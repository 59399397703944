<script lang="ts">
  import { screens, version, glossary, playerTruth} from '../store';
  import { openOptions, openTutorial } from './modals/modals';
  import { isSavedGame, loadGame } from './utils/savefile';
  import { onMount } from 'svelte';
  import pinch from './utils/pinch';

  const startGame = () => {
    screens.set('playerselect');
  }

  const openFarm = () => {
    screens.set('farm');
  }

  function openGlossary(){
    glossary.open.set(true);
  }

  function openOpts(){
    openOptions({});
  }

  function continueGame(){
    var data = loadGame();
    playerTruth.set(data.player);

    screens.set('map', {map: data.map});
  }

  function quit(){
    window.close();
  }
</script>

<div class='frame'>

  <div class='buttons'>
    <h1>Odam</h1>
    <h2>Dice Builder RPG</h2>

    {#if isSavedGame()}
      <button class='btn start' on:click={continueGame}>
        Continue
      </button>
    {/if}

    <button class='btn start' on:click={startGame}>
      Start Game
    </button>

    <button class='btn start' on:click={openOpts}>
      Options
    </button>

    <button class='btn start' on:click={openGlossary}>
      Glossary
    </button>

    <!-- <button class='btn start' on:click={openFarm}>
      farm
    </button> -->

    <!-- <button class='btn start' on:click={startGame}>
      Credits
    </button> -->

    <button class='btn start' on:click={quit}>
      Quit
    </button>
  </div>
</div>

<p style='text-align=left;'>v{version}</p>

<style lang='less'>
  @import "../defines.less";

  .frame{
    height: 100%;
    width: 100%;
    background-image: url('../assets/backgrounds/main-splash.webp');
    background-position: center;
    background-size: cover;
    align-items: center;
    justify-items: center;
    display: grid;
    grid-template-rows: 1fr auto;
  }
  .buttons{
    display: grid;
    // height: 400px;
    grid-gap: 10px;
    align-items: center;
    padding: 20px;
    border-radius: 20px;
    background: rgba(0,0,0,0.5);
    min-width: min(400px, 90vw);

    .btn{
      font-size: 2em;
    }
  }

  p{
    position: fixed;
    bottom: 0;
    right: 0;
    margin: 10px 20px;
  }
</style>