<script lang="ts">
  import { playerTruth } from '../store';
  import Icon from './icon/icon.svelte';
  import type { MapEvent } from './models/events.type';
  import type { MapData, MapNode } from './models/map.type';

  export let data : {
    event: MapEvent,
    map: MapData,
    node: MapNode
  };
  let event = data.event;
  let step = event.step;
  let image = event.image;
  let node = data.node;

  function activateStep(option){
    // test if option.activate is an array
    if(Array.isArray(option.activate)){
      //random index from array
      let index = Math.floor(Math.random() * option.activate.length);
      option.activate[index]($playerTruth, data.map, node);

      if(option.next[index]){
        step = option.next[index];
        if(step.image){
          image = step.image;
        }
      }
      else{
        // screens.pop();
      }
      return
    }
    else{
      if(option.activate){
        let promise = option.activate($playerTruth, data.map, node);

        if(promise){
          promise.then(option => {
            if(option.next){
              step = option.next;

              if(step.image){
                image = step.image;
              }
            }
          });
        }
      }
    }

    if(option.next){
      step = option.next;

      if(step.image){
        image = step.image;
      }
    }
    else{
      // screens.pop();
    }
  }
</script>

<div class='container'>
  <h2>{event.title}</h2>

  <div class='flex-center'>
    <div class='portrait {event.type}'>
      <div class='frame {image}'>
      </div>
    </div>
  </div>

  <p class='event-text'>{step.text}</p>

  {#if step.subtext}
    <p class='subtext'>{@html step.subtext}</p>
  {/if}

  <div class='options'>
    {#each step.options as option, index}
      {#if !option.condition || option.condition.fulfils($playerTruth)}
        <button on:click={() => {activateStep(option)}} class='btn'>
          {#if option.condition?.icon}
            <Icon name={option.condition.icon} width='20px' height='20px' class='icon'/>
          {/if}
          {#if option.condition?.text}
            <span class='condition'>({option.condition.text})</span>
          {/if}
          <span class='text'>
            {option.text}
          </span>
          {#if option.subtext}
            <span class='subtext'>( {option.subtext} )</span>
          {/if}
        </button>
      {/if}
    {/each}
  </div>
</div>


<style lang='less'>
  @import "../defines.less";

  .container{
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    min-width: min(500px, 90vw);
    max-width: 90vw;

    .options{
      display: flex;
      flex-direction: column;
      grid-gap: 10px;

      .btn{
        width: 100%;

        .condition{
          font-weight: bold;
        }
        .text{
          margin-left: 10px;
        }

        .subtext{
          margin-left: 10px;
          font-style: italic;
        }
      }
    }

    .portrait{
      height: calc(var(--column-size) * 2);
      width: calc(var(--column-size) * 2);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: auto;
      box-shadow: inset black 0px 0px var(--icon-size) 10px;
      font-size: 250px;
      position: relative;

      @media @small {
        font-size: 120px;
      }
      @media @medium {
        font-size: 140px;
      }

      .frame{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-style: solid;
        border-width: 4px;
        border-color: black;

        outline-style: solid;
        outline-width: 2px;
        outline-color: var(--colour);

        margin: 3px;
        box-shadow: 0 0 4px 4px var(--colour) inset;

        background-position: center;
        background-size: cover;

        @media @medium{
          border-width: 2px;
          margin: 2px;
        }
        @media @small{
          border-width: 1px;
          outline-width: 1px;
          box-shadow: 0 0 2px 2px var(--colour) inset;
        }
      }

      &.scout{
        background: #4CAF50;
        color: #388E3C;
        --colour: #4CAF50;
      }

      &.campfire{
        background: #FF9800;
        color: #E65100;
        --colour: #FF9800;
      }

      &.event{
        background: #FFEB3B;
        color: #8D6E00;
        --colour: #FFEB3B;
      }

      &.battle{
        background: #C72E17;
        color: #50120A;
        --colour: #C72E17;
      }

      &.quest{
        background: #2196F3;
        color: #0D47A1;
        --background-colour: #2196F3;
      }

      &.boss{
        background: #9370DB;
        color: #4B0082;
        --background-colour: #9370DB;
      }
    }
  }
</style>