
<script lang='ts'>
  import { tutorialData, type TutorialData } from './modals';

  let onConfirm = null;

  let anchor = null;
  let position = {
    top: null,
    left: null,
    bottom: null,
    right: null,
    value: null
  };
  let highlightBorders = {
    top: null,
    left: null,
    bottom: null,
    right: null
  };
  let size: | 'small' | 'medium' | 'large' = 'medium';
  let data: TutorialData = null;

  let modelView;

  tutorialData.subscribe(value => {
    data = value;
    if(anchor){
      anchor.classList.remove('tutorial-highlighted');
    }

    position = {
      top: null,
      left: null,
      bottom: null,
      right: null,
      value: null
    };
    highlightBorders = {
      top: null,
      left: null,
      bottom: null,
      right: null
    };

    if(value){
      if(value.onConfirm){
        onConfirm = value.onConfirm;
      }

      if(value.anchor){
        anchor = document.querySelector(value.anchor.selector);
        if(anchor){
          // anchor.scrollIntoView({behavior: 'smooth'});

          // add special highlighted class to element
          anchor.classList.add('tutorial-highlighted');

          //calculate element corners
          let rect = anchor.getBoundingClientRect();
          highlightBorders.top = rect.top;
          highlightBorders.left = rect.left;
          highlightBorders.bottom = rect.bottom;
          highlightBorders.right = rect.right;

          //position the element so that it sits relative to the anchor element
          if(value.anchor.position == 'below'){
            position.top = highlightBorders.bottom + 23;
            position.left = highlightBorders.left + (anchor.offsetWidth/2);
          }
          else if(value.anchor.position == 'above'){
            position.bottom = window.innerHeight - highlightBorders.top + 23;
            position.left = highlightBorders.left + (anchor.offsetWidth/2);
          }
          else if(value.anchor.position == 'right'){
            position.left = highlightBorders.right + 23;
            position.top = highlightBorders.top + (anchor.offsetHeight/2);
          }
          else if(value.anchor.position == 'left'){
            position.right = window.innerWidth - highlightBorders.left + 23;
            position.top = highlightBorders.top + (anchor.offsetHeight/2);
          }
          else if(value.anchor.position == 'center'){
            // position.top = window.innerHeight/2;
            // position.left = window.innerWidth/2;
          }

          setTimeout(() => {
            //if any position is outside the page, bring it inside the page
            var width = modelView.offsetWidth/2;
            var height = modelView.offsetHeight/2;

            if(position.value == 'left' || position.value == 'right'){
              if(position.top && position.top + height > window.innerHeight){
                position.top = window.innerHeight - height - 10;
              }
              if(position.top && position.top < 0){
                position.top = 10;
              }
              if(position.bottom && position.bottom + height > window.innerHeight){
                position.bottom = window.innerHeight - height - 10;
              }
              if(position.bottom && position.bottom < 0){
                position.bottom = 10;
              }
            }

            if(position.value == 'below' || position.value == 'above'){
              if(position.left && position.left + width > window.innerWidth){
                position.left = window.innerWidth - width - 10;
              }
              if(position.left && position.left < 0){
                position.left = 10;
              }
              if(position.right && position.right + width > window.innerWidth){
                position.right = window.innerWidth - width - 10;
              }
              if(position.right && position.right < 0){
                position.right = 10;
              }
            }
            position = position;
          }, 10);

          position.value = value.anchor.position;
        }
      }
    }
    else{
      onConfirm = null;
    }
  });

  const confirm = () => {
    if(onConfirm){
      onConfirm();
    }
    $tutorialData.callback();
    tutorialData.set(null);
  }

  const skip = () => {
    $tutorialData.callback(true);
    tutorialData.set(null);
  }
</script>

{#if data}
<div class='modal-container tutorial-container'>
  <div class='modal-center' style='
      top: {position.top}px;
      left: {position.left}px;
      bottom: {position.bottom}px;
      right: {position.right}px;
      {position.value == 'below' || position.value == 'above' ? 'width: 0px;' : 'height: 0px; flex-direction: column'}
    '>
    <div class='caret {position.value}'></div>

    <div class='modal {size}' bind:this="{modelView}">
      {#if data.title}
        <div class='header'>
          <h1>{data.title}</h1>
        </div>
      {/if}

      {#if data.text}
        <div class='body'>
          <p>{@html data.text}</p>
          <img style='display:none' alt=''>
        </div>
      {/if}

      <div class='functions'>
        <button class='btn' on:click={skip}>Skip</button>
        <button class='btn' on:click={confirm}>Confirm</button>
      </div>
    </div>
  </div>
</div>

<div class='highlight-box' style='
  top: {highlightBorders.top-12}px;
  left: {highlightBorders.left-12}px;
  bottom: {highlightBorders.bottom-10}px;
  right: {highlightBorders.right-1}px;
  width: {highlightBorders.right - highlightBorders.left + 20}px;
  height: {highlightBorders.bottom - highlightBorders.top + 20}px;
'>

</div>
{/if}

<style lang='less'>
  /* :global(.tutorial-highlighted) {
    border: 2px solid white;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.75), 0 0 5px 5px white;
    z-index: 100;
  } */

  .highlight-box{
    border: 2px solid white;
    box-shadow: 0 0 0 9999px rgba(0, 0, 0, 0.75), 0 0 5px 5px white;
    z-index: 100;
    position: absolute;
    border-radius: 8px;
  }

  .tutorial-container{
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    width: 100%;
    height: 100%;
    overflow: hidden;

    display: flex;
    justify-content: center;
    align-items: center;

    z-index: 1000;
    background-color: transparent;

    .modal-center{
      display: flex;
      justify-content: center;
      align-items: center;
      position: absolute;

      .caret{
        position: absolute;
        width: 0;
        height: 0;
        border-left: 10px solid transparent;
        border-right: 10px solid transparent;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;

        z-index: 1001;

        &.above{
          bottom: -20px;
          border-top: 10px solid white;
        }
        &.below{
          top: -20px;
          border-bottom: 10px solid white;
        }
        &.left{
          right: -20px;
          border-left: 10px solid white;
        }
        &.right{
          left: -20px;
          border-right: 10px solid white;
        }
      }

      .modal{
        margin-top: unset;
        border-color: white;

        &.small{
          width: max(200px, 30vw);
        }
        &.medium{
          width: max(300px, 50vw);
        }
        &.large{
          width: max(400px, 70vw);
        }

        .header{
          text-align: center;
          justify-content: center;
        }

        .body{
          :global(img){
            vertical-align: middle;
            width: 50px;
          }
        }
      }
    }
  }
</style>