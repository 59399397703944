<script lang='ts'>
	export let left;
	export let top;
  export let clazz = '';

  let vx = 0;
  let vy = 0;
  let lastX = 0;
  let lastY = 0;

  const a = 0.85;
  const t = Math.log(1e-3) / Math.log(a);

	let dragging = false;

	function onMouseDown(event) {
    event.preventDefault();
    lastX = event.clientX;
    lastY = event.clientY;
    vx = 0;
    vy = 0;

		dragging = true;
	}

	function onMouseMove(e) {
		if (dragging) {
			left += e.movementX;
			top += e.movementY;

      const dx = e.clientX - lastX;
      const dy = e.clientY - lastY;
      lastX = e.clientX;
      lastY = e.clientY;
      vx = dx;
      vy = dy;
		}
	}

	function onMouseUp() {
		dragging = false;

    // let animation = requestAnimationFrame(move);
    // function move() {
      // left += vx;
      // top += vy;
      // vx *= 0.85;
      // vy *= 0.85;

      const dx = vx * (1 - a**t) / (1 - a);
      const dy = vy * (1 - a**t) / (1 - a);

      // Calculate landing position
      left += dx;
      top += dy;
      vx = 0;
      vy = 0;

      // Calculate time taken to land


      /* if (Math.abs(vx) < 0.1 && Math.abs(vy) < 0.1) {
        cancelAnimationFrame(animation);
      } else {
        animation = requestAnimationFrame(move);
      } */
    // }

	}

// 	$: console.log(dragging);
</script>

<style lang='less'>
	.draggable {
		user-select: none;
		cursor: move;
		/* border: solid 1px gray; */
		position: absolute;
    transition: top 420ms ease-out, left 420ms ease-out !important;
	}

  .dragging{
    transition: none !important;
  }
</style>

<section on:mousedown={onMouseDown} style="left: {left}px; top: {top}px;" class="draggable {clazz} {dragging ? 'dragging':''}">
	<slot></slot>
</section>

<svelte:window on:mouseup={onMouseUp} on:mousemove={onMouseMove} />