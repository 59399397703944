import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';
import { addModifier, dealDamage, healthRemove } from '../player.type';
import type { Technique } from '../techniques.type';
import { techniqueHistory } from '../../../store';
import type { Die } from '../die.type';
import type { Player } from '../player.type';
import { DieFace } from "../face.type";
import { StatusEffect } from "../status-effects";

export class Grappled extends Modifier {
  getTechniques(techniques: Technique[]): Technique[] {
    return [{
      title: 'Struggle',
      text: 'Take 1 Pure Damage. Gain 1 Weakness. Remove 1 stack of Grappled.',
      tags: ['required'],
      ingredients: [],
      requirements: [{
        text: 'Has 1 Health.',
        icon: 'blank',
        test: (source, target) => {
          return source.health === 1;
        }
      }],
      activate: (target: Player, source: Player, dice: Array<Die>) => {
        healthRemove({ target: source, source: target, value: 1, dice: [] });
        this.removeStacks(1);
      }
    }, {
      title: 'Flail',
      text: 'Remove 1 stack of Grappled.',
      tags: ['required'],
      ingredients: [DieFace.Blank],
      activate: (target: Player, source: Player, dice: Array<Die>) => {
        this.removeStacks(1);
      }
    }, {
      title: 'Thrash',
      text: 'Gain 1 stack of Vulnerable. Remove 1 stack of Grappled.',
      tags: ['required'],
      ingredients: [DieFace.Blank],
      activate: (target: Player, source: Player, dice: Array<Die>) => {
        this.removeStacks(1);
        addModifier(source, StatusEffect.Vulnerable, 1);
      }
    }]
  }

  update(stacks: number, params?) {
    super.update(stacks, params);
    this.delay = true;
  }

  startTurn() {
  }

  endTurn() {
    healthRemove({target: this.owner, source: this.owner, value: this.stacks*2, dice: []});
    this.removeStacks(this.stacks);
  }
}