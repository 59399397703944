import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';
import { addModifier, dealDamage } from '../player.type';
import type { Technique } from '../techniques.type';
import { techniqueHistory } from '../../../store';
import type { Die } from '../die.type';
import type { Player } from '../player.type';
import { DieFace } from "../face.type";

export class Slow extends Modifier {
  getTechniques(techniques: Technique[]): Technique[] {
    const lastAction = techniqueHistory[0];
    if (lastAction && lastAction.title != 'Catch Up'){
      techniques.length = 0;
    }

    return [{
      title: 'Catch Up',
      text: 'Do Nothing.',
      tags: ['chain', 'required'],
      ingredients: [DieFace.Blank],
      requirements: [{
        text: 'Previous technique was not a "Catch Up" technique.',
        icon: 'blank',
        test: (source, target) => {
          const lastAction = techniqueHistory[0];
          return lastAction && lastAction.title != 'Catch Up';
        }
      }],
      activate: (target: Player, source: Player, dice: Array<Die>) => {
      }
    }]
  }

  update(stacks: number, params?) {
    super.update(stacks, params);
    this.delay = true;
  }

  startTurn() {
    if (this.delay) {
      const focus = this.owner.statusEffects.find(modifier => modifier.type === 'focus');
      if (!focus) {
        this.delay = false;
      }
    }
  }

  endTurn() {
    if (!this.delay) {
      this.removeStacks(1);
    }
  }
}