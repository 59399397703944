import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';


export const shell: Technique[] = [
  // Shell Pincer
  {
    ingredients: [DieFace.Shell],
    title: 'Weak Carapace',
    text: 'Gain 1 Barrier.',
    tags: ['defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Barrier, 1);
    }
  }, {
    ingredients: [DieFace.Shell, DieFace.Shell],
    title: 'Carapace',
    text: 'Gain 3 Barrier.',
    tags: ['defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Barrier, 3);
    }
  }, {
    ingredients: [DieFace.Shell, DieFace.Shell, DieFace.Shell],
    title: 'Mega Carapace',
    text: 'Gain 5 Barrier.',
    tags: ['defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Barrier, 5);
    }
  }, {
    ingredients: [DieFace.Shell, DieFace.Bone],
    title: 'Claw Slam2',
    text: 'Deal 2 Damage, Apply 1 Dopey.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, StatusEffect.Dopey, 1);
    }
  }, /* {
    ingredients: [DieFace.Shell, DieFace.Blood],
    title: 'Claw Slam',
    text: 'Deal 2 Damage, Add 1 Dopey',
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, 'dopey', 1);
    }
  } */
];
