import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';
import { healthRemove } from '../player.type';

export class Poison extends Modifier {
  endTurn() {
    const damage: Damage = {
      value: this.stacks,
      target: this.owner,
      source: this.owner,
      dice: []
    };

    healthRemove(damage);

    this.halveStacks();
  }

  modifyNotches(notches: Array<string>) {
    let last = notches.lastIndexOf('health');
    for (var i = 0; i < this.stacks; i++) {
      if (last - i < 0) {
        break;
      }
      if (notches[last - i]) {
        notches[last - i] += ' poison';
      }
    }
  }
}