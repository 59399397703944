import { DieMaterial, type Die } from "../die.type";
import { Modifier } from "../modifier";

export class Petrified extends Modifier {
  getDice(dice: Array<Die>) {
    let cleanDie = dice.filter(die => die.material == DieMaterial.Normal);
    // random sort cleanDie
    cleanDie.sort(() => Math.random() - 0.5);
    cleanDie.sort(() => Math.random() - 0.5);

    // for the number of stacks
    for (let i = Math.min(this.stacks, cleanDie.length); i > 0; i--) {
      cleanDie[i].material = DieMaterial.Stone;
    }

    return null;
  }
}