<script lang='ts'>
  export let clazz = '';
  import { onMount } from 'svelte';
  import { pan } from 'svelte-hammer';
  import pinch from '../utils/pinch';
  import { clamp } from '../utils/math';

  let container;
  let element;

  let pos = { top: 0, left: 0, x: 0, y: 0 };
  export let zoomable: boolean = false;

  function onWheel(event){
    if(zoomable){
      // get the mouse position relative to the container
      let pos = {
        x: event.pageX,// - element.getBoundingClientRect().left,
        y: event.pageY// - element.getBoundingClientRect().top
      };

      throttleZoom(pos.x, pos.y, event.delta || event.wheelDelta || event.originalEvent.detail)
      event.preventDefault();
    }
  }

  let dragging = false;
  let pinching = false;
  let scale = 1;

  function panStart(event){
    dragging = true;

    pos = {
      // The current scroll
      left: container.scrollLeft,
      top: container.scrollTop,
      // Get the current mouse position
      x: event.detail.center.x,
      y: event.detail.center.y,
    };
  }

  function panEnd(){
    dragging = false;
  }
  function onPan(event) {
    if (dragging) {
			// How far the mouse has been moved
      const dx = event.detail.center.x - pos.x;
      const dy = event.detail.center.y - pos.y;

      // Scroll the element
      container.scrollTop = pos.top - dy;
      container.scrollLeft = pos.left - dx;
		}
	}

  let prevDelta = 0;
  const throttleZoom = throttle(zoom, 50);
  function pinchStart(event){
    pinching = true;
    prevDelta = event.detail.scale;
  }
  function onPinch(event) {
    if(pinching){
      let delta = prevDelta - event.detail.scale;
      prevDelta = event.detail.scale;
      throttleZoom(event.detail.center.x, event.detail.center.y, delta*-50);
    }
  }

  var zoom_point = { x: 0, y: 0 };
  let timeout;
  function zoom(x, y, delta) {
    var offset = container.getBoundingClientRect();
    zoom_point.x = x - offset.left;
    zoom_point.y = y - offset.top;

    let percentX = zoom_point.x / element.offsetWidth;
    let percentY = zoom_point.y / element.offsetHeight;

    delta = Math.max(-1, Math.min(1, delta)); // cap the delta to [-1,1] for cross browser consistency

    // apply zoom
    let prevScale = scale;
    scale += delta * 0.25 * scale;
    scale = clamp(scale, 1, 4);

    let deltaWidth = element.offsetWidth * scale - element.offsetWidth * prevScale;
    let deltaHeight = element.offsetHeight * scale - element.offsetHeight * prevScale;

    let left = container.scrollLeft;
    let top = container.scrollTop;

    function updateZoom(){
      container.scrollLeft = left + deltaWidth * percentX;
      container.scrollTop = top + deltaHeight * percentY;
    }

    if(timeout){
      updateZoom();
      clearTimeout(timeout);
    }
    timeout = setTimeout(updateZoom);
  }

// 	$: console.log(dragging);
  onMount(() => {
    //scroll to the middle of the map
    if(element){
      element.scrollLeft = element.scrollWidth / 2 - element.clientWidth / 2;
      element.scrollTop = element.scrollHeight / 2 - element.clientHeight / 2;
    }
	});

  function throttle(cb, delay) {
    let wait = false;

    return (...args) => {
      if (wait) {
        return;
      }

      cb(...args);
      wait = true;
      setTimeout(() => {
        wait = false;
      }, delay);
    }
  }
</script>

<style lang='less'>
  section{
    overflow-x: hidden;
    overflow-y: hidden;

    width: 100%;
    height: 100%;
    position: relative;
  }

  .zoomContainer{
    width: 100%;
    height: 100%;
    transition: transform 0.1s linear;
    position: absolute;
    transform-origin: 0 0;
  }
</style>

<!-- on:mousedown={onMouseDown} -->
<section
  use:pan
  on:panstart={panStart}
  use:pinch
  on:pinchstart={pinchStart}
  class="{clazz}"
  bind:this={container}
  >
  {#if zoomable}
  <div
    bind:this={element}
    style='width: min({100*scale}vh, {100*scale}vw); height: min({100*scale}vh, {100*scale}vw)'
    class='zoomContainer'
  >
    <slot></slot>
  </div>
  {:else}
    <slot></slot>
  {/if}
</section>

<!-- on:mouseup={onMouseUp} -->
<!-- on:mousemove={onMouseMove} -->
<svelte:window
  use:pan
  on:panend={panEnd}
  on:pan={onPan}
  use:pinch
  on:pinchin={onPinch}
  on:pinchout={onPinch}
  on:wheel|nonpassive={onWheel}/>
