import { crossfade, fade } from 'svelte/transition';
import { quintInOut, quadInOut } from 'svelte/easing';

export const [send, receive] = crossfade({
  duration: d => Math.sqrt(d * 400),

  fallback(node, params) {
    const style = getComputedStyle(node);
    const transform = style.transform === 'none' ? '' : style.transform;

    return {
      duration: 600,
      easing: quintInOut,
      css: t => `
        transform: ${transform} scale(${t});
      `
    };
  }
});

export const squish = (node, params) => {
  return {
    delay: params.delay || 0,
    duration: params.duration || 400,
    css: (t, u) => {
      const eased = quadInOut(t);
      return `max-height:${eased*90}; margin-bottom:${eased*10}`;
    }
  };
}
