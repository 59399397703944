import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';
import { healthRemove } from '../player.type';

export class Burning extends Modifier {
  modifierAdd(type: string, stacks: number) {
    if (type === 'block') {
      let difference = this.stacks - stacks;
      this.removeStacks(Math.min(this.stacks, stacks));

      if (difference >= 0) {
        stacks = 0;
      }
      else {
        stacks = Math.abs(difference);
      }
    }
    return stacks;
  }

  rollStart(a,b, count) {
    if (count !== 0){
      var half = Math.ceil(this.stacks / 2);
      const damage: Damage = {
        value: half,
        target: this.owner,
        source: this.owner,
        dice: []
      };

      healthRemove(damage);

      this.removeStacks(half);
    }
  }

  endTurn() {
    this.halveStacks();
  }
}