import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';


export const shield: Technique[] = [
  //shield sword
  {
    ingredients: [DieFace.Shield],
    title: 'Weak Block',
    text: 'Gain 1 Block.',
    tags: ['defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Block, 1);
    }
  }, {
    ingredients: [DieFace.Shield, DieFace.Shield],
    title: 'Strong Block',
    text: 'Gain 3 Block.',
    tags: ['defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Block, 3);
    }
  }, {
    ingredients: [DieFace.Shield, DieFace.Shield, DieFace.Shield],
    title: 'Mega Block',
    text: 'Gain 6 Block.',
    tags: ['defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Block, 6);
    }
  }, {
    ingredients: [DieFace.Shield, DieFace.Fist],
    title: 'Shield Bash',
    text: 'Deal damage equal to block value.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      var modifier = source.statusEffects.find(modifier => {
        return modifier.type == 'block';
      });

      var value = 0;
      if (modifier) {
        value = modifier.stacks;
      }

      dealDamage({ value: value, target, source, dice });
    }
  },
];
