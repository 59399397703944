import { Modifier, type ModifierParams } from "../modifier";
import type { Damage } from '../damage.type';
import { addModifier, healDamage, healthRemove, type Player } from '../player.type';
import { actionHistory } from '../../../store';
import type { Die } from "../die.type";
import type { Technique } from "../techniques.type";
import { StatusEffect } from "../status-effects";
import { DieFace } from "../face.type";

export enum Songs {
  Seduction = 'Sonet of Seduction', // listener, if stacks is divisble by 3, gain stunned, else gain dopey

  Healing = 'Hymn of Healing', // both, heal stacks health
  Harm = 'Hymn of Harm', // both, take stacks damage

  Poison = 'Sickening Symphony', // both, take stacks of poison
  Regen = 'Sanguine Symphony', // both, take stacks of regenerate
}

export const definitions = {
  [Songs.Seduction]: {
    text: 'If stacks is divisble by 3, gain stunned, else gain dopey',
  },
  [Songs.Healing]: {
    text: 'Heal health equal to stacks (max 5).',
  },
  [Songs.Harm]: {
    text: 'Take damage equal to stacks (max 5).',
  },
  [Songs.Poison]: {
    text: 'Gain Poison equal to stacks (max 3).',
  },
  [Songs.Regen]: {
    text: 'Gain regenerate equal to stacks (max 3).',
  },
};

export class Song extends Modifier {
  role: 'performer' | 'listener';
  song: Songs;

  init(params?: ModifierParams): void {
    this.role = params.role;
    this.song = params.song;

    this.priority = 4;
  }

  clone(newOwner: Player){
    var clone = super.clone(newOwner);
    clone.role = this.role;
    clone.song = this.song;

    return clone;
  }

  update(stacks: number, params?: ModifierParams): void {
    if (params && ((params.role && params.role !== this.role) || (params.song && params.song !== this.song))){
      actionHistory.push(`${this.owner.title} changed song from ${this.song} to ${params.song}.`);
      this.role = params.role;
      this.song = params.song;
      this.title = `${this.type} (${this.song})`;
      // this.bonusIcon = this.song;

      this.stacks = 1;
    }
    else{
      this.stacks += stacks;
    }
  }

  damageOut(damage: Damage) {
    damage.value *= 2;
    this.removeStacks(1);
  }

  startTurn({ activeDie, rerollMax, activePlayer, inactivePlayer }): { activeDie: Array<Die>, rerollMax: number, activePlayer: Player, inactivePlayer: Player } | void {
    // if performer, add song as listener to inactivePlayer
    if(this.role === 'performer'){
      addModifier(activePlayer, StatusEffect.Song, 1, {role: 'performer', song: this.song});
      addModifier(inactivePlayer, StatusEffect.Song, 1, {role: 'listener', song: this.song});
    }

    switch(this.song){
      case Songs.Seduction:
      case Songs.Healing:
      case Songs.Harm:
      case Songs.Poison:
      case Songs.Regen:
        this.activate();
        break;
    }
  }

  getTechniques(techniques: Technique[]): Technique[] {
    if (this.role === 'listener'){
      return [];
    }
    let technique: Technique = {
      title: 'Change Tempo',
      text: '',
      tags: ['song'],
      ingredients: [DieFace.Note],
      activate: () => {}
    }
    if (this.song == Songs.Healing) {
      technique.text = 'Start performing song Hymn of Harm';
      technique.activate = (target: Player, source: Player, dice: Array<Die>) => {
        addModifier(source, StatusEffect.Song, 1, { role: 'performer', song: Songs.Harm });
        addModifier(target, StatusEffect.Song, 1, { role: 'listener', song: Songs.Harm });
      };
    }
    else if (this.song == Songs.Harm) {
      technique.text = 'Start performing song Hymn of Healing';
      technique.activate = (target: Player, source: Player, dice: Array<Die>) => {
        addModifier(source, StatusEffect.Song, 1, { role: 'performer', song: Songs.Healing });
        addModifier(target, StatusEffect.Song, 1, { role: 'listener', song: Songs.Healing });
      };
    }

    else if (this.song == Songs.Poison) {
      technique.text = 'Start performing song Sanguine Symphony';
      technique.activate = (target: Player, source: Player, dice: Array<Die>) => {
        addModifier(source, StatusEffect.Song, 1, { role: 'performer', song: Songs.Regen });
        addModifier(target, StatusEffect.Song, 1, { role: 'listener', song: Songs.Regen });
      };
    }
    else if (this.song == Songs.Regen) {
      technique.text = 'Start performing song Sickening Symphony';
      technique.activate = (target: Player, source: Player, dice: Array<Die>) => {
        addModifier(source, StatusEffect.Song, 1, { role: 'performer', song: Songs.Poison });
        addModifier(target, StatusEffect.Song, 1, { role: 'listener', song: Songs.Poison });
      };
    }

    return [];
  }

  activate() {
    switch(this.song){
      case Songs.Seduction:
        if(this.role === 'listener'){
          if(this.stacks % 3 === 0){
            addModifier(this.owner, StatusEffect.Stunned, 1);
          }
          else{
            addModifier(this.owner, StatusEffect.Dopey, 1);
          }
        }
        break;

      case Songs.Healing:
        healDamage({ value: Math.min(this.stacks, 5), source: this.owner, target: this.owner, dice: []});
        break;

      case Songs.Harm:
        healthRemove({ value: Math.min(this.stacks, 5), source: this.owner, target: this.owner, dice: []});
        break;

      case Songs.Poison:
        addModifier(this.owner, StatusEffect.Poison, Math.min(this.stacks, 3));
        break;

      case Songs.Regen:
        addModifier(this.owner, StatusEffect.Regen, Math.min(this.stacks, 3));
        break;
    }
  }

  getHTML(): string {
    let definition = definitions[this.song];
    return `
      <h3> ${this.song} (${this.role}) </h3>
      <p> ${definition.text} </p>
    `;
  }
}