import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';
import { healthRemove } from '../player.type';
import { actionHistory } from '../../../store';

export class Snared extends Modifier {
  modifierAdd(type: string, stacks: number) {
    if (type === 'dodge') {
      let difference = this.stacks - stacks;
      this.removeStacks(Math.min(this.stacks, stacks));

      if (difference >= 0) {
        stacks = 0;
      }
      else {
        stacks = Math.abs(difference);
      }
    }
    return stacks;
  }

  damageIn(damage: Damage) {
    damage.value = Math.ceil(damage.value * (1 + this.stacks * 0.25));
    this.removeStacks(this.stacks);
  }
}