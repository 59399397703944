<script lang="ts">
  import { screens, stateNode, playerTruth, playerInstance, enemyInstance, glossary } from '../store';
  import { setupEnvironment } from "./models/environment.type";
  import { createEnemyInstance } from './models/enemy.type';
  import { clonePlayer } from './models/player.type';
  import Icon from "./icon/icon.svelte";
  import Portrait from "./components/portrait.svelte";
  import { randomInt } from './utils/math';
  import { actionHistory } from '../store';
  import { track } from './utils/playerTracking';

  export let data;

  const player = clonePlayer($playerTruth);
  player.statusEffects.sort((a, b) => {
    return b.priority - a.priority;
  });

  const enemy = createEnemyInstance(data.battle.level, data.battle.enemy);

  // player.healthCurrent = player.healthMax;
  enemy.healthCurrent = enemy.healthMax;

  if(data.battle.environment){
    setupEnvironment(data.battle.environment);
  }
  else if(data.battle.enemy.environments && data.battle.enemy.environments.length){
    const environment: string = data.battle.enemy.environments[randomInt(0, data.battle.enemy.environments.length - 1)];
    setupEnvironment(environment);
    /* data.battle.enemy.environments.forEach(env => {
      setupEnvironment(env);
    }); */
  }
  else{
    setupEnvironment('forest');
  }

  function start(){
    //create player instances
    screens.set('battle', {battle: data.battle, player: player, enemy: enemy});

    actionHistory.length = 0;
  }

  function viewManual(){
    glossary.open.set(true);
    glossary.selected.set('enemies');
    glossary.searchTerm.set(enemy.title);
  }

  track('Battle Start', {
    player_class: $playerTruth.type,
    player_archetype: $playerTruth.archetype,
    player_level: $playerTruth.level,

    enemy_class: data.battle.enemy.type,
    enemy_level: data.battle.enemy.level
  });
</script>

<div class='container'>
  <h1>To Battle!</h1>

  <div class='vs'>
    <div>
      <h2>{player.title}</h2>
      <div class='portrait-container'>
        <Portrait data={player}/>
        <!-- <HealthBar data={player}/> -->
        <!-- <h2>{player.text}</h2> -->
      </div>
    </div>

    <div class='center-flex'>
      <div class='battle-icon'>
        <Icon name='fight'/>
      </div>
    </div>

    <div class='portrait-container enemy'>
      <h2>{enemy.title}</h2>
      <Portrait data={enemy}/>

      <button class='btn' style='margin: 10px auto' on:click={viewManual}>View Manual</button>
    </div>
  </div>

  <div class='description'>
    <h2>{enemy.text}</h2>
  </div>

  <button on:click={start} class='start btn'>
    Start
  </button>
</div>


<style lang='less'>
  @import "../defines.less";

  :root{
    background-color: var(--background-colour);
  }

  .container{
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    margin: auto;

    button{
      background-color: var(--background-colour-dark);
    }

    .vs{
      display: grid;
      grid-template-columns: max-content 1fr max-content;
      grid-gap: 25px;
      align-items: center;
      justify-content: center;
      width: 100vw;
      max-width: 1000px;
      margin: auto;

      .portrait-container{
        padding: 0 10px;
      }
    }

    .description{
      max-width: min(80vw, 800px);
      margin: auto;
    }
  }

  :global(.battle-icon){
    --icon-size: 200px;
  }
  @media @medium{
    :global(.battle-icon){
      --icon-size: 150px;
    }
    .container{
      grid-gap: 25px;
      .vs{
        max-width: 600px;
        grid-gap: 10px;
      }
    }
  }
  @media @small{
    :global(.battle-icon){
      --icon-size: 75px;
    }
    .container{
      .vs{
        max-width: 500px;
        grid-gap: 5px;
      }
    }
  }
</style>