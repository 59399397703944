import { dealDamage, healDamage, addModifier, healthRemove } from'../player.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';


export const basics: Technique[] = [
  /* {
    ingredients: [DieFace.Fist, DieFace.Fist],
    title: 'Punch',
    text: 'Deal 1 Damage.',
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
    }
  }, *//* {
    ingredients: [DieFace.Blank, DieFace.Blank],
    title: 'Focus',
    text: 'Gain 1 Focus.',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Focus, 1);
    }
  }, */ {
    ingredients: [DieFace.Fist, DieFace.Fist, DieFace.Blank],
    title: 'Strength',
    text: 'Gain 1 Strength.',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Strength, 1);
    }
  },/*  {
    ingredients: [DieFace.Bone, DieFace.Bone],
    title: 'Parry',
    text: 'Gain 1 Block.',
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Block, 1);
    }
  },  */{
    ingredients: [DieFace.Bone, DieFace.Bone, DieFace.Blank],
    title: 'Armoured',
    text: 'Gain 1 Armoured.',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Armoured, 1);
    }
  }, {
    ingredients: [DieFace.Agility, DieFace.Agility, DieFace.Blank],
    title: 'Juke',
    text: 'Gain 1 Haste.',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Haste, 1);
    }
  }, {
    ingredients: [DieFace.Eye, DieFace.Eye],
    title: 'Aim',
    text: 'Gain 1 Accuracy.',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Accuracy, 1);
    }
  }, {
    ingredients: [DieFace.Brain],
    requirements: [ //TODO: Make this work for AI
      {
        text: '',
        test: (source: Player, target: Player) => {
          return source.id == 'player';
        }
      }
    ],
    title: 'Quick Thinking',
    text: 'Select a die, choose which face is selected.',
    tags: ['intelligence'],
    activate: async (target: Player, source: Player, dice: Array<Die>) => {
      const die = await selectDie({ dice: lockedDie });

      die.activeFace = await selectFace({ die });
    }
  }, {
    ingredients: [DieFace.Brain, DieFace.Brain, DieFace.Blank],
    title: 'Hyper Study',
    text: 'Gain 1 Witty.',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Witty, 1);
    }
  }, /* {
    ingredients: [DieFace.Reflex, DieFace.Reflex],
    title: 'Dodge',
    text: '1 dodge',
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Dodge, 1);
    }
  },  */{
    ingredients: [DieFace.Reflex, DieFace.Reflex, DieFace.Blank],
    title: 'Adrenaline',
    text: 'Gain 1 Reactive.',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Reactive, 1);
    }
  },

  {
    ingredients: [DieFace.Fist, DieFace.Bone],
    title: 'Redirect',
    text: 'Deal 1 Damage, Gain 1 Block.',
    tags: ['damage', 'defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(source, StatusEffect.Block, 1);
    }
  }, {
    ingredients: [DieFace.Agility, DieFace.Reflex],
    title: 'Duck and Weave',
    text: 'Gain 2 Dodge and 2 Delay (Dodge)',
    tags: ['defence', 'buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Dodge, 2);
      addModifier(source, StatusEffect.Delay, 2, { imbue: StatusEffect.Dodge });
    }
  },

  {
    ingredients: [DieFace.Mana, DieFace.Mana, DieFace.Blank],
    title: 'Unlimited Power',
    text: 'Gain 1 Powerful.',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      /* const dice = source.dice.map((dice: string, index: number) => {
        return createDie(die, index, 'x' + index);
      });
      const die = await selectDie({ dice });
      const level = Math.min(3, die.level + 1);
      die.setLevel(level);

      source.dice = dice.map((dice: Die) => {
        return die.label;
      }); */
      addModifier(source, StatusEffect.Powerful, 1);
    }
  }, {
    ingredients: [DieFace.Brain, DieFace.Mana],
    title: 'Twin Cast',
    text: 'Repeat next Technique.',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.TwinSpell, 1);
    }
  },
  /*,{
   ingredients: [DieFace.Mana, DieFace.Mana],
   title: 'Split Mana',
   text: 'Invoke 2 mana die.',
   activate: (target: Player, source: Player, dice: Array<Die>) => {
     invokeDie(DieFace.Mana, 1, 0);
     invokeDie(DieFace.Mana, 1, 0);
   }
 }*/

 {
    ingredients: [DieFace.Blood, DieFace.Bone],
    title: 'Healing',
    text: 'Heal 2 Health.',
    tags: ['heal'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      healDamage({ value: 2, target: source, source, dice });
    }
  }, {
    ingredients: [DieFace.Blood, DieFace.Blood, DieFace.Blank],
    title: 'Regeneration',
    text: 'Heal 2 health, Gain 2 Regeneration.',
    tags: ['heal', 'buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      healDamage({ value: 2, target: source, source, dice });
      addModifier(source, StatusEffect.Regen, 2);
    }
  },
];
