import type { Die } from "../die.type";
import { Modifier } from "../modifier";

export class Powerful extends Modifier {
  update(stacks: number, params?) {
    super.update(stacks, params);
    this.delay = true;
  }

  endTurn() {
    if (this.delay) {
      const focus = this.owner.statusEffects.find(modifier => modifier.type === 'focus');
      if (!focus) {
        this.delay = false;
      }
    }
    else {
      this.removeStacks(1);
    }
  }

  startTurn(state) {
    for (let i = this.stacks; i > 0; i--) {
      const dice = state.activeDie.filter((die: Die) => {
        return die.level < 2;
      });

      if (!dice){
        return;
      }

      const index = Math.floor(Math.random() * dice.length);
      let die = dice[index];

      if (die.level < 2) {
        die.setLevel(die.level + 1);
      }
      else {
        i++;
      }
    }
    // this.removeStacks(1);
    return state;
  }
}