import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';


export const dagger: Technique[] = [
  {
    ingredients: [DieFace.Dagger],
    title: 'Stab',
    text: 'Deal 1 damage',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Dagger, DieFace.Dagger],
    title: 'Double Stab',
    text: 'Deal 1 damage 2 times, Invoke Dagger',
    tags: ['damage', 'chain'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      dealDamage({ value: 1, target, source, dice });
      // dealDamage({value: 1, target, source});
      invokeDie([DieFace.Dagger]);
    }
  }, {
    ingredients: [DieFace.Dagger, DieFace.Dagger, DieFace.Dagger],
    title: 'Stabfest',
    text: 'Deal 1 damage 4 times, Invoke 2 Dagger',
    tags: ['damage', 'chain'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      dealDamage({ value: 1, target, source, dice });
      dealDamage({ value: 1, target, source, dice });
      dealDamage({ value: 1, target, source, dice });
      invokeDie([DieFace.Dagger, DieFace.Dagger]);
    }
  }, {
    ingredients: [DieFace.Dagger, DieFace.Poison],
    title: 'Poisoned Dagger',
    text: 'Deal 1 damage 2 times, Apply 1 Poisoned 2 times',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Poison, 1);
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Poison, 1);
    }
  }, {
    ingredients: [DieFace.Dagger, DieFace.Blood],
    title: 'Serated Dagger',
    text: 'Deal 1 damage 2 times, Apply 1 Bleed 2 times',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Bleed, 1);
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Bleed, 1);
    }
  }, {
    ingredients: [DieFace.Dagger, DieFace.Eye],
    title: 'Backstab',
    text: 'Deal 2 Pure damage.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      healthRemove({ value: 2, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Dagger, DieFace.Fist],
    title: 'Throw Dagger',
    text: 'Deal 2 Damage. With Accuracy deal 3 instead.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      if (source.statusEffects.find(mod => mod.type == 'accuracy')) {
        dealDamage({ value: 3, target, source, dice });
      }
      else{
        dealDamage({ value: 2, target, source, dice });
      }
    }
  }
];
