import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';
import { healthRemove } from "../player.type";

export class Stealth extends Modifier {
  damageIn(damage: Damage) {
    damage.value -= this.stacks;
    damage.value = Math.max(0, damage.value);
    // damage.value = 0;
    this.removeStacks(1);
  }

  damageOut(damage: Damage) {
    // damage.value = Math.ceil(damage.value * (1 + this.stacks * 0.25));
    // this.removeStacks(this.stacks);

    healthRemove(damage);
    damage.value = 0;
    this.removeStacks(1);
  }
}