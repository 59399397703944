<script lang='ts'>
  import Die from "../../dice-tray/die.svelte";
  import { createDie } from "../../models/die.type";
  import { send, receive } from '../../../animation';
  import Icon from '../../icon/icon.svelte';

  var die = createDie('longsword');

  var lockedDie1 = [];
  var unlockedDie1 = [die];

  var lockedDie2 = [die.clone()];
  var unlockedDie2 = [die.clone()];
  var rerollDie;
  var rerollsRemaining = 2;

  var lockedDie3 = [die.clone()];
  var unlockedDie3 = [die.clone()];

  const lockDie = (die) => {
    lockedDie1.push(die);
    unlockedDie1.pop();
    lockedDie1 = lockedDie1;
    unlockedDie1 = unlockedDie1;
  };
  const unlockDie = (die) => {
    lockedDie1.pop();
    unlockedDie1.push(die);
    lockedDie1 = lockedDie1;
    unlockedDie1 = unlockedDie1;
  };

  const reroll = () => {
    if(rerollsRemaining > 0 && rerollDie){
      rerollDie.roll();
      rerollsRemaining --;
    }
  };

  const lock3 = () => {
    if(unlockedDie3.length){
      var die = unlockedDie3.pop();
      lockedDie3.push(die);
      lockedDie3 = lockedDie3;
      unlockedDie3 = unlockedDie3;
    }
  };
</script>

<div class='container'>

  <h2>Roll Phase</h2>
  <p>Place your cursor over the die to view its faces. The left faces tend to be more favourable than the right ones.</p>
  <div class='die-container' style='margin: auto'>
    <Die die={die} index='1' hover={true}/>
  </div>

  <hr/>
  <p>Click the die on the board to lock it's face. Click on the die in the tray to unlock it.</p>

  <div class='die-row'>
    <div class='die'>
      <div class='slot'></div>
      {#each lockedDie1 as die, index (die.id)}
        <div
          class='die-holder'
          in:receive="{{key: die.id}}"
          out:send="{{key: die.id}}">
          <Die die={die} index='0' hover={true} die3D={false} clickEvent={unlockDie}/>
        </div>
      {/each}
    </div>

    <div class='die'>
      {#each unlockedDie1 as die, index (die.id)}
        <div
          class='die-holder'
          in:receive="{{key: die.id}}"
          out:send="{{key: die.id}}">
          <Die die={die} index='0' hover={true} die3D={false} clickEvent={lockDie}/>
        </div>
      {/each}
    </div>
  </div>

  <hr/>
  <p>Click the Reroll button to roll all unlocked dice again and assign them new faces. Keep in mind that you only have a certain number of rerolls per turn shown by the number in the lower right of the button.</p>
  <div class='die-row'>
    <div class='die'>
      <div class='slot'></div>
      {#each lockedDie2 as die, index (die.id)}
        <div
          class='die-holder'
          in:receive="{{key: die.id}}"
          out:send="{{key: die.id}}">
          <Die die={die} index='0' hover={true} die3D={false}/>
        </div>
      {/each}
    </div>

    <div class='die'>
      {#each unlockedDie2 as die, index (die.id)}
        <div
          class='die-holder'
          in:receive="{{key: die.id}}"
          out:send="{{key: die.id}}">
          <Die die={die} index='0' hover={true}
            bind:this={rerollDie}
          />
        </div>
      {/each}
    </div>
  </div>
  <div class='flex-center'>
    <div class='action btn' on:click={reroll} on:keydown={()=>{}}>
      <Icon name='reroll' width='60px' height='60px'/>

      <div class='sub'>
        {rerollsRemaining}
      </div>
    </div>
  </div>

  <hr/>
  <p>Once you have used up all of your rerolls, you will be required to lock all of the die.</p>
  <div class='die-row'>
    <div class='die'>
      <div class='slot'></div>
      <div class='slot' style='margin-left: 10px;'></div>
      {#each lockedDie3 as die, index (die.id)}
        <div
          class='die-holder'
          style='left: {90 * (index+1) +index*20 +"px"}'
          in:receive="{{key: die.id}}"
          out:send="{{key: die.id}}">
          <Die die={die} index='0' hover={true} die3D={false} clickEvent={unlockDie}/>
        </div>
      {/each}
    </div>

    <div class='die'>
      {#each unlockedDie3 as die, index (die.id)}
        <div
          class='die-holder'
          in:receive="{{key: die.id}}"
          out:send="{{key: die.id}}">
          <Die die={die} index='0' hover={true} die3D={false}/>
        </div>
      {/each}
    </div>
  </div>
  <div class='flex-center'>
    <div class='action btn' on:click={lock3} on:keydown={()=>{}}>
      <Icon name='lock' width='40px' height='40px'/>
    </div>
  </div>

  <hr/>
  <h2>Action Phase</h2>
  <p>Using the faces of the locked dice, you can now choose which actions to take.</p>
  <p>Techniques that you can use will be displayed at the top. Solid dice show symbols that you have available, while transparent dice show symbols that are not available.</p>
  <hr/>
  <p>Click on a die in the tray to reveal and filter all the valid recipes using that face.</p>
  <hr/>
  <p>Click on the action arrow to perform a technique. This will use up all of the dice required for that technique. If you did not manually select the dice beforehand, they will be automatically chosen for you.</p>
  <hr/>
  <p>Actions done are added to the history bar at the top. Mouse over the items to see a breakdown of what the action did.</p>
  <hr/>
  <p>You can undo the action using the undo button to refund the dice used and reset the action done to the players.</p>
  <hr/>
  <p>When you have used all the die, click the end turn button to swap </p>

  <hr/>
  <h2>Enemy Turn</h2>
  <p>The enemy will take their turn rolling and acting, and will pass their turn to you when they are done.</p>

  <hr/>
  <h2>Health and Status</h2>
  <p>The game will end once one character has their health reduced to zero.</p>
  <p>The red health bars are the main health, and when these are removed that character loses. There are other coloured health bars</p>

</div>

<style lang='less'>
  .container{
    border: 2px solid var(--background-colour-light);
    padding: 5px;

    display: grid;
    align-items: center;
    text-align: left;
    grid-gap: 10px;
    padding: 10px;
  }
  h2{
    text-transform: capitalize;
  }

  hr{
    width: 100%;
    height: 1px;
    border: none;
    background: linear-gradient(to right, transparent, var(--background-colour-light), transparent);
  }

  .action.btn{
    width: 100px;
    height: 100px;
    position: relative;

    .sub{
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 16px;
      position: absolute;
      background-color: var(--background-colour);
      width: 25%;
      height: 25%;
      right: 0;
      bottom: 0;
      border-radius: 100px;
      border: 3px solid var(--background-colour-light);
      border-style: outset;
    }
  }

  .die-row{
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
  }
  .die-container{
    position: relative;
  }
  .die-holder{
    position: absolute;
  }
  .slot{
    border-radius: 4px;
    height: calc(var(--dice-size) + 20px);
    width: calc(var(--dice-size) + 20px);
    background: var(--background-colour);
    box-shadow: inset var(--background-colour-dark) 5px 5px 10px;
    outline: var(--background-colour-dark) 2px solid;
    display: flex;
    align-items: center;
    justify-content: center;
  }
</style>