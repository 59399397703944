import { addModifier, type Player } from "../player.type";
import { addMoney, healthRemove } from "../player.type";
import { screens } from '../../../store';
import type { MapEvent,  } from "../events.type";
import { createHasCondition } from "../events.type";
import { DieFace } from "../face.type";
import { StatusEffect } from "../status-effects";
import { Events } from '../eventList';

const leave = {
  text: 'Leave',
  activate: (player, map, node) => {
    screens.pop();
  }
};

export const campevents: Array<MapEvent> = [
  {
    title: 'Campfire',
    type: 'campfire',
    image: 'campfire1',
    text: 'In a forest clearing, a crackling campfire emits a warm glow, offering solace amidst the tranquil surroundings.',
    id: Events.Campfire,
    step: {
      text: 'In a forest clearing, a crackling campfire emits a warm glow, offering solace amidst the tranquil surroundings.',
      options: [
        { // default action to heal
          text: 'Rest by the comforting campfire and allow its gentle warmth to soothe your tired body.',
          activate: (player, map, node) => {
            // heal player to full
            player.healthCurrent = player.healthMax;
            addModifier(player, StatusEffect.Rested, 3, { duration: 2 });
          },
          next: {
            text: 'As you bask in the campfire\'s healing radiance, your wounds begin to mend, and a sense of rejuvenation washes over you, restoring your health and revitalizing your spirit.',
            options: [leave]
          }
        }, { // default action to heal
          text: 'Use your knowledge of traditional medicine to cure your wounds.',
          condition: {
            text: 'Has Wound',
            icon: 'wound',
            fulfils: (player: Player) => {
              return !!player.statusEffects.find(modifier => {
                return modifier.type == 'wound';
              });
            }
          },
          activate: (player, map, node) => {
            //remove wound
            player.healthCurrent = player.healthMax;
            const wound = player.statusEffects.find(modifier => {
              return modifier.type == 'wound';
            });
            wound.removeStacks(wound.stacks);
          },
          next: {
            text: 'As you bask in the campfire\'s healing radiance, your wounds begin to mend, and a sense of rejuvenation washes over you, restoring your health and revitalizing your spirit.',
            options: [leave]
          }
        },
        { // if player has brain die, gain xp
          text: 'Settle into a tranquil meditation and connect with the essence of the surrounding nature.',
          condition: createHasCondition('brain'),
          activate: (player, map, node) => {
            //give player 10 bonus xp
            player.experience += 40;
          },
          next: {
            text: 'As you delve deeper into your meditation, a profound realization dawns upon you, unlocking hidden potential within.',
            options: [leave]
          }
        },/*
        { // if player has sword die, upgrade it
          text: 'Withdraw your sword from its scabbard and begin the meticulous task of sharpening the edge.',
          condition: createHasCondition('sword'),
          activate: (player, map, node) => {
            // upgrade the die
          },
          next: {
            text: 'With a focused intent, you begin to hone the blade, rhythmically gliding the whetstone along its edge, each stroke adding a keenness that reflects the flickering light, signifying your preparation for the challenges ahead.',
            options: [leave]
          }
        },
        { // if player has hammer die, upgrade it
          text: 'Strike the hammer's blunt end against a rock, dulling its surface.',
          condition: createHasCondition('hammer'),
          activate: (player, map, node) => {
            // upgrade the die
          },
          next: {
            text: 'As you blunten your hammer, its once sharpened edges transform into a mighty force, capable of delivering powerful, bone-crushing blows. The process of tempering the weapon ensures that its strength and durability are maximized, granting you an improved advantage when wielding your reinforced hammer in future battles.',
            options: [leave]
          }
        }, */
        { // if player has fire die, gain infusion (fire)
          text: 'Place your hands near the campfire, channeling your inner energy and elemental knowledge into the flames.',
          condition: createHasCondition('fire'),
          activate: (player, map, node) => {
            // gain fire infusion
            addModifier(player, StatusEffect.Infusion, 3, { duration: 2, infusion: DieFace.Fire });
          },
          next: {
            text: 'As the campfire dances with newfound fervor, you feel a surge of elemental connection, earning bonus fire essence that courses through your being, augmenting your fire-based abilities and granting you an advantage in future battles.',
            options: [leave]
          }
        },
        { // if player has fist die, gain strengthen
          text: 'Seize the opportunity to hone your physical prowess.',
          condition: createHasCondition('fist'),
          activate: (player, map, node) => {
            addModifier(player, StatusEffect.Strength, 1, { duration: 2 });
          },
          next: {
            text: 'Through your dedication to training, your body becomes more resilient and agile, and your combat skills improve significantly, empowering you with newfound strength for the battles that await you in the wilderness.',
            options: [leave]
          }
        },
        leave
      ]
    }
  }
];
/*
let eventsArray = [];
export const getEvent = () => {
  if (!eventsArray.length){
    eventsArray = [...events];
    //random sort events Array
    eventsArray.sort(() => Math.random() - 0.5);
  }

  return eventsArray.pop();
}; */