import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Die } from '../die.type';
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import { getAmmoEffect, hasAmmo, shootAmmo } from './ammo';
import { Stances } from '../status-effects/stance';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';

export const bow: Technique[] = [
  {
    requirements: [hasAmmo],
    ingredients: [DieFace.Bow],
    title: 'Single Shot',
    text: 'Shoot one stored Ammo.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const effect = getAmmoEffect(target, source, dice);
      shootAmmo(effect, target);
    }
  },{
    requirements: [hasAmmo],
    ingredients: [DieFace.Bow, DieFace.Bow],
    title: 'Volley',
    text: 'Shoot 3 stored Ammo.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const ammo = source.statusEffects.find(modifier => {
        return modifier.type == 'ammo';
      });
      var count = Math.min(ammo.stacks, 3);
      for(let i = 0; i < count; i++){
        const effect = getAmmoEffect(target, source, dice);
        shootAmmo(effect, target);
      }
    }
  },{
    requirements: [hasAmmo],
    ingredients: [DieFace.Bow, DieFace.Bow, DieFace.Bow],
    title: 'Blot out the Sun',
    text: 'Shoot 5 stored ammo.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const ammo = source.statusEffects.find(modifier => {
        return modifier.type == 'ammo';
      });
      var count = Math.min(ammo.stacks, 5);
      for (let i = 0; i < count; i++) {
        const effect = getAmmoEffect(target, source, dice);
        shootAmmo(effect, target);
      }
    }
  },{
    ingredients: [DieFace.Bow, DieFace.Ammo],
    title: 'Quick Shot',
    text: 'Deal 2 Damage. Invoke Bow.',
    tags: ['attack', 'chain'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({value: 2, target, source, dice});
      invokeDie([DieFace.Bow]);
    }
  }, {
    requirements: [hasAmmo],
    ingredients: [DieFace.Bow, DieFace.Eye],
    title: 'Accurate Shot',
    text: 'Gain 1 Accuracy. Shoot 1 ammo.',
    tags: ['attack', 'buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Accuracy, 1);
      const effect = getAmmoEffect(target, source, dice);
      shootAmmo(effect, target);
    }
  }, {
    // requirements: [hasAmmo],
    ingredients: [DieFace.Bow, DieFace.Reflex],
    title: 'Draw Bow',
    text: 'Gain Stance (Prepared).',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Stance, 1, { stance: Stances.Prepared })
    }
  }
];
