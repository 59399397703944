import mixpanel from 'mixpanel-browser';
import * as Sentry from "@sentry/browser";

if(location.origin == 'https://odam.clarklavery.com'){
  mixpanel.init('a1139fb41c737299c307e49aff6a6bdc', { debug: true, track_pageview: true, persistence: 'localStorage' });
}

// Set this to a unique identifier for the user performing the event.
// mixpanel.identify('USER_ID')

export function track(eventName, properties) {
  if(location.origin == 'https://odam.clarklavery.com'){
    mixpanel.track(eventName, properties);

    Sentry.setContext(eventName, properties);
  }
}