import { definitions as songDefinitions } from "./status-effects/song";
import { definitions as ammoDefinitions } from "./status-effects/ammo";
import { definitions as stanceDefinitions } from "./status-effects/stance";

export const keywords = {
  'fade': 'Reduce the number of stacks by one.',
  'halve': 'Reduce the number of stacks by half rounded down.',
  'vanish': 'Remove status effect.',

  'apply': 'Give status effect to enemy.',
  'gain': 'Give status effect to self.',

  'boon': 'A positive status effect.',
  'bane': 'A negative status effect.',

  'shoot': 'Consume ammo to deal its effect.',
  'deal': 'Deal damage to enemy.',
  'take': 'Deal damage to self.',
  'heal': 'Heal damage to self.',

  'stack': 'Number of charges on a status effect.',
  'stacks': 'Number of charges on a status effect.',
  'pure': 'Will ignore all status effects when calculating damage.',
  'invoke': 'Create a new die of the invoked type.',
  'purge': 'Remove a beneficial status effect from the target.',
  'cleanse': 'Remove a harmful status effect from the target.',
  'instant': 'Technique is used in the rolling phase when possible.',
  'prevent': 'Cancel a technique before it is used.',
  'perform': 'Play a new Song.',
  'performing': 'Playing a Song.',

  'upgrade': 'Increase the level of a die by 1.',
  'downgrade': 'Decrease the level of a die by 1.',

  'consume': 'Removes the item from your inventory.',
  'lasting': 'Will not reduce stacks on its own.',
  'stable': 'Will fade at the end of the owner\'s next turn. Reapplying will refresh the timer.',
  'temporary': 'Will vanish at the start of the owner\'s next turn.',
};

//add song definitions
Object.keys(songDefinitions).forEach((key) => {
  keywords[`song (${key.toLowerCase()})`] = songDefinitions[key].text;
});
Object.keys(ammoDefinitions).forEach((key) => {
  keywords[`ammo (${key.toLowerCase()})`] = ammoDefinitions[key].text;
});
Object.keys(stanceDefinitions).forEach((key) => {
  keywords[`stance (${key.toLowerCase()})`] = stanceDefinitions[key].text;
});
