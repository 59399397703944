import type { itemDefinition } from "./item.type";

import { consumables } from "./items/consumables";
import { dies } from "./items/dies";
import { specials } from "./items/specials";
import { potions } from "./items/potions";

export const createItem = (type: string) => {
  const itemDefinition = items.find(item => item.type == type);
  const item = new itemDefinition.class(itemDefinition);
  return item;
};

export const items: Array<itemDefinition> = []
//@ts-ignore
.concat(consumables).concat(dies).concat(specials).concat(potions);
