<script lang="ts">
  import Portrait from "../../components/portrait.svelte";
  import { createDie } from "../../models/die.type";
  import Die from '../../dice-tray/die.svelte';
  import type { PlayerDefinition } from '../../models/player.type';

  export var data: PlayerDefinition;

  let dice1 = [];
  let dice2 = [];
  let dice3 = [];

  if(data){
    dice1 = data.dice1.map((die, index) => {
      return createDie(die, index, 'p'+index);
    });
    dice2 = data.dice2.map((die, index) => {
      return createDie(die, index, 'p'+index);
    });
    dice3 = data.dice3.map((die, index) => {
      return createDie(die, index, 'p'+index);
    });
  }
</script>

<div class='container'>
  <div style='--player-colour:{data.colour}'>
    <Portrait data={data}></Portrait>
  </div>

  <div class='information'>
    <h2>{data.title}</h2>
    <h3>{data.text}</h3>

    <h3>Weapons</h3>
    <div class='die-list'>
      {#each dice1 as die, index (die.id)}
        <div
          class='die-container'
        >
          <Die die={die} index={index} die3D={false} hover={true} sizeMod={1}/>
        </div>
      {/each}
    </div>

    <h3>Items</h3>
    <div class='die-list'>
      {#each dice2 as die, index (die.id)}
        <div
          class='die-container'
        >
          <Die die={die} index={index} die3D={false} hover={true} sizeMod={1}/>
        </div>
      {/each}
    </div>

    <h3>Abilities</h3>
    <div class='die-list'>
      {#each dice3 as die, index (die.id)}
        <div
          class='die-container'
        >
          <Die die={die} index={index} die3D={false} hover={true} sizeMod={1}/>
        </div>
      {/each}
    </div>
  </div>
</div>

<style lang='less'>
  .container{
    border: 2px solid var(--background-colour-light);

    display: flex;
    flex-direction: row;

    .information{
      padding: 5px;
      text-align: left;

      .die-list{
        text-align: center;
        display: flex;
        grid-gap: 10px;

        .die-container{
          position: relative;
        }
      }
    }
  }

  h2{
    text-transform: capitalize;
  }
  h3{
    margin-bottom: 5px;
  }
</style>