<script lang='ts'>
  import Icon from '../../icon/icon.svelte';
  import { code } from '../../modals/modals';
  import Text from '../../components/text.svelte';

  export var data;
  export var tags:  Array<string>;
  let display = true;

  function viewCode(){
    code({
      title: 'Code',
      text: data.class
    });
  }

  $: if (tags.length !== -1) {
    display = false;

    // set display to true if all the values in data.tags also exist in tags
    if(tags.every((tag: string) => data.tags.includes(tag))){
      display = true;
    }
  }
</script>

{#if display}
  <div class='container'>
    <h2>{data.title}</h2>

    <div class='information'>
      <Icon name={data.icon}/>

      <Text data={data.text}/>

      <!-- <button on:click={viewCode}>View Code</button> -->
    </div>
  </div>
{/if}

<style lang='less'>
  @import "../../../defines.less";

  .container{
    border: 2px solid var(--background-colour-light);
    /* --icon-size: 75px;
    @media @medium{
      --icon-size: 50px;
    } */

    .information{
      display: grid;
      grid-template-columns: 75px 1fr;
      grid-gap: 10px;

      padding: 5px;
      text-align: left;

      @media @medium{
        grid-template-columns: 50px 1fr;
      }
    }
  }

  h2{
    text-transform: capitalize;
  }
</style>