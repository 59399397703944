import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import type { Player } from '../player.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import { Stances } from '../status-effects/stance';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';


export const spear: Technique[] = [
  {
    ingredients: [DieFace.Spear],
    title: 'Poke',
    text: 'Deal 1 Damage.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Spear, DieFace.Spear],
    title: 'Thrust',
    text: 'Deal 2 damage, gain 1 Spikes.',
    tags: ['buff', 'damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(source, StatusEffect.Spikes, 1);
    }
  }, {
    ingredients: [DieFace.Spear, DieFace.Spear, DieFace.Spear],
    title: 'Lance',
    text: 'Deal 4 damage, gain 2 Spikes.',
    tags: ['buff', 'damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 4, target, source, dice });
      addModifier(source, StatusEffect.Spikes, 2);
    }
  }, /* {
    ingredients: [DieFace.Spear, DieFace.Eye],
    title: 'Precise',
    text: '',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {

    }
  }, */ {
    ingredients: [DieFace.Spear, DieFace.Fist],
    title: 'Hurl Javelin',
    text: 'Deal 3 damage. With accuracy, deal 3 pure damage.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const accuracy = source.statusEffects.find(modifier => {
        return modifier.type == 'accuracy';
      });
      if (accuracy) {
        healthRemove({ value: 3, target, source, dice });
      }
      else {
        dealDamage({ value: 3, target, source, dice });
      }
    }
  }, {
    ingredients: [DieFace.Spear, DieFace.Reflex],
    title: 'Opportunity Attack',
    text: 'Gain Stance (Interrupt)',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Stance, 1, { stance: Stances.Interrupt })
    }
  }, {
    ingredients: [DieFace.Spear, DieFace.Web],
    title: 'Retiarius Cast',
    text: 'Deal 2 damage and apply 1 Snared. If target was already snared, apply 1 stunned.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const snared = target.statusEffects.find(modifier => {
        return modifier.type == 'snared';
      });
      if (snared) {
        addModifier(target, StatusEffect.Stunned, 1);
      }
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, StatusEffect.Snared, 1);
    }
  }
];
