import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';


export const stinger: Technique[] = [
  {
    ingredients: [DieFace.Stinger],
    title: 'Sting',
    text: 'Deal 1 Damage.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Stinger, DieFace.Stinger],
    title: 'Double Sting',
    text: 'Deal 2 Damage, Invoke Stinger.',
    tags: ['damage', 'chain'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      invokeDie([DieFace.Stinger]);
    }
  }, {
    ingredients: [DieFace.Stinger, DieFace.Stinger, DieFace.Stinger],
    title: 'Stingfest',
    text: 'Deal 2 Damage 2 times, Invoke 2 Stinger.',
    tags: ['damage', 'chain'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      dealDamage({ value: 2, target, source, dice });
      invokeDie([DieFace.Stinger, DieFace.Stinger]);
    }
  }, {
    ingredients: [DieFace.Stinger, DieFace.Poison],
    title: 'Inject Poison',
    text: 'Deal 1 Damage, Apply 1 Poisoned. Gain 1 Imbue (Poisoned).',
    tags: ['damage', 'buff', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Poison, 1);
      addModifier(source, StatusEffect.Imbue, 1, { imbue: StatusEffect.Poison });
    }
  }/*,{
    ingredients: [DieFace.Stinger, DieFace.Agility],
    title: 'Pounce',
    text: '2 Damage',
    activate: (target: Player, source: Player, dice: Array<Die>) => {
    }
  }*//* , {
    ingredients: [DieFace.Stinger, DieFace.Wings],
    title: 'Pounce',
    text: '2 Damage, 2 dodge',
    activate: (target: Player, source: Player, dice: Array<Die>) => {
    }
  }, */
];
