
export const memory = {

  init: () => {
    // initialise persist vars
    let persist: string | object = localStorage.getItem('odampersist');
    if(persist){
      persist = JSON.parse(persist);
    }
    else{
      persist = {};
    }
    localStorage.setItem('odampersist', JSON.stringify(persist));

    // initialise session vars
    localStorage.setItem('odamsession', '{}');
  },

  get: function(key){
    var persist = JSON.parse(localStorage.getItem('odampersist'));
    if(persist.hasOwnProperty(key)){
      return persist[key];
    }

    var session = JSON.parse(localStorage.getItem('odamsession'));
    if(session.hasOwnProperty(key)){
      return session[key];
    }

    return '';
  },

  store: function(key, value){
    var session = JSON.parse(localStorage.getItem('odamsession'));
    session[key] = value;
    localStorage.setItem('odamsession', JSON.stringify(session));
  },

  persist: function(key, value){
    var persist = JSON.parse(localStorage.getItem('odampersist'));
    persist[key] = value;
    localStorage.setItem('odampersist', JSON.stringify(persist));
  },

  clear: function(){
    localStorage.setItem('odamsession', '{}');
    localStorage.setItem('odampersist', '{}');
  }
}