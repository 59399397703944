import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';


export const bite: Technique[] = [
  {
    ingredients: [DieFace.Bite],
    title: 'Bite',
    text: 'Deal 1 damage',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Bite, DieFace.Blood],
    title: 'Drain',
    text: 'Deal 2 damage, heal damage dealt as health.',
    tags: ['damage', 'heal'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      var damage = dealDamage({ value: 2, target, source, dice });
      healDamage({ value: damage.value, target: source, source, dice });
    }
  }, {
    ingredients: [DieFace.Bite, DieFace.Agility],
    title: 'Lunge',
    text: 'Deal 3 Damage',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Bite, DieFace.Bite],
    title: 'Chomp',
    text: 'Deal 3 Damage',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Bite, DieFace.Bite, DieFace.Bite],
    title: 'Chompfest',
    text: 'Deal 3 Damage 2 times',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
      dealDamage({ value: 3, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Bite, DieFace.Note],
    title: 'Bark',
    text: 'Deal 1 Damage, Apply 1 Vulnerable',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Vulnerable, 1);
    }
  }
];
