import type { itemDefinition } from '../item.type';
import { type Player, healDamage, hasItem, removeItem, addModifier } from "../player.type";
import type { Die } from "../die.type";
import { Item, Items } from '../item.type';

export const dies: Array<itemDefinition> = [
  {
    type: Items.CorestoneFragmentFire,
    title: 'Corestone Fragment (fire)',
    text: 'Gain lvl.-1 Fire die.',
    icon: 'corestone',
    bonusIcon: 'fire',
    class: class extends Item {
      getDice(): Array<string> {
        return ['fire>-1'];
      }
    }
  }, {
    type: Items.CorestoneFragmentLightning,
    title: 'Corestone Fragment (lightning)',
    text: 'Gain lvl.-1 Lightning die.',
    icon: 'corestone',
    bonusIcon: 'lightning',
    class: class extends Item {
      getDice(): Array<string> {
        return ['lightning>-1'];
      }
    }
  }, {
    type: Items.CorestoneFragmentIce,
    title: 'Corestone Fragment (ice)',
    text: 'Gain lvl.-1 Ice die.',
    icon: 'corestone',
    bonusIcon: 'ice',
    class: class extends Item {
      getDice(): Array<string> {
        return ['ice>-1'];
      }
    }
  }, {
    type: Items.CorestoneFragmentEarth,
    title: 'Corestone Fragment (earth)',
    text: 'Gain lvl.-1 Earth die.',
    icon: 'corestone',
    bonusIcon: 'earth',
    class: class extends Item {
      getDice(): Array<string> {
        return ['earth>-1'];
      }
    }
  }, {
    type: Items.RustyShiv,
    title: 'Rusty Shiv',
    text: 'Gain lvl.-1 Shiv die.',
    icon: 'shiv',
    class: class extends Item {
      getDice(): Array<string> {
        return ['shivs>-1'];
      }
    }
  }, {
    type: Items.RustyCoin,
    title: 'Rusty Coin',
    text: 'Gain lvl.1 Lucky Coin die.',
    icon: 'coin',
    class: class extends Item {
      getDice(): Array<string> {
        return ['luckycoin'];
      }
    }

  }
];