import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Player } from '../player.type';
import type { Technique, Requirement } from "../techniques.type";
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';


export const beak: Technique[] = [
  {
    ingredients: [DieFace.Beak],
    title: 'Peck',
    text: 'Deal 1 damage',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
    }
  },{
    ingredients: [DieFace.Beak, DieFace.Beak],
    title: 'Double Peck',
    text: 'Deal 2 damage, Apply 1 Blind.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, StatusEffect.Blind, 1);
    }
  },{
    ingredients: [DieFace.Beak, DieFace.Beak, DieFace.Beak],
    title: 'Triple Peck',
    text: 'Deal 3 damage, Apply 2 Blind.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
      addModifier(target, StatusEffect.Blind, 2);
    }
  },{
    ingredients: [DieFace.Beak, DieFace.Wings],
    title: 'Swoop',
    text: 'Deal 3 damage.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
    }
  },{
    ingredients: [DieFace.Beak, DieFace.Eye],
    title: 'Precision Peck',
    text: 'Gain 1 Accuracy, Deal 2 Damage',
    tags: ['damage', 'buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Accuracy, 2);
      dealDamage({ value: 2, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Beak, DieFace.Claws],
    title: 'Gouge',
    text: 'Deal 2 Damage, Apply 2 blind',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, StatusEffect.Blind, 2);
    }
  }, {
    ingredients: [DieFace.Beak, DieFace.Note],
    title: 'Screech',
    text: 'Deal 1 Damage, Apply 1 Distracted.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Distracted, 1);
    }
  },
];
