<script lang='ts'>
  import { promptData } from './modals';

  let onConfirm = null;
  let promptText = '';

  promptData.subscribe(value => {
    if(value && value.callbackConfirm){
      onConfirm = value.callbackConfirm;
    }
    else{
      onConfirm = null;
    }
  });

  const confirm = () => {
    if(onConfirm){
      onConfirm(promptText);
    }
    promptData.set(null);
    promptText = '';
  }

  const cancel = () => {
    promptData.set(null);
    promptText = '';
  }
</script>

{#if $promptData}
<div class='modal-container'>
  <div class='modal'>
    {#if $promptData.title}
      <div class='header'>
        <h1>{$promptData.title}</h1>
      </div>
    {/if}

    {#if $promptData.text}
      <div class='body'>
        <p>{$promptData.text}</p>

        <textarea bind:value={promptText} placeholder='Enter report here...'></textarea>
      </div>
    {/if}

    <div class='functions'>
      <button class='btn' on:click={cancel}>Cancel</button>
      <button class='btn' on:click={confirm}>Confirm</button>
    </div>
  </div>
</div>
{/if}

<style lang='less'>
  p{
    margin-bottom: 20px;
  }
  textarea{
    width: 100%;
    height: 100px;
  }
</style>