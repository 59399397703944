<script lang='ts'>
  import Icon from "../icon/icon.svelte";
  import type { EnemyDefinition } from "../models/enemy.type";
  import type { Player, PlayerDefinition } from "../models/player.type";
  import Hover from './hover.svelte';
  import { openInventory } from "../modals/modals";

  export let data: Player | PlayerDefinition | EnemyDefinition | {type:string} = {type:''};

  let player = data as Player;

  let effect = '';
  if(player && player.hasOwnProperty('takeDamage')){
    player.takeDamage = () => {
      effect = 'damage';

      setTimeout(() => {
        effect = '';
      }, 300);
    };
  }

  function openInv(){
    openInventory({
      inventory: player.inventory,
      player: player
    });
  }

  $: if (data !== undefined) {
    player = data as Player;
  }
</script>

<div class='portrait {player.archetype || data.type} {player.id}'>
  <div class='effect {effect}'></div>
  <div class='frame'></div>
  <!-- <h2>{data.title}</h2> -->

  <div class='inventory'>
    {#if player.inventory?.length > 0}
      <div class='inventory-item' on:click={openInv} on:keydown={()=>{}}>
        <Icon name="backpack" class='icon'/>
      </div>
    {/if}
  </div>

  <div class='traits'>
    {#if player.traits}
    {#each player.traits as trait, index}
    {#if trait.id && trait.icon}
      <div class='trait {trait.tags.join(' ')}'>
        <!-- {#if trait.bonusIcon}
          <Icon name={trait.bonusIcon} width='20px' height='20px' class='icon bonus'/>
        {/if} -->
        <Icon name={trait.icon} class='icon'/>

        {#if trait.stacks !== undefined}
          <svg width='20px' height='20px' viewBox="0 0 50 50" class='stack'>
            <text y='40' x='50%'>{trait.stacks}</text>
          </svg>
        {/if}

        <Hover>
          <div class='icon-container'>
            <Icon name={trait.icon} width='40px' height='40px' class='icon'/>
            <!-- {#if trait.bonusIcon}
              <Icon name={trait.bonusIcon} width='20px' height='20px' class='icon bonus'/>
            {/if} -->
          </div>
          <br/>
          <h2>{trait.title}</h2>
          <br/>
          <p>{trait.text}</p>

          {#if trait.getHTML}
            {@html trait.getHTML()}
          {/if}
        </Hover>
      </div>
    {/if}
    {/each}
    {/if}
  </div>

  <div class='status-effects'>
    {#if player.statusEffects}
    {#each player.statusEffects as modifier, index}
      <div class='status-effect {modifier.tags.join(' ')}' on:click={()=>{if(modifier.onClick){modifier.onClick()}}} on:keydown={()=>{}}>
        {#if modifier.bonusIcon}
          <Icon name={modifier.bonusIcon} width='15px' height='15px' class='icon bonus'/>
        {/if}
        <Icon name={modifier.icon} class='icon'/>

        {#if modifier.stacks}
          <svg width='20px' height='20px' viewBox="0 0 50 50" class='stack'>
            <text y='40' x='50%'>{modifier.stacks}</text>
          </svg>
        {/if}

        <Hover>
          <div class='icon-container'>
            <Icon name={modifier.icon} width='40px' height='40px' class='icon'/>
            {#if modifier.bonusIcon}
              <Icon name={modifier.bonusIcon} width='20px' height='20px' class='icon bonus'/>
            {/if}
          </div>
          <br/>
          <h2>{modifier.title}</h2>
          <br/>
          <p>{modifier.text}</p>

          {#if modifier.getHTML}
            {@html modifier.getHTML()}
          {/if}
        </Hover>
      </div>
    {/each}
    {/if}
  </div>
</div>

<style lang='less'>
  @import "../../defines.less";

  @keyframes damage {
    0%   {box-shadow: inset red 0px 0px 0px 0px;}
    50%  {box-shadow: inset red 0px 0px 50px 10px;}
    100% {box-shadow: inset red 0px 0px 0px 0px;}
  }

  :global(.portrait){
    --icon-size: 30px;
    --stack-size: 15px;
    --padding: 15px;
  }
  @media @medium{
    :global(.portrait){
      --icon-size: 30px;
      --stack-size: 15px;
      --padding: 10px;
    }
  }
  @media @small{
    :global(.portrait){
      --icon-size: 20px;
      --stack-size: 10px;
      --padding: 8px;
    }
  }
  .portrait{
    margin: auto;
    height: var(--column-size);
    width: var(--column-size);
    background-size: 95%;
    background-repeat: no-repeat;
    background-position: top;
    position: relative;
    background-color: var(--background-colour);
    box-shadow: inset black 0px 0px var(--icon-size) 10px;
    @media @small{
      box-shadow: inset black 0px 0px var(--icon-size) 5px;
    }

    .frame{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      border-style: solid;
      border-width: 3px;
      border-color: black;

      outline-style: solid;
      outline-width: 2px;
      outline-color: var(--background-colour);

      margin: 3px;
      box-shadow: 0 0 4px 4px var(--background-colour) inset;

      @media @large{
        border-width: 2px;
        margin: 2px;
      }
      @media @small{
        border-width: 1px;
        outline-width: 1px;
        box-shadow: 0 0 2px 2px var(--background-colour-light) inset;
      }
    }
    .effect{
      position: absolute;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      animation-duration: 0.3s;

      &.damage{
        animation-name: damage;
      }
    }

    &.player{
      .frame{
        outline-color: var(--player-colour);
        box-shadow: 0 0 4px 4px var(--player-colour) inset;

        @media @small{
          box-shadow: 0 0 2px 2px var(--player-colour) inset;
        }
      }
    }

    h2{
      margin-top: 20px;
      text-transform: capitalize;
    }

    .inventory{
      position: absolute;
      top: var(--padding);
      left: var(--padding);
      line-height: 0;
      display: flex;

      .inventory-item{
        position: relative;
        border-width: 3px;
        border-style: none;
        border-radius: 4px;
        background: rgba(0,0,0,0.5);
      }
    }

    .traits{
      position: absolute;
      top: var(--padding);
      right: var(--padding);
      line-height: 0;
      grid-gap: 3px;
      display: flex;

      .trait{
        position: relative;
        border-width: 3px;
        border-style: none;
        border-radius: 4px;
        background: rgba(0,0,0,0.5);

        .stack{
          font-family: arial, sans-serif;
          font-size: 40px;
          font-weight: 600;
          position: absolute;
          z-index: 2;
          bottom: -5px;
          right: -5px;
          text-anchor: middle;
          fill: black;
          stroke: black;
          stroke-width: 2px;
          text-shadow: none;

          width: var(--stack-size);
          height: var(--stack-size);
          border-radius: var(--stack-size);
          background: white;
          color: black;
          border: 2px solid black;
        }
      }
    }
    .status-effects{
      position: absolute;
      bottom: var(--padding);
      right: var(--padding);
      line-height: 0;
      grid-gap: 3px;
      display: flex;
      max-width: calc(var(--column-size) - var(--padding) - var(--padding));
      flex-wrap: wrap-reverse;

      .status-effect{
        position: relative;
        border-width: 3px;
        border-style: solid;
        border-radius: 4px;
        background: rgba(0,0,0,0.5);

        &.boon{
          border-color: #00bb00;
        }
        &.bane{
          border-color: #bb0000;
        }
        &.temporary{
          border-style: dotted;
        }

        :global(.icon.bonus){
          position: absolute;
          z-index: 3;
          top: -5px;
          left: -5px;
          background-color: black;
          width: var(--stack-size);
          height: var(--stack-size);
          border-radius: var(--stack-size);
          // border: 2px solid black;
          padding: 2px;
        }

        :global(h3){
          text-transform: capitalize;
        }
        :global(p){
          margin-bottom: 10px;
        }
      }

      .icon-container{
        width: var(--icon-size);
        margin: auto;
        position: relative;
      }

      .icon-container :global(.icon){
        position: relative;
        z-index: 2;
      }
      .icon-container :global(.icon.bonus){
        position: absolute;
        z-index: 3;
        top: -5px;
        left: -5px;
      }

      .stack{
        font-family: arial, sans-serif;
        font-size: 40px;
        font-weight: 600;
        position: absolute;
        z-index: 2;
        bottom: -5px;
        right: -5px;
        text-anchor: middle;
        fill: black;
        stroke: black;
        stroke-width: 2px;

        width: var(--stack-size);
        height: var(--stack-size);
        border-radius: var(--stack-size);
        background: white;
        color: black;
        border: 2px solid black;
      }
    }
  }

</style>