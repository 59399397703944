import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';
import { healthRemove } from '../player.type';

export class Bleed extends Modifier {
  damageIn(damage: Damage) {
    const bleed: Damage = {
      value: 1,
      target: this.owner,
      source: this.owner,
      dice: []
    };

    healthRemove(bleed);
    this.removeStacks(1);
  }
}