import type { itemDefinition } from '../item.type';
import { type Player, healDamage, hasItem, removeItem, addModifier } from "../player.type";
import { invokeDie } from "../die.type";
import { Item, Items } from '../item.type';
import type { Technique } from '../techniques.type';
import type { Die } from '../die.type';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';

export const specials: Array<itemDefinition> = [
  {
    type: Items.RazielsWhisper,
    title: 'Raziel\'s Whisper',
    text: 'Gain technique: Raziel\'s Shroud.',
    icon: 'cloak',
    class: class extends Item {
      getTechniques(): Array<Technique> {
        return [{
          requirements: [
            {
              text: 'No Stealth',
              icon: 'stealth',
              iconBonus: 'no',
              test: (source: Player) => {
                const infusion = source.statusEffects.find(modifier => {
                  return modifier.type == 'stealth';
                });
                return !infusion;
              }
            }
          ],
          ingredients: [DieFace.Blank],
          title: 'Raziel\'s Shroud',
          text: 'Gain 1 Stealth.',
          tags: ['buff', 'value'],
          activate: (target: Player, source: Player, dice: Array<Die>) => {
            addModifier(source, StatusEffect.Stealth, 1);
          }
        }];
      }
    }
  }
];