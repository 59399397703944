import { Modifier } from "../modifier";

export class Clumsy extends Modifier {
  update(stacks: number, params?) {
    super.update(stacks, params);
    this.delay = true;
  }

  endTurn() {
    if (this.delay) {
      this.delay = false;
    }
    else {
      this.removeStacks(1);
    }
  }

  rollMid(diceElements, dice, promises) {
    /* let rolling = false;
    diceElements.forEach(dieElement => {
      if (dieElement && dieElement.state == 'rolling') {
        rolling = true;
      }
    });
    if (rolling) {
      setTimeout(() => {
        this.rollEnd(diceElements, dice);
      }, 100);
      return;
    } */

    // check if dice rolled value is 6, and if so reroll it
    dice.forEach((die, index) => {
      if (die.activeFace == 5 && diceElements[index]) {
        // diceElements[index].roll();
        promises.push(diceElements[index].roll());
      }
    });
  }
}
