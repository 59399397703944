import { Modifier } from "../modifier";

export class Shocked extends Modifier {
  startTurn(state) {
    for (let i = this.stacks; i > 0; i--) {
      const dice = state.activeDie;

      const index = Math.floor(Math.random() * dice.length);
      let die = dice[index];

      if (die.level > -2) {
        die.setLevel(die.level - 1);
      }
      else {
        i++;
      }
    }
    this.removeStacks(this.stacks);
    return state;
  }
}