import { Modifier, type ModifierParams } from "../modifier";
import type { Damage } from '../damage.type';
import { addModifier } from '../player.type';
import type { Player } from '../player.type';
import { actionHistory } from '../../../store';
import type { StatusEffect } from "../status-effects";

export class Imbue extends Modifier {
  imbue: StatusEffect;

  clone(newOwner: Player) {
    var clone = super.clone(newOwner);
    clone.imbue = this.imbue;

    return clone;
  }

  init(params?: ModifierParams) {
    if (actionHistory) {
      actionHistory.push(`${this.owner.title} gained Imbue (${params.imbue}).`);
    }
    this.update(0, params);
  }

  update(stacks: number, params) {
    if(this.imbue !== params.imbue){
      actionHistory.push(`${this.owner.title} changed imbue from ${this.imbue} to ${params.imbue}.`);
      this.imbue = params.imbue;
      this.title = `${this.type} (${this.imbue})`;
      this.bonusIcon = this.imbue;
      this.stacks = 0;
    }

    if (stacks) {
      this.stacks += stacks;
      actionHistory.push(`Added ${stacks} ${this.type} to ${this.owner.title}.`);
    }
  }

  damageOut(damage: Damage) {
    addModifier(damage.target, this.imbue as StatusEffect, damage.value);
    this.removeStacks(1);
  }
}