import './styles/button.less';
import './styles/die.less';
import './styles/portraits.less';
import './app.css'
import App from './App.svelte'
//@ts-ignore
import { registerSW } from 'virtual:pwa-register';
import { confirm } from './lib/modals/modals';

const app = new App({
  target: document.getElementById('app')
})

export default app;

const updateSW = registerSW({
  async onNeedRefresh() {
    // show a prompt to user
    let result = await confirm({
      title: 'Update to the latest version?',
      text: 'A new version of the app is available. Do you want to update now?'
    });
    updateSW();
  },
  onOfflineReady() { },
})