import { Modifier, type ModifierParams } from "../modifier";
import { actionHistory } from '../../../store';

export class Wound extends Modifier {
  init(params?: ModifierParams) {
    super.init(params);

    this.owner.healthMax -= this.stacks;
    if(this.owner.healthCurrent > this.owner.healthMax){
      this.owner.healthCurrent = this.owner.healthMax;
    }
  }

  update(stacks: number) {
    super.update(stacks);

    this.owner.healthMax -= stacks;
  }

  removeStacks(stacks: number): void {
    this.owner.healthMax += stacks;

    super.removeStacks(stacks);
  }

  remove() {
    if(this.stacks > 0){
      this.owner.healthMax += this.stacks;
    }
    super.remove();
  }
}