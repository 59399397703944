import type { Die } from "../die.type";
import { Modifier } from "../modifier";
import type { Player } from '../player.type';

export class TwinSpell extends Modifier {
  useTechnique(technique, target: Player, caster: Player, die: Array<Die>) {
    technique.activate(target, caster, die);

    this.removeStacks(1);
  }
}