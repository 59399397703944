import type { Player } from "../player.type";
import { addModifier, addMoney, changeReputation, healthRemove } from "../player.type";
import { screens } from '../../../store';
import { createDie } from "../die.type";
import type { MapNode, MapData } from "../map.type";
import type { Step, MapEvent, Option } from "../events.type";
import { createHasCondition, playerHasAttribute } from "../events.type";
import { createEdge, createNode } from "../../utils/map";
import { AStarPathBetweenNodes } from "../../utils/astar";
import { getIcon } from '../../icon/icons';
import { getEvent } from './eventsAll';
import { EventType, Events } from '../eventList';
import type { Battle } from "../battle.type";
import { getEnemyDefinition } from "../enemy.type";
import { StatusEffect } from "../status-effects";

// events to write
// belltower


function scoutNode(map: MapData, node: MapNode){
  if (!node.visited && !node.scouted) {
    node.scouted = true;
  }
}

function scoutAdjacentNodes(map: MapData, node: MapNode){
  map.edges.filter(edge => {
    return edge.from == node.id || edge.to == node.id;
  })
  .map(edge => {
    let id = edge.from == node.id ? edge.to : edge.from;
    return map.nodes.find(node => {
      return node.id == id;
    });
  })
  .forEach(node => {
    if (!node.visited && !node.scouted) {
      node.scouted = true;
    }
  });
}

function scoutAdjacentNodesTwoDeep(map: MapData, node: MapNode) {
  map.edges.filter(edge => {
    return edge.from == node.id || edge.to == node.id;
  })
  .map(edge => {
    let id = edge.from == node.id ? edge.to : edge.from;
    return map.nodes.find(node => {
      return node.id == id;
    });
  })
  .forEach(node => {
    if (!node.visited && !node.scouted) {
      node.scouted = true;
      scoutAdjacentNodes(map, node);
    }
  });
}


function scoutRandomNode(map: MapData) {
  var nodes = map.nodes.filter(node => {
    return !node.visited && !node.scouted;
  });

  if (nodes.length > 0) {
    var randomNode = nodes[Math.floor(Math.random() * nodes.length)];
    randomNode.scouted = true;

    return randomNode;
  }
}

const leave = {
  text: 'Leave',
  activate: (player, map, node) => {
    screens.pop();
  }
};

export const scoutevents: Array<MapEvent> = [
  {
    title: 'Sky Perch',
    type: 'scout',
    image: 'watchtower',
    text: 'A weathered watchtower stands tall, offering a commanding view from its aged structure and creaking stairs that spiral upward.',
    id: Events.SkyPerch,
    step: {
      text: 'Curious, you venture closer, eager to ascend to its pinnacle for a higher perspective and the chance to uncover hidden secrets.',
      options: [
        {
          text: 'Ascend the creaking stairs of the watchtower.',
          activate: (player, map, node) => {
            scoutAdjacentNodes(map, node);
          },
          next: {
            text: 'You ascend the creaking stairs of the watchtower, step by step, until you reach the top. From the tower\'s vantage point, you gain an expansive view of the surrounding wilderness, spotting various points of interest, hidden trails, and potential dangers.',
            options: [
              {
                text: 'Search the tower.',
                condition: createHasCondition('brain'),
                activate: (player, map, node) => {
                  var randomNode = scoutRandomNode(map);
                  if (randomNode) {
                    scoutAdjacentNodes(map, randomNode);
                  }
                },
                next: {
                  text: 'As you search the watchtower, your attention is drawn to a weathered old table cluttered with forgotten items, and among them you stumble upon a rolled-up parchment that reveals itself to be a map, detailing a far away location awaiting discovery.',
                  image: 'map1',
                  options: [leave]
                }
              }, {
                text: 'Search the tower.',
                condition: createHasCondition('eye'),
                activate: (player) => {
                  addMoney(player, 10);
                },
                next: {
                  text: 'You search the watchtower, thoroughly examining its nooks and crannies until your gaze falls upon a dusty table, and to your delight, you spot a glimmer of gold coins tucked away amidst the forgotten belongings.',
                  image: 'coins2',
                  options: [leave]
                }
              },
              leave
            ]
          }
        },
        leave
      ]
    }
  },{
    title: 'Aetherwood Apex',
    image: 'talltree',
    type: 'scout',
    text: 'A tall tree, perched atop a hill, boasts a staircase winding gracefully midway up its towering trunk, offering a glimpse of the world beyond.',
    id: Events.AetherwoodApex,
    step: {
      text: 'You gaze upon the tall tree standing on the hilltop, your eyes follow the staircase that winds its way halfway up its majestic height.',
      options: [
        {
          text: 'Ascend the staircase to explore what lies beyond.',
          activate: (player, map, node) => {
            scoutAdjacentNodes(map, node);
          },
          next: {
            text: 'As you climb the staircase, the world unfolds before you, revealing a breathtaking panorama of the surrounding landscape and distant landmarks, filling you with a sense of awe and wonder at the beauty that lies beyond the halfway point of the towering tree.',
            options: [
              { // No agility
                text: 'Continue your ascent, venturing even higher into the swaying branches.',
                condition: {
                  fulfils: (player: Player) => {
                    return !playerHasAttribute(player, 'agility') && !playerHasAttribute(player, 'fist');
                  }
                },
                activate: (player, map, node) => {
                  scoutAdjacentNodesTwoDeep(map, node);
                },
                next: {
                  text: 'With each upward step, the world below seems to shrink, and your view expands, granting you a mesmerizing vista of uncharted territories and hidden wonders.',
                  options: [
                    {
                      text: 'Climb Down.',
                      activate: (player, map, node) => {
                        healthRemove({ value: 3, target: player, source: player, dice: [] });
                      },
                      next: {
                        text: 'As you begin your descent, an unfortunate misstep causes your footing to falter, and you find yourself tumbling downward, each branch whizzing by in a blur until you finally land on the ground with a thud.',
                        image: 'injury1',
                        options: [leave]
                      }
                    }
                  ]
                }
              }, { // With agility
                text: 'Continue your ascent, venturing even higher into the swaying branches.',
                condition: createHasCondition('agility'),
                activate: (player, map, node) => {
                  scoutAdjacentNodesTwoDeep(map, node);
                },
                next: {
                  text: 'With each upward step, the world below seems to shrink, and your view expands, granting you a mesmerizing vista of uncharted territories and hidden wonders.',
                  options: [
                    {
                      text: 'Climb Down.',
                      activate: (player, map, node) => {},
                      next: {
                        text: 'As you descend with grace and dexterity, you reach the bottom of the tall tree unscathed, your heart filled with satisfaction at having experienced the world from such an elevated perspective.',
                        options: [leave]
                      }
                    }
                  ]
                }
              }, { // With agility
                text: 'Continue your ascent, venturing even higher into the swaying branches.',
                condition: createHasCondition('fist'),
                activate: (player, map, node) => {
                  scoutAdjacentNodesTwoDeep(map, node);
                },
                next: {
                  text: 'With each upward step, the world below seems to shrink, and your view expands, granting you a mesmerizing vista of uncharted territories and hidden wonders.',
                  options: [
                    {
                      text: 'Climb Down.',
                      activate: (player, map, node) => { },
                      next: {
                        text: 'As you descend with grace and dexterity, you reach the bottom of the tall tree unscathed, your heart filled with satisfaction at having experienced the world from such an elevated perspective.',
                        options: [leave]
                      }
                    }
                  ]
                }
              },
              leave
            ]
          }
        },
        leave
      ]
    }
  }, {
    title: 'Signpost',
    image: 'signpost',
    type: 'scout',
    text: 'A signpost stands tall, its weathered surface bearing the faded remnants of a map, its directions pointing to various locations.',
    id: Events.Signpost,
    step: {
      text: 'You approach the signpost, its weathered surface bearing the faded remnants of a map, its directions pointing to various locations.',
      options: [
        {
          text: 'Read the signpost.',
          activate: (player, map, node) => {
            scoutAdjacentNodes(map, node);
          },
          next: {
            text: 'You read the signpost and learn of the locations in the area.',
            options: [leave]
          }
        }, {
          // with brains, read the sign and create a new event location to add to the map
          text: 'Study the signpost.',
          condition: createHasCondition('brain'),
          activate: (player, map, node) => {
            const newNode = createNode(map.nodes.length+1);
            const newEdge = createEdge(node.id, newNode.id);
            newNode.type = 'event';
            newNode.icon = 'event';
            newNode.image = getIcon(newNode.icon);
            newNode.visit = () => {
              screens.push('event');
            };
            newNode.event = getEvent(EventType.Event);
            newNode.color = 'lightgreen';
            newNode.open = true;
            newNode.scouted = true;

            map.nodes.push(newNode);
            map.edges.push(newEdge);
          },
          next: {
            text: 'As you study the signpost, you notice a faded map, its directions pointing to various locations, and among them you spot a new location that you have yet to explore.',
            options: [leave]
          }
        },
        leave
      ]
    }
  }, {
    title: 'Raven Watch',
    image: 'raven-watch',
    type: 'scout',
    text: 'A raven flies overhead, its dark wings casting a shadow upon the ground, its piercing gaze fixated upon you.',
    id: Events.RavenWatch,
    step: {
      text: 'A raven flies overhead, its dark wings casting a shadow upon the ground, its piercing gaze fixated upon you.',
      options: [
        {
          text: 'Watch the raven.',
          activate: (player, map, node) => {
            // scout nodes in a line
            //use astar to find all nodes 3 jumps away from this one
            const scoutNode = map.nodes.find(mapNode => {
              return mapNode.scouted == false && AStarPathBetweenNodes(mapNode, node, map).length == 4;
            });
            const path = AStarPathBetweenNodes(scoutNode, node, map);
            path.forEach(node => {
              node.node.scouted = true;
            });
          },
          next: {
            text: 'As you watch the raven, you feel a connection to it. You suddenly see through its eyes, revealing new locations to explore.',
            options: [leave]
          }
        }, {
          text: 'Ignore the raven.',
          activate: ()=>{},
          next: {
            text: 'You ignore the raven, and it flies away, leaving you to your own devices.',
            options: [leave]
          }
        },
        leave
      ]
    }
  }, {
    title: 'Travelling Cartographer',
    image: 'cartographer',
    type: 'scout',
    text: 'A travelling cartographer is selling maps of the surrounding area.',
    id: Events.TravellingCartographer,
    step: {
      text: 'The traveler glances up as you approach, a friendly gleam in their eye. "Ah, another soul seeking direction in these expansive lands. Are you in search of guidance or tales of distant horizons?"',
      options: [
        {
          text: 'Purchase a Local Map',
          subtext: 'Scout adjacent nodes',
          condition: {
            text: '10 gold',
            fulfils: (player: Player) => {
              return player.money > 10;
            }
          },
          activate: (player, map, node) => {
            player.money -= 10;
            scoutAdjacentNodesTwoDeep(map, node);
          },
          next: {
            text: 'With a modest payment, you obtain a detailed map of the immediate area, illuminating hidden trails and landmarks nearby. The traveler nods appreciatively, sharing a few insights about the locale before parting ways.',
            subtext: 'Lose 10 Gold <br>Adjacent nodes gain Scouted',
            options: [leave]
          }
        }, {
          text: 'Purchase a Regional Map',
          subtext: 'Scout distant node cluster',
          condition: {
            text: '15 gold',
            fulfils: (player: Player) => {
              return player.money > 15;
            }
          },
          activate: (player, map, node) => {
            player.money -= 15;
            const scoutingNode = map.nodes.find(mapNode => {
              return mapNode.scouted == false && AStarPathBetweenNodes(mapNode, node, map).length >= 7;
            });
            if(scoutingNode){
              scoutNode(map, scoutingNode);
              scoutAdjacentNodes(map, scoutingNode);
            }
          },
          next: {
            text: 'After a considerable exchange of coins, the traveler presents you with a meticulously drawn map showcasing distant realms and uncharted territories. It unravels potential prospects for future exploration, broadening your horizons.',
            subtext: 'Lose 15 Gold <br>Distant node cluster gains Scouted',
            options: [leave]
          }
        }, {
          text: 'Attack the Traveller',
          subtext: 'Start Combat',
          activate: (player, map, node) => {
            let battle: Battle = { //Battle
              enemy: getEnemyDefinition('bandit-ranger'),
              environment: 'forest',
              level: 1,
              type: 'normal',
              outcome: 'active'
            };
            screens.push('battlestart', {battle});
            changeReputation(player, -1);

            return new Promise((resolve) => {
              battle.resolve = resolve;
            })
            .then(outcome => {
              if (outcome == 'win') {
                return {
                  next: {
                    text: `Opting for aggression, you engage in a skirmish with the traveler. Emerging victorious, albeit tarnished by the conflict, you claim the distant map among the traveler's possessions. However, your aggressive act negatively impacts your reputation in the area.`,
                    subtext: `Lose 1 Reputation`,
                    options: [{
                      text: 'Take the Map and Leave',
                      activate: (player: Player, map: MapData, node: MapNode) => {
                        const scoutNode = map.nodes.find(mapNode => {
                          return mapNode.scouted == false && AStarPathBetweenNodes(mapNode, node, map).length >= 7;
                        });
                        if (scoutNode) {
                          scoutAdjacentNodesTwoDeep(map, scoutNode);
                        }
                      },
                      next: {
                        text: 'You take the map and leave.',
                        subtext: 'Scout distant node cluster',
                        options: [leave]
                      }
                    }]
                  }
                };
              }
              else {
                return {
                  next: {
                    text: `Seeking to forcibly obtain the map, a conflict ensues between you and the traveler. Defeated in the altercation, you sustain a wound from the scuffle. Despite your efforts, the map remains in the possession of the traveler, and your reputation takes a hit.`,
                    subtext: `Lose 1 Reputation`,
                    options: [{
                      text: 'Leave',
                      activate: (player: Player, map: MapData, node: MapNode) => {
                        addModifier(player, StatusEffect.Wound, 1);
                        screens.pop();
                      },
                    }]
                  }
                };
              }
            });
          },
        }, leave
      ]
    }
  }
];

/* let eventsArray = [];
export const getEvent = () => {
  if (!eventsArray.length) {
    eventsArray = [...events];
    //random sort events Array
    eventsArray.sort(() => Math.random() - 0.5);
  }

  return eventsArray.pop();
}; */