import type { MapNode } from "../models/map.type";

type aStarNode = {
  node: MapNode,
  // id: number,
  parent?: aStarNode,
  g?: number,
  h?: number,
  f?: number
}

export function AStarPathBetweenNodes(node1, node2, map){
  let openList: Array<aStarNode> = [];
  let closedList: Array<aStarNode> = [];
  let path: Array<aStarNode> = [];

  let currentNode: aStarNode = {node: node1};
  let endNode: aStarNode = {node: node2};

  openList.push(currentNode);

  while(openList.length > 0){
    // get node with lowest f cost
    currentNode = openList[0];
    for(let i = 0; i < openList.length; i++){
      if(openList[i].f < currentNode.f){
        currentNode = openList[i];
      }
    }

    // remove current node from open list
    openList = openList.filter(node => {
      return node.node.id != currentNode.node.id;
    });

    // add current node to closed list
    closedList.push(currentNode);

    // check if current node is the end node
    if(currentNode.node.id == endNode.node.id){
      endNode.parent = currentNode.parent;
      // console.log('found path');
      break;
    }

    // get edges
    let nodeEdges = map.edges.filter(edge => {
      return edge.from == currentNode.node.id || edge.to == currentNode.node.id;
    });

    // loop through edges
    nodeEdges.forEach(edge => {
      let nextNodeId = edge.to == currentNode.node.id ? edge.from : edge.to;
      let nextNode: aStarNode = {node: map.nodes.find(node => {
        return node.id == nextNodeId;
      })};

      // check if node is in closed list
      if(closedList.find(node => {
        return node.node.id == nextNode.node.id;
      })){
        return;
      }

      // check if node is in open list
      if(openList.find(node => {
        return node.node.id == nextNode.node.id;
      })){
        return;
      }

      // add node to open list
      openList.push(nextNode);

      // set parent node
      nextNode.parent = currentNode;

      // calculate g cost
      nextNode.g = (currentNode.g || 0) + 1;

      // calculate h cost
      nextNode.h = 0.5;//Math.abs(nextNode.x - endNode.x) + Math.abs(nextNode.y - endNode.y);

      // calculate f cost
      nextNode.f = nextNode.g + nextNode.h;
    });
  }

  // create path
  currentNode = endNode;
  while(currentNode.parent){
    path.push(currentNode);
    currentNode = currentNode.parent;
  }
  path.push(currentNode);

  return path;
}