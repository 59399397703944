import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';

export class Block extends Modifier {
  damageIn(damage: Damage) {
    let difference = this.stacks - damage.value;
    this.removeStacks(Math.min(this.stacks, damage.value));

    if (difference >= 0) {
      damage.value = 0;
    }
    else {
      damage.value = Math.abs(difference);
    }
    // damage.value = Math.min(1, Math.floor(damage.value/2));
  }

  modifyNotches(notches: Array<string>) {
    for (let i = 0; i < this.stacks; i++) {
      notches.push('block');
    }
  }

  startTurn() {
    this.removeStacks(this.stacks);
  }
}