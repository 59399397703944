<script lang='ts'>
  import Icon from "../icon/icon.svelte";
  import { intervals } from "../../store";

  type Particle = {
    type: 'text|icon';
    animation: string;
    value: string;
    spawnTarget: string;

    colour?: string;

    class?: string;
    x?: string;
    y?: string;
    lifespan: number;
  }

  let particles = [];
  let queue = [];

  export function queueParticle(particle: Particle){
    queue.push(particle);
  }

  const loop = () => {
    if(queue.length){
      createParticle(queue.shift());
    }

    if(particles.length){
      if(particles[0].lifespan < performance.now()){
        // particles.shift();
        // particles = particles;
      }
    }
  }

  $: {
    if(intervals.particleInterval){
      clearInterval(intervals.particleInterval);
    }
    intervals.particleInterval = setInterval(loop, 200);
  }

  function createParticle(particle){
    particles.push(particle);

    var target: HTMLElement = document.querySelector(`.full-screen.visible ${particle.spawnTarget}`);
    if(!target){
      return;
    }

    particle.x = target.offsetLeft + target.offsetWidth/2;
    particle.y = target.offsetTop + target.offsetHeight/2;
    particle.x += (Math.random()-0.5) * (target.offsetWidth/3);
    particle.y += (Math.random()-0.5) * (target.offsetHeight/3);
    particle.y -= 21;
    particle.x += 'px';
    particle.y += 'px';

    particle.lifespan = performance.now() + 2000;

    setTimeout(() => {
      particle.class = particle.animation;
      particles = particles;
    }, 10);

    particles = particles;
  }

  window.addEventListener('createParticle', function(event: CustomEvent){
    queueParticle(event.detail);
  });

</script>

<div class='particles'>
  {#each particles as particle}
    <div
      class='particle {particle.class} {particle.colour}'
      style='left: {particle.x}; top: {particle.y}'
    >
      {#if particle.type == 'text'}
        {particle.value}
      {/if}

      {#if particle.type == 'icon'}
        <Icon name={particle.value} width='40px' height='40px'/>
      {/if}
    </div>
  {/each}
</div>

<style lang='less'>
  .particles{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    width: 100vw;
    height: 100vh;
    pointer-events: none;

    .particle{
      opacity: 1;
      position: absolute;

      transition: opacity 1.5s ease-out, transform 1.5s ease-out;

      font-size: 36px;
      font-family: sans-serif;
      text-shadow: -1px -1px 0 #000, 1px -1px 0 #000, -1px 1px 0 #000, 1px 1px 0 #000;

      &.rise{
        transform: translateY(50px);
        opacity: 0;
      }
      &.fall{
        transform: translateY(-50px);
        opacity: 0;
      }
      &.grow{
        transform: scale(2);
        opacity: 0;
      }

      &.red{
        color: red;
      }
      &.green{
        color: green;
      }
    }
  }
</style>