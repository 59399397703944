<script lang="ts">
  import Portrait from "../../components/portrait.svelte";
  import { createDie } from "../../models/die.type";
  import { glossary } from "../../../store";
  import Die from '../../dice-tray/die.svelte';
  import type { EnvironmentDefinition } from "../../models/environment.type";

  export var data: EnvironmentDefinition;

  /* const openTechniques = () => {
    glossary.selected.set('environments');
    glossary.searchTerm.set('');
  } */
</script>

<div class='container'>
  <div style='--background-colour:{data.colour}'>
    <Portrait data={{type:data.id}}></Portrait>
  </div>

  <div class='information'>
    <h2>{data.title}</h2>
    <h3>{data.text}</h3>
  </div>
</div>

<style lang='less'>
  .container{
    border: 2px solid var(--background-colour-light);

    display: flex;
    flex-direction: row;

    .information{
      padding: 5px;
      text-align: left;
    }
  }

  h2{
    text-transform: capitalize;
  }
</style>