import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';
import { healthRemove } from '../player.type';
import { actionHistory } from '../../../store';

export class Accuracy extends Modifier {
  damageOut(damage: Damage) {
    damage.value *= 2;
    this.removeStacks(1);
  }
}