import { screens } from '../../store';
import { getIcon } from '../icon/icons';
import type { Events } from '../models/eventList';
import { getEventByID } from '../models/events/eventsAll';
import type { Items } from '../models/item.type';
import type { MapData } from '../models/map.type';
import { addItem, addModifier, addTrait, createPlayerInstance, type Player } from '../models/player.type';
import { memory } from './memory';

export function loadGame(){
  const map = memory.get('map');
  const nodes = map.nodes.map(node => {
    let newNode = {
      id: node.id,
      type: node.type,
      icon: node.icon,

      visited: node.visited,
      scouted: node.scouted,
      open: node.open,

      battle: node.battle,
      event: getEventByID(node.event as Events),

      visit: () => {
        if (newNode.type == 'battle' || newNode.type == 'boss'){
          screens.push('battlestart', { battle: newNode.battle });
          return new Promise((resolve) => {
            newNode.battle.resolve = resolve;
          });
        }
        else if(newNode.type == 'event'){
          screens.push('event', { event: newNode.event, map, node: newNode });
        }
      },

      image: getIcon(node.icon),
      shape: node.visited ? 'circle' : 'image',
      color: node.visited ? 'white' : node.open ? 'lightgreen' : 'red',
      shadow: true,
      size: 40,
      group: node.group,
    }
    return newNode;
  });
  let centerX = document.body.offsetWidth / 2;
  let centerY = document.body.offsetHeight / 2;
  nodes[0].x = centerX;
  nodes[0].y = centerY;

  const loadedMap: MapData = {
    nodes: nodes,
    edges: map.edges,

    innerNodes: map.innerNodes.map(node => {
      return nodes.find(n => n.id == node);
    }),
    middleNodes: map.middleNodes.map(node => {
      return nodes.find(n => n.id == node);
    }),
    outerNodes: map.outerNodes.map(node => {
      return nodes.find(n => n.id == node);
    }),
    edgeNodes: map.edgeNodes.map(node => {
      return nodes.find(n => n.id == node);
    }),
  }

  const playerSave = memory.get('player');
  const player = createPlayerInstance(playerSave.type, playerSave.archetype);
  player.title = playerSave.title;
  player.healthMax = playerSave.healthMax;
  player.rerollMax = playerSave.rerollMax;
  player.dice = playerSave.dice;
  player.colour = playerSave.colour;
  const root: HTMLElement = document.querySelector(':root');
  root.style.setProperty('--player-colour', playerSave.colour);

  playerSave.statusEffects.forEach((modifier) => {
    addModifier(player, modifier.type, modifier.stacks, {...modifier.properties, duration: modifier.duration});
  });
  playerSave.traits.forEach((trait: string) => {
    addTrait(player, trait);
  });
  player.healthCurrent = playerSave.healthCurrent;

  player.level = playerSave.level;
  player.experience = playerSave.experience;
  player.money = playerSave.money;
  player.reputation = playerSave.reputation;
  playerSave.inventory.forEach((item: Items) => {
    addItem(player, item);
  });

  // console.log('loading', map, playerSave)
  // console.log('loading', loadedMap, player)

  return { map: loadedMap, player };
}

export function saveGame(map: MapData, player: Player){
  if (!map.innerNodes){
    return;
  }
  let mapData = {
    nodes: map.nodes.map(node => {
      return {
        id: node.id,
        group: node.group,
        type: node.type,
        icon: node.icon,

        visited: node.visited,
        scouted: node.scouted,
        open: node.open,

        battle: node.battle,
        event: node.event?.id,
      }
    }),
    edges: map.edges,

    innerNodes: map.innerNodes.map(node => node.id),
    middleNodes: map.middleNodes.map(node => node.id),
    outerNodes: map.outerNodes.map(node => node.id),
    edgeNodes: map.edgeNodes.map(node => node.id),
  };
  memory.persist('map', mapData);

  let playerSave = {
    title: player.title,
    type: player.type,
    archetype: player.archetype,
    healthMax: player.healthMax,
    rerollMax: player.rerollMax,
    dice: player.dice,
    colour: player.colour,

    statusEffects: player.statusEffects.map(modifier => {
      return {
        type: modifier.type,
        stacks: modifier.stacks,
        duration: modifier.duration,
        properties: modifier.properties
      }
    }),
    traits: player.traits.map(trait => trait.type),
    healthCurrent: player.healthCurrent,

    level: player.level,
    experience: player.experience,
    money: player.money,
    reputation: player.reputation,
    inventory: player.inventory.map(item => item.type),
  }
  memory.persist('player', playerSave);

  // console.log('saving', mapData, playerSave)
}

export function deleteGame(){
  memory.persist('map', '');
  memory.persist('player', '');
}

export function isSavedGame(){
  if(memory.get('map') == null || memory.get('map') == '') return false;
  return true;
}