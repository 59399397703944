<script lang='ts'>
  import DieIcon from './die-icon.svelte';

  export let level: number = 0;

  let randomDelay = Math.random()*2;
</script>

<!-- <div class='shadow {mouseover == "true" ? "hovering":""}'></div> -->

<div class='face-inner die-level-{level}' style="animation-delay:{-randomDelay}s">
  <div class='face-special' style="animation-delay:{-randomDelay+2}s"></div>

  <div class='edge top'>
    <div class='tick tick-1'></div>
    <div class='tick tick-2'></div>
  </div>
  <div class='edge bottom'>
    <div class='tick tick-1'></div>
    <div class='tick tick-2'></div>
  </div>
  <div class='edge left'>
    <div class='tick tick-1'></div>
    <div class='tick tick-2'></div>
  </div>
  <div class='edge right'>
    <div class='tick tick-1'></div>
    <div class='tick tick-2'></div>
  </div>
</div>

<style lang='less'>
  @import "../../defines.less";
  .die-level--2{
    .tick{
      border-color: black;
    }
  }
  .die-level--1{
    .tick{
      border-color: black;
    }
    .tick-2{
      display: none;
    }
  }
  .die-level-0{
    .edge{
      display: none;
    }
  }
  .die-level-1{
    .tick-2{
      display: none;
    }
  }
  /* .die-level-2{
  } */

  .edge{
    position: absolute;
    display: flex;
    align-items: center;
    justify-content: center;
    grid-gap: 5px;

    &.top{
      top: 0;
      height: 5px;
      width: 100%;

      .tick{
        left: calc(50% - 5px);
        right: calc(50% - 5px);

        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-top: 5px solid #fff;
      }
    }
    &.bottom{
      bottom: 0;
      height: 5px;
      width: 100%;

      .tick{
        left: calc(50% - 5px);
        right: calc(50% - 5px);

        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid #fff;
      }
    }
    &.left{
      left: 0;
      width: 5px;
      height: 100%;
      flex-direction: column;

      .tick{
        top: calc(50% - 5px);
        bottom: calc(50% - 5px);

        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        border-left: 5px solid #fff;
      }
    }
    &.right{
      right: 0;
      width: 5px;
      height: 100%;
      flex-direction: column;

      .tick{
        top: calc(50% - 5px);
        bottom: calc(50% - 5px);

        border-bottom: 5px solid transparent;
        border-top: 5px solid transparent;
        border-right: 5px solid #fff;
      }
    }
  }

  .die-level--2,
  .die-level--1{
    .edge{
      &.top{
        .tick{
          border-top: 5px solid black;
        }
      }
      &.bottom{
        .tick{
          border-bottom: 5px solid black;
        }
      }
      &.left{
        .tick{
          border-left: 5px solid black;
        }
      }
      &.right{
        .tick{
          border-right: 5px solid black;
        }
      }
    }
  }

</style>