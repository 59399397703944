import type { MapEvent } from '../events.type';
import { addModifier, dealDamage, addItem, type Player, changeReputation } from "../player.type";
import { screens, stateNode } from '../../../store';
import type { MapData, MapNode } from "../map.type";
import type { Battle } from '../battle.type';
import { getEnemyDefinition } from '../enemy.type';
import { Items } from '../item.type';
import { Events } from '../eventList';

// events to write
// blacksmith

const leave = {
  text: 'Leave',
  activate: (player, map, node) => {
    screens.pop();
  }
};

export const vendors: Array<MapEvent> = [
  {
    title: 'Wandering Salesman',
    text: 'A wandering salesman is selling his wares.',
    type: 'event',
    image: 'wagon',
    id: Events.SalesmanPotion,
    step: {
      text: 'As you are wandering through the forest, you come across a potion seller.',
      image: 'salesman-potion',
      options: [
        {
          text: 'Buy a Potion of Healing.',
          condition: {
            text: '10 gold',
            fulfils: (player: Player) => {
              return player.money > 10;
            }
          },
          activate: (player: Player, map: MapData, node: MapNode) => {
            player.money -= 10;
            addItem(player, Items.PotionHealth);
          }
        }, {
          text: 'Buy a Mana Potion.',
          condition: {
            text: '10 gold',
            fulfils: (player: Player) => {
              return player.money > 15;
            }
          },
          activate: (player: Player, map: MapData, node: MapNode) => {
            player.money -= 15;
            addItem(player, Items.PotionMana);
          }
        }, {
          text: 'Buy a Clarity Potion.',
          condition: {
            text: '15 gold',
            fulfils: (player: Player) => {
              return player.money > 15;
            }
          },
          activate: (player: Player, map: MapData, node: MapNode) => {
            player.money -= 15;
            addItem(player, Items.PotionClarity);
          }
        }, {
          text: 'Attack the salesman.',
          activate: (player: Player, map: MapData, node: MapNode) => {
            let battle: Battle = { //Battle
              enemy: getEnemyDefinition('salesman-potion'),
              environment: 'forest',
              level: 1,
              type: 'normal',
              outcome: 'active'
            };
            screens.push('battlestart', battle);
            changeReputation(player, -1);

            return new Promise((resolve) => {
              battle.resolve = resolve;
            })
            .then(outcome => {
              if (outcome == 'win') {
                return {
                  next: {
                    text: `You defeating the potion seller and get access to their goods, but this act tarnishes your reputation and makes it harder to gain trust.`,
                    subtext: `Gain 1 potion of healing. Lose 1 Reputation`,
                    options: [{
                      text: 'Take Potion and Leave',
                      activate: (player: Player, map: MapData, node: MapNode) => {
                        addItem(player, Items.PotionHealth);
                      },
                      next: {
                        text: 'You take the potion and leave.',
                        options: [leave]
                      }
                    }]
                  }
                };
              }
              else {
                return {
                  next: {
                    text: `Losing to the potion seller humiliates you, spreading word of your failed aggression and diminishing your reputation, hindering your efforts to earn the townspeople's trust.`,
                    options: [leave]
                  }
                };
              }
            });
          }
        },
        leave
      ]
    }
  }, {
    title: 'Wandering Salesman',
    text: 'A wandering salesman is selling his wares.',
    type: 'event',
    image: 'wagon',
    id: Events.SalesmanBomb,
    step: {
      text: 'As you are wandering through the forest, you come across a bomb seller.',
      image: 'salesman-bomb',
      options: [
        {
          text: 'Buy a Smoke Bomb.',
          condition: {
            text: '10 gold',
            fulfils: (player: Player) => {
              return player.money > 10;
            }
          },
          activate: (player: Player, map: MapData, node: MapNode) => {
            player.money -= 10;
            addItem(player, Items.BombSmoke);
          }
        }, {
          text: 'Buy a Flash Bomb.',
          condition: {
            text: '10 gold',
            fulfils: (player: Player) => {
              return player.money > 10;
            }
          },
          activate: (player: Player, map: MapData, node: MapNode) => {
            player.money -= 10;
            addItem(player, Items.BombFlash);
          }
        }, {
          text: 'Buy a Fire Bomb.',
          condition: {
            text: '15 gold',
            fulfils: (player: Player) => {
              return player.money > 15;
            }
          },
          activate: (player: Player, map: MapData, node: MapNode) => {
            player.money -= 15;
            addItem(player, Items.BombFire);
          }
        }, {
          text: 'Buy a Sticky Bomb.',
          condition: {
            text: '15 gold',
            fulfils: (player: Player) => {
              return player.money > 15;
            }
          },
          activate: (player: Player, map: MapData, node: MapNode) => {
            player.money -= 15;
            addItem(player, Items.BombSticky);
          }
        },
        leave
      ]
    }
  }
];