<script lang="ts">
  import Portrait from "../../components/portrait.svelte";
  import { createDie } from "../../models/die.type";
  import { glossary } from "../../../store";
  import Die from '../../dice-tray/die.svelte';
  import type { Player } from '../../models/player.type';

  export var data: Player;

  let dice = [];
  if(data && data.dice){
    dice = data.dice.map((die: string, index: number) => {
      return createDie(die, index, 'p'+index);
    });
  }

  const openTechniques = () => {
    glossary.selected.set('techniques');
    glossary.searchTerm.set('');
    glossary.searchDie.set(dice);
  }
</script>

<div class='container'>
  <div> <!-- style='--background-colour:{data.colour}' -->
    <Portrait data={data}></Portrait>
  </div>

  <div class='information'>
    <h2>{data.title}</h2>
    <h3>{data.text}</h3>

    <div class='die-list'>
      {#each dice as die, index (die.id)}
        <div
          class='die-container'
        >
          <Die die={die} index={index} die3D={false} hover={true} sizeMod={1}/>
        </div>
      {/each}

      <!-- button to show techniques panel -->
      <input type='button' class='btn' value='Techniques' on:click={openTechniques}/>
    </div>
  </div>
</div>

<style lang='less'>
  .container{
    border: 2px solid var(--background-colour-light);

    display: flex;
    flex-direction: row;

    .information{
      padding: 5px;
      text-align: left;

      .die-list{
        text-align: center;
        display: flex;
        grid-gap: 10px;

        .die-container{
          position: relative;
        }
      }
    }
  }

  h2{
    text-transform: capitalize;
  }
</style>