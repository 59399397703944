<script lang='ts'>
  import Text from '../../components/text.svelte';
  import Icon from '../../icon/icon.svelte';
  import { code } from '../../modals/modals';

  export let data;

  /* function viewCode(){
    code({
      title: 'Code',
      text: '    '+data.activate
    });
  } */
</script>

<div class='container'>

  <div class='information'>
    {#if data.bonusIcon}
      <Icon name={data.bonusIcon} width='30px' height='30px' class='icon bonus'/>
    {/if}
    <Icon name={data.icon}/>

    <div>
      <h2>{data.title}</h2>
      <Text data={data.text}/>
    </div>

    <!-- <button on:click={viewCode}>View Code</button> -->
  </div>
</div>

<style lang='less'>
  @import "../../../defines.less";

  .container{
    border: 2px solid var(--background-colour-light);
    padding: 5px;

    --icon-size: 75px;
    @media @medium{
      --icon-size: 50px;
    }

    .information{
      display: grid;
      grid-template-columns: 75px 1fr 100px;
      grid-gap: 10px;
      align-items: center;
      padding: 5px;
      text-align: left;
      position: relative;

      @media (max-width: 550px){
        grid-template-columns: 50px 1fr;
      }

      :global(.icon.bonus){
        position: absolute;
        z-index: 3;
        top: -5px;
        left: -5px;
        background-color: black;
        width: 40px;
        height: 40px;
        border-radius: 40px;
        // border: 2px solid black;
        padding: 2px;
      }
    }

    /* button{
      display: none;
    } */
  }

  h2{
    text-transform: capitalize;
  }
</style>