<script lang='ts'>
</script>

<div class='hover-area'>
  <div class='shadow'></div>

  <div class='hover-container'>
    <div class='hover'>
      <slot />
    </div>
  </div>
</div>

<style lang='less'>
  .hover-area{
    position: absolute;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    z-index: 111;

    .hover-container{
      position: fixed;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      opacity: 0;
      pointer-events: none;
      transition: opacity 0.3s;
      z-index: 111;

      .hover{
        background-color: var(--background-colour);
        outline: 1px solid black;
        border-radius: 4px;
        line-height: 1;
        padding: 20px;

        // h2{
        //   margin: 10px;
        // }
      }
    }

    .shadow{
      position: fixed;
      top: 0;
      bottom: 0;
      left: 0;
      right: 0;
      pointer-events: none;
      background: rgba(0,0,0,0.3);
      opacity: 0;
      z-index: 11;
      transition: opacity 0.3s;
    }

    &:hover{
      .hover-container{
        opacity: 1;
      }
      .shadow{
        opacity: 1;
      }
    }
  }
</style>