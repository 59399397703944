import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import type { Player } from '../player.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';


export const axe: Technique[] = [
  {
    ingredients: [DieFace.Axe],
    title: 'Hew',
    text: 'Apply 1 Bleed',
    tags: ['debuff', 'damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(target, StatusEffect.Bleed, 1);
    }
  }, {
    ingredients: [DieFace.Axe, DieFace.Axe],
    title: 'Cleave',
    text: 'Deal 1 Damage, Apply 2 Bleed',
    tags: ['debuff', 'damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Bleed, 2);
    }
  }, {
    ingredients: [DieFace.Axe, DieFace.Blood],
    title: 'Blood Let',
    text: 'Deal 1 Damage, Apply 1 Wound',
    tags: ['debuff', 'damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Wound, 1);
    }
  }, {
    ingredients: [DieFace.Axe, DieFace.Eye],
    title: 'Open Wound',
    text: 'Deal 2 Damage, Increase damage by the value of target\'s Wound',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const wound = target.statusEffects.find(modifier => {
        return modifier.type == 'wound';
      });
      if (wound){
        dealDamage({ value: 2 + wound.stacks, target, source, dice });
      }
    }
  }, {
    ingredients: [DieFace.Axe, DieFace.Shield],
    title: 'Smash Shield',
    text: 'Deal 3 Damage to enemy\'s block, then Deal 2 damage.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const block = target.statusEffects.find(modifier => {
        return modifier.type == 'block';
      });
      if (block) {
        block.removeStacks(3);
      }

      dealDamage({ value: 2, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Axe, DieFace.Axe, DieFace.Axe],
    title: 'Split',
    text: 'Deal 3 Damage, Apply 3 Bleed',
    tags: ['debuff', 'damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
      addModifier(target, StatusEffect.Bleed, 3);
    }
  }, {
    requirements: [{
      text: 'Requires 5 Wounds',
      icon: 'blood',
      test: (source: Player, target: Player) => {
        const wound = target.statusEffects.find(modifier => {
          return modifier.type == 'wound';
        });
        return wound && wound.stacks >= 5;
      }
    }],
    ingredients: [DieFace.Axe, DieFace.Fist],
    title: 'Execute',
    text: 'Instantly kills the target',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      healthRemove({ value: 999, target, source, dice });
    }
  }
];
