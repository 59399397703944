
<script lang='ts'>
  import { createEventDispatcher, onMount } from 'svelte';
  import { codeData } from './modals';
  const dispatch = createEventDispatcher();

  let onConfirm = null;

  codeData.subscribe(value => {
    if(value && value.onConfirm){
      onConfirm = value.onConfirm;
    }
    else{
      onConfirm = null;
    }
  });

  const confirm = () => {
    if(onConfirm){
      onConfirm();
    }
    codeData.set(null);
  }
</script>

{#if $codeData}
<div class='modal-container'>
  <div class='modal'>
    {#if $codeData.title}
      <div class='header'>
        <h1>{$codeData.title}</h1>
      </div>
    {/if}

    {#if $codeData.text}
      <div class='body'>
        <pre>{$codeData.text}</pre>
      </div>
    {/if}

    <div class='functions'>
      <button class='btn' on:click={confirm}>Confirm</button>
    </div>
  </div>
</div>
{/if}

<style lang='less'>
  .modal-container{
    .modal{
      .body{
        text-align: left;
      }
    }
  }
</style>