import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';

export class Strength extends Modifier {
  update(stacks: number, params?) {
    super.update(stacks, params);
    this.delay = true;
  }

  startTurn() {
    if (this.delay) {
      const focus = this.owner.statusEffects.find(modifier => modifier.type === 'focus');
      if (!focus) {
        this.delay = false;
      }
    }
  }

  endTurn() {
    if (!this.delay) {
      this.removeStacks(1);
    }
  }

  damageOut(damage: Damage) {
    damage.value += this.stacks * Math.max(damage.dice.length, 1);
  }
}