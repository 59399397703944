var tags = [
  'balanced',
  'charge',
  'heavy',
  'fast',
  'piercing',
  'precision',
  'baning',
  'magic',

  'dodge',
  'stealth',
  'healing',
  'poison',
  'bleeding',
  'burn',
  'frost',
  'defensive',
  'stun',
  ]

export enum DieFace {
  Sword = 'sword',
  Axe = 'axe',
  Hammer = 'hammer',
  Spear = 'spear',
  Fist = 'fist',
  Bone = 'bone',
  Shield = 'shield',
  Wildcard = 'wildcard',
  Eye = 'eye',
  Dagger = 'dagger',
  Cloak = 'cloak',
  Agility = 'agility',
  Reflex = 'reflex',
  Poison = 'poison',
  Blood = 'blood',
  Hoof = 'hoof',
  Horn = 'horn',
  Mana = 'mana',
  Fire = 'fire',
  Earth = 'earth',
  Brain = 'brain',
  Ice = 'ice',
  Lightning = 'lightning',
  Bite = 'bite',
  Claws = 'claws',
  Wings = 'wings',
  Stinger = 'stinger',
  Web = 'web',
  Bow = 'bow',
  Shiv = 'shiv',
  Dart = 'dart',
  Ammo = 'ammo',
  Blank = '',
  Broken = 'broken',
  Pincer = 'pincer',
  Shell = 'shell',
  Beak = 'beak',
  Crossbow = 'crossbow',
  Note = 'note',
  Blowgun = 'blowgun',
  Prehensile = 'prehensile',

  OR = '/',
}

export type FaceDefinition = {
  id: DieFace;
  text: string;
  icon: string;
  tags: Array<string>;
}

export const faces: Array<FaceDefinition> = [
  {
    id: DieFace.Sword,
    text: '',
    icon: 'sword',
    tags: ['balanced', 'offensive', 'defensive'],
  },{
    id: DieFace.Axe,
    text: '',
    icon: 'axe',
    tags: ['charge', 'bleed', 'offensive'],
  },{
    id: DieFace.Hammer,
    text: '',
    icon: 'hammer',
    tags: ['heavy', 'stun', 'offensive'],
  },{
    id: DieFace.Spear,
    text: '',
    icon: 'spear',
    tags: ['precision', 'offensive'],
  },{
    id: DieFace.Fist,
    text: '',
    icon: 'fist',
    tags: ['balanced', 'offensive'],
  },{
    id: DieFace.Bone,
    text: '',
    icon: 'bone',
    tags: ['balanced','defensive'],
  },{
    id: DieFace.Shield,
    text: '',
    icon: 'shield',
    tags: ['defensive'],
  },{
    id: DieFace.Wildcard,
    text: '',
    icon: 'wildcard',
    tags: ['simple'],
  },{
    id: DieFace.Eye,
    text: '',
    icon: 'eye',
    tags: ['precision', 'offensive', 'defensive'],
  },{
    id: DieFace.Dagger,
    text: '',
    icon: 'dagger',
    tags: ['quick', 'piercing', 'sneak', 'multiattack'],
  },{
    id: DieFace.Cloak,
    text: '',
    icon: 'cloak',
    tags: ['stealth', 'defensive'],
  },{
    id: DieFace.Agility,
    text: '',
    icon: 'agility',
    tags: ['quick', 'defensive', 'haste'],
  },{
    id: DieFace.Reflex,
    text: '',
    icon: 'reflex',
    tags: ['quick', 'defensive', 'dodge'],
  },{
    id: DieFace.Poison,
    text: '',
    icon: 'poison',
    tags: ['poison'],
  },{
    id: DieFace.Blood,
    text: '',
    icon: 'blood',
    tags: ['bleed'],
  },{
    id: DieFace.Hoof,
    text: '',
    icon: 'hoof',
    tags: ['quick', 'charge', 'offensive'],
  },{
    id: DieFace.Horn,
    text: '',
    icon: 'horn',
    tags: ['charge', 'bleed', 'offensive'],
  },{
    id: DieFace.Mana,
    text: '',
    icon: 'mana',
    tags: ['balanced', 'offensive', 'defensive'],
  },{
    id: DieFace.Fire,
    text: '',
    icon: 'fire',
    tags: ['magic', 'fire', 'heavy'],
  },{
    id: DieFace.Earth,
    text: '',
    icon: 'earth',
    tags: ['magic', 'earth', 'defensive'],
  },{
    id: DieFace.Brain,
    text: '',
    icon: 'brain',
    tags: ['balanced'],
  },{
    id: DieFace.Ice,
    text: '',
    icon: 'ice',
    tags: ['magic', 'freeze', 'defensive'],
  },{
    id: DieFace.Lightning,
    text: '',
    icon: 'lightning',
    tags: ['magic', 'shock', 'quick'],
  },{
    id: DieFace.Bite,
    text: '',
    icon: 'bite',
    tags: ['balanced', 'offensive'],
  },{
    id: DieFace.Claws,
    text: '',
    icon: 'claws',
    tags: ['heavy', 'offensive'],
  },{
    id: DieFace.Wings,
    text: '',
    icon: 'wings',
    tags: ['charge'],
  },{
    id: DieFace.Stinger,
    text: '',
    icon: 'stinger',
    tags: ['poison', 'baning'],
  },{
    id: DieFace.Web,
    text: '',
    icon: 'web',
    tags: ['snare', 'baning'],
  },{
    id: DieFace.Bow,
    text: '',
    icon: 'bow',
    tags: ['quick', 'precision'],
  },{
    id: DieFace.Shiv,
    text: '',
    icon: 'shiv',
    tags: ['quick', 'offensive', 'simple'],
  },{
    id: DieFace.Dart,
    text: '',
    icon: 'dart',
    tags: ['quick', 'offensive', 'simple'],
  },{
    id: DieFace.Ammo,
    text: '',
    icon: 'ammo',
    tags: ['balanced'],
  },{
    id: DieFace.Blank,
    text: '',
    icon: '',
    tags: ['simple'],
  },{
    id: DieFace.Broken,
    text: '',
    icon: 'broken',
    tags: ['simple'],
  },{
    id: DieFace.Pincer,
    text: '',
    icon: '',
    tags: ['simple'],
  },{
    id: DieFace.Shell,
    text: '',
    icon: 'shell',
    tags: ['defensive'],
  }, {
    id: DieFace.Beak,
    text: '',
    icon: 'beak',
    tags: ['offensive'],
  }, {
    id: DieFace.Crossbow,
    text: '',
    icon: 'crossbow',
    tags: ['offensive'],
  }, {
    id: DieFace.Note,
    text: '',
    icon: 'note',
    tags: ['song'],
  }, {
    id: DieFace.Blowgun,
    text: '',
    icon: 'blowgun',
    tags: ['offensive'],
  }
];