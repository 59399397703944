import { dealDamage, addModifier } from '../player.type';
import type { Technique } from "../techniques.type";
import type { Player } from '../player.type';
import type { Die } from '../die.type';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';

export const sword: Technique[] = [
  {
    ingredients: [DieFace.Sword],
    title: 'Slash',
    text: 'Deal 1 Damage.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Sword, DieFace.Sword],
    title: 'Heavy Slash',
    text: 'Deal 3 Damage.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Sword, DieFace.Fist],
    title: 'Pommel Strike',
    text: 'Apply 1 Stun.',
    tags: ['debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(target, StatusEffect.Stunned, 1);
    }
  }, {
    ingredients: [DieFace.Sword, DieFace.Brain],
    title: 'Tactical Strike',
    text: 'Deal 2 Damage, Infuse Sword.',
    tags: ['damage', 'buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(source, StatusEffect.Infusion, 1, { infusion: DieFace.Sword });
    }
  }, {
    ingredients: [DieFace.Sword, DieFace.Bone],
    title: 'Strike',
    text: 'Deal 2 Damage, Gain Block equal to damage dealt.',
    tags: ['damage', 'defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      var damage = dealDamage({ value: 2, target, source, dice });
      addModifier(source, StatusEffect.Block, damage.value);
    }
  }, {
    ingredients: [DieFace.Sword, DieFace.Sword, DieFace.Sword],
    title: 'Heaviest Slash',
    text: 'Deal 7 Damage.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 7, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Sword, DieFace.Shield],
    title: 'Sword & Board',
    text: 'Deal 2 Damage, Gain 2 Block',
    tags: ['damage', 'defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(source, StatusEffect.Block, 2);
    }
  }, {
    ingredients: [DieFace.Sword, DieFace.Eye],
    title: 'Aimed Slash',
    text: 'Gain 1 Accuracy, Deal 1 Damage',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Accuracy, 1);
      dealDamage({ value: 1, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Sword, DieFace.Agility],
    title: 'Quick Strike',
    text: 'Deal 2 Damage, Gain 2 Dodge.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(source, StatusEffect.Dodge, 2);
    }
  },
];
