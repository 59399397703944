import { Modifier } from "../modifier";

export class Distracted extends Modifier {
  update(stacks: number, params?) {
    super.update(stacks, params);
    this.delay = true;
  }

  startTurn() {
    this.delay = false;
  }

  endTurn() {
    if (!this.delay) {
      this.removeStacks(1);
    }
  }
}