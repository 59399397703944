import type { Player } from "./player.type";
import type { MapData, MapNode } from "./map.type";
import { createDie } from "./die.type";
import type { Events } from "./eventList";

export type MapEvent = {
  title: string;
  id: Events;
  text: string;
  type: string;
  image: string;
  step: Step;
}

export type Step = {
  text: string;
  subtext?: string;
  image?: string;
  options: Array<Option>;
}

export type Condition = {
  text?: string;
  icon?: string;
  fulfils: (player: Player) => boolean;
};

export type Option = {
  text: string;
  subtext?: string;
  condition?: Condition;
  activate?: ((player: Player, map: MapData, node: MapNode) => void) | (Array<(player: Player, map: MapData, node: MapNode) => void>);
  next?: Step | Array<Step>;
}

export const getPlayerAttributes = (player: Player) => {
  var faces = [];

  player.dice.forEach(die => {
    var dieInstance = createDie(die);

    //add unique die faces to faces array
    dieInstance.faces.forEach(face => {
      if (faces.indexOf(face) == -1) {
        faces.push(face);
      }
    });
  });

  return faces;
}

export const playerHasAttribute = (player: Player, attribute: string) => {
  var faces = getPlayerAttributes(player);
  return faces.indexOf(attribute) > -1;
}

export const createHasCondition = (attribute: string) => {
  return {
    text: '',//`Has ${attribute} die`,
    icon: attribute,
    fulfils: (player: Player) => {
      return playerHasAttribute(player, attribute);
    }
  };
}

