<script lang="ts">
  type Cell = {
    plant: string;
    highlight: boolean;
  }

  let grid = new Array();
  //fill grid with empty objects
  for(let i = 0; i < 10; i++){
    grid[i] = new Array();
    for(let j = 0; j < 10; j++){
      grid[i][j] = {
        x: i,
        y: j,
        plant: null,
        highlight: false
      };
    }
  }

  function plantSquare(x: number, y: number){
    grid[x][y].plant = 'x';
    harvest();
  }

  function highlightSquare(x: number, y: number){
    grid[x][y].highlight = true;
    simulate(x,y);
  }
  function unhighlightSquare(x: number, y: number){
    //unhighlight all squares
    grid.forEach(row => {
      row.forEach(cell => {
        cell.highlight = false;
      });
    });
    simulate();
  }

  function simulate(x?: number, y?: number){
    let simulated = false;
    if(x !== undefined && y !== undefined){
      if(!grid[x][y].plant){
        simulated = true;
      }
      grid[x][y].plant = 'x';
    }

    var cells = detectRowsColsFilled();
    cells.push(detectBlocks());

    //transform cells to a flat array of unique cells
    cells = cells.flat().filter((cell, index, self) =>
      index === self.findIndex((t) => (
        t.x === cell.x && t.y === cell.y
      ))
    );

    cells.forEach((cell: Cell) => {
      cell.highlight = true;
    });

    if(simulated){
      grid[x][y].plant = null;
    }
  }

  function harvest(){
    let cells = detectRowsColsFilled();
    cells.push(detectBlocks());

    //transform cells to a flat array of unique cells
    cells = cells.flat().filter((cell, index, self) =>
      index === self.findIndex((t) => (
        t.x === cell.x && t.y === cell.y
      ))
    );

    cells.forEach((cell: Cell) => {
      cell.plant = null;
    });

    //clear highlight
    grid.forEach(row => {
      row.forEach(cell => {
        cell.highlight = false;
      });
    });
  }

  function detectRowsColsFilled(){
    let cells = [];

    /*
    // check rows (memory efficient but less readable)
    for(let i = 0; i < grid.length; i++){
      for(let j = 0; j < grid[i].length; j++){
        if(!grid[i][j].plant){
          break;
        }
        if(j == grid[i].length - 1){
          cells.push(grid[i]);
        }
      }
    }

    // check cols (memory efficient but less readable)
    for(let i = 0; i < grid[0].length; i++){
      for(let j = 0; j < grid.length; j++){
        if(!grid[j][i].plant){
          break;
        }
        if(j == grid.length - 1){
          let col = grid.map(row => row[i]);
          cells.push(col);
        }
      }
    }
    */

    // check rows (more readable)
    for(let i = 0; i < grid.length; i++){
      let row = grid[i];
      let filled = row.filter((cell: Cell) => cell.plant);
      if(filled.length == row.length){
        cells.push(row);
      }
    }
    // check cols (more readable)
    for(let i = 0; i < grid[0].length; i++){
      let col = grid.map(row => row[i]);
      let filled = col.filter((cell: Cell) => cell.plant);
      if(filled.length == col.length){
        cells.push(col);
      }
    }

    //return cells
    return cells;
  }

  function detectBlocks(){
    // a block is where a 3x3 or more square is filled
    let cells = [];
    for(let i = 0; i < grid.length - 2; i++){
      for(let j = 0; j < grid[i].length - 2; j++){
        let block = [
          grid[i][j], grid[i][j+1], grid[i][j+2],
          grid[i+1][j], grid[i+1][j+1], grid[i+1][j+2],
          grid[i+2][j], grid[i+2][j+1], grid[i+2][j+2]
        ];
        let filled = block.filter((cell: Cell) => cell.plant);
        if(filled.length == block.length){
          cells.push(block);
        }
      }
    }
    return cells.flat();
  }


</script>

<div class='frame'>

  <div class='buttons'>
    <h1>Farm</h1>

    <table>
      {#each grid as row, i}
        <tr>
          {#each row as cell, j}
            <td class="{cell.highlight ? 'highlight' : ''} {cell.plant ? 'plant' : ''}"
              on:keydown={()=>{}}
              on:click={() => {
                plantSquare(i, j);
              }}
              on:mouseenter={() => {
                highlightSquare(i, j);
              }}
              on:mouseleave={() => {
                unhighlightSquare(i, j);
              }}
            >
              {cell.plant ? cell.plant : ''}
            </td>
          {/each}
        </tr>
      {/each}
    </table>

  </div>
</div>

<style lang='less'>
  @import "../defines.less";

  .frame {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100vw;
  }

  table {
    //border-collapse: collapse;

    td{
      border: 1px solid var(--light-font);
      width: 50px;
      height: 50px;
      text-align: center;
      border-width: 2px;

      &.highlight{
        border-color: red;
      }
    }
  }
</style>