import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';
import { healthRemove } from '../player.type';

export class Spark extends Modifier {
  endTurn() {
    this.halveStacks();
  }

  useTechnique() {
    var half = Math.ceil(this.stacks / 2);
    const damage: Damage = {
      value: half,
      target: this.owner,
      source: this.owner,
      dice: []
    };

    healthRemove(damage);

    this.removeStacks(half);
  }
}