import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';


export const pincer: Technique[] = [
  {
    ingredients: [DieFace.Pincer],
    title: 'Nip',
    text: 'Apply 1 Vulnerable.',
    tags: ['debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(target, StatusEffect.Vulnerable, 1);
    }
  }, {
    ingredients: [DieFace.Pincer, DieFace.Pincer],
    title: 'Pinch',
    text: 'Deal 2 Damage, Apply 1 vulnerable.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, StatusEffect.Vulnerable, 1);
    }
  }, {
    ingredients: [DieFace.Pincer, DieFace.Pincer, DieFace.Pincer],
    title: 'Clamp',
    text: 'Deal 3 Damage, Apply 2 vulnerable.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
      addModifier(target, StatusEffect.Vulnerable, 2);
    }
  }, {
    ingredients: [DieFace.Pincer, DieFace.Poison],
    title: 'Grab and Stab',
    text: 'Apply 1 Snared and 3 Poison.',
    tags: ['debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(target, StatusEffect.Snared, 1);
      addModifier(target, StatusEffect.Poison, 3);
    }
  },{
    ingredients: [DieFace.Pincer, DieFace.Shell],
    title: 'Claw Slam',
    text: 'Deal 2 Damage, Apply 1 Dopey.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, StatusEffect.Dopey, 1);
    }
  }
];
