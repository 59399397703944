import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';
import { healDamage } from '../player.type';
import { actionHistory } from '../../../store';

export class Regeneration extends Modifier {
  startTurn() {
    const healing: Damage = {
      value: this.stacks,
      target: this.owner,
      source: this.owner,
      dice: []
    };

    healDamage(healing);

    this.halveStacks();
  }

  modifyNotches(notches: Array<string>) {
    var first = notches.indexOf('empty');
    for (var i = 0; i < this.stacks; i++) {
      if (notches[first + i] && notches[first + i].includes('empty')) {
        notches[first + i] += ' regen';
      }
    }
  }
}