import type { Player } from "../models/player.type";
import type { Technique } from "../models/techniques.type";

type Mazlovs = {
  heal: number;
  damage: number;
  defence: number;
  buff: number;
  debuff: number;
  ammo: number;
}

export function calculateMazlovs(player: Player, enemy: Player) : Mazlovs{
  const mazlovs: Mazlovs = {
    heal: 0,
    damage: 50,
    defence: 0,
    buff: 0,
    debuff: 0,
    ammo: 0,
  };

  // calculate health mazlov
  // mazlov.heal is set to 0-100 where 0 is at max hp and 100 is at min hp
  mazlovs.heal = 100 - (player.healthCurrent / player.healthMax) * 100;

  // calculate defence mazlov
  // mazlov.defence is set to 20-100 where 20 is no defence and 100 is max defence
  const defences = ['armoured', 'block', 'barrier', 'dodge', 'stealth', 'ward'];
  mazlovs.defence = Math.max(20, 100 - Math.min((player.statusEffects.find( (mod) => {
      defences.includes(mod.type);
    })?.stacks || 0), 5) * 20);


  // calculate buff mazlov
  // mazlov.buff is set to 25-75 where 75 is no buffs and 25 is 5 stacks of buff
  let total = 0;
  player.statusEffects.forEach((mod) => {
    if (mod.tags.includes('boon') && !mod.tags.includes('ammo')) {
      total += mod.stacks;
    }
    return total;
  });
  mazlovs.buff = Math.max(25, (5 - total) * 25);

  // calculate debuff mazlov
  // mazlov.debuff is set to 25-75 where 75 is no buffs and 25 is 5 stacks of debuff
  total = 0;
  enemy.statusEffects.forEach((mod) => {
    if (mod.tags.includes('bane')) {
      total += mod.stacks;
    }
    return total;
  });
  mazlovs.debuff = Math.max(25, (5 - total) * 25);

  // calculate ammo mazlov
  // mazlov.ammo is set to 0-100 where 0 is 4 ammo and 100 is 0 ammo
  const ammoDie = ['bow', 'crossbow', 'blowgun', 'dart'];
  // check if player.dice has any of ammoDie array
  const needsAmmo = player.dice.find((die) => {
    return ammoDie.includes(die);
  });
  if(needsAmmo){
    const ammo = player.statusEffects.find((mod) => {
      return mod.type === 'ammo';
    });
    mazlovs.ammo = Math.max(15, 100 - (ammo?.stacks || 0) * 25);
  }

  return mazlovs;
}

export function chooseTechnique(techniques: Array<Technique>, mazlovsIn: Mazlovs){

  //find required technique
  const requiredTechnique = techniques.find((technique) => {
    return technique.tags.includes('required');
  });
  if (requiredTechnique){
    return requiredTechnique;
  }

  let index = 0;
  // choose mazlov with the highest value
  const mazlovs = Object.keys(mazlovsIn).map(key => {
    return { key, value: mazlovsIn[key] };
  })
  .filter(mazlov => mazlov.value > 10)
  .sort((a, b) => b.value - a.value);

  let filteredTechniques = techniques.filter((technique) => !technique.tags.includes('intelligence'))
    .filter((technique) => technique.tags.includes(mazlovs[index].key));

  while (index < mazlovs.length-1 && filteredTechniques.length === 0) {
    index++;
    filteredTechniques = techniques.filter((technique) => technique.tags.includes(mazlovs[index].key));
  }

  if(filteredTechniques.length === 0){
    return;
  }

  //sort techniques, techniques with 'chain' tag go first, then sort by count of ingredients
  filteredTechniques = filteredTechniques.sort((a, b) => {
    if (a.tags.includes('chain') && !b.tags.includes('chain')) {
      return -1;
    }
    if (!a.tags.includes('chain') && b.tags.includes('chain')) {
      return 1;
    }
    if (a.tags.includes('value') && !b.tags.includes('value')) {
      return -1;
    }
    if (!a.tags.includes('value') && b.tags.includes('value')) {
      return 1;
    }
    return b.ingredients.length - a.ingredients.length;
  });

  return filteredTechniques[0];
}

