import { randomInt } from "./math";
import type { MapNode, MapEdge } from "../models/map.type";
import { AStarPathBetweenNodes } from "./astar";

export function createEdge(to, from, length: number = 50) {
  return {
    from: from,
    to: to,
    width: 3,
    dashes: true,
    length: length == 50 ? randomInt(20, 80) : length,
  };
}

export function createNode(id: number, /* type: string,  */cid?: number) {
  let node: MapNode = {
    id: id,
    group: cid == undefined ? id + 1000 : cid,
    // type: type,
    // shape: type == 'home' ? 'circle' : 'box',
    shape: 'image',
    image: '',
    color: 'red',
    size: 40,
    shadow: true,
    visit: () => { console.warn('visit not implemented'); },

    scouted: false,
    visited: false,
    open: false
  };

  return node;
}

export function createMap() {
  let nodes: Array<MapNode> = [];
  let edges: Array<MapEdge> = [];

  let innerNodes: Array<MapNode> = [];
  let middleNodes: Array<MapNode> = [];
  let outerNodes: Array<MapNode> = [];
  let edgeNodes: Array<MapNode> = [];
  let centerX = document.body.offsetWidth / 2;
  let centerY = document.body.offsetHeight / 2;

  const home = createNode(0, 0);
  home.x = centerX;
  home.y = centerY;
  /* home.fixed = {
    x: true,
    y: true
  }; */
  nodes.push(home);

  for (let i = 0; i < 4; i++) {
    var node = createNode(nodes.length, 0)
    node.scouted = true;
    innerNodes.push(node);
    nodes.push(node);

    edges.push(createEdge(0, node.id));
  }

  for (let i = 0; i < 16; i++) {
    var node = createNode(nodes.length, nodes.length + 1000)
    outerNodes.push(node);
    nodes.push(node);

    //set node x and y to be on the edge of the circle
    let angle = i * Math.PI / 8;
    var minSize = Math.min(document.body.offsetWidth, document.body.offsetHeight);
    node.x = Math.cos(angle) * minSize + randomInt(-minSize / 10, minSize / 10);
    node.y = Math.sin(angle) * minSize + randomInt(-minSize / 10, minSize / 10);

    // create loop
    let next = node.id + 1;
    if (next > 20) { next = 5 };
    edges.push(createEdge(node.id, next, 200));
  }

  for (var i = 0; i < 4; i++) {
    let innerNode = innerNodes[i];
    let outerNode = outerNodes[i * 4 + randomInt(0, 2)];

    const connection = createConnection(innerNode, outerNode, { nodes, edges });
    middleNodes = middleNodes.concat(connection.nodes);
  }

  for (var i = 0; i < 8; i++) {
    let outerNode = outerNodes[i * 2];

    const branch = createBranch(outerNode, { nodes, edges });
    edgeNodes = edgeNodes.concat(branch.nodes);
  }

  let map = {
    nodes: nodes,
    edges: edges,
    innerNodes: innerNodes,
    middleNodes: middleNodes,
    outerNodes: outerNodes,
    edgeNodes: edgeNodes
  };
  map.nodes = nodes.sort((a, b) => {
    if (!a.pathLength) {
      a.pathLength = AStarPathBetweenNodes(home, a, map).length;
    }
    if (!b.pathLength) {
      b.pathLength = AStarPathBetweenNodes(home, b, map).length;
    }
    return a.pathLength - b.pathLength;
  });

  return map;
}



export function createBranch(startNode, map) {
  const minNodes = 2;
  const maxNodes = 4;
  let nodes: Array<MapNode> = [];
  let edges: Array<MapEdge> = [];

  let nodeCount = randomInt(minNodes, maxNodes);
  let currentNode = startNode;
  let nextNode;
  const cid = map.nodes.length;

  const type = randomInt(0, 2);
  // create a branch that loops back on itself
  if (type == 0) {
    for (let i = 0; i < maxNodes; i++) {
      nextNode = createNode(map.nodes.length, cid);
      map.nodes.push(nextNode);
      nodes.push(nextNode);
      map.edges.push(createEdge(currentNode.id, nextNode.id));
      currentNode = nextNode;
    }
    map.edges.push(createEdge(currentNode.id, startNode.id));
  }
  // create a branch that forks
  else if (type == 1) {
    // fork immediately
    if (Math.random() < 0.5) {
      for (let i = 0; i < 2; i++) {
        currentNode = startNode;
        for (let j = 0; j < 2; j++) {
          nextNode = createNode(map.nodes.length, cid);
          map.nodes.push(nextNode);
          nodes.push(nextNode);
          map.edges.push(createEdge(currentNode.id, nextNode.id));
          currentNode = nextNode;
        }
      }
    }
    // place 2 nodes then fork
    else {
      for (let i = 0; i < 3; i++) {
        nextNode = createNode(map.nodes.length, cid);
        map.nodes.push(nextNode);
        nodes.push(nextNode);
        map.edges.push(createEdge(currentNode.id, nextNode.id));
        currentNode = nextNode;
      }

      nextNode = createNode(map.nodes.length, cid);
      map.nodes.push(nextNode);
      map.edges.push(createEdge(currentNode.id - 1, nextNode.id));
    }
  }
  // create a branch that merges back to the outer path
  else if (type == 2) {
    let i = randomInt(-1, 1);
    while (i == 0) {
      i = randomInt(-1, 1);
    }
    let endNode2id = startNode.id + i;
    if (endNode2id > 20) {
      endNode2id -= 16;
    };
    if (endNode2id < 5) {
      endNode2id += 16;
    };

    for (let i = 0; i < nodeCount; i++) {
      nextNode = createNode(map.nodes.length, cid);
      map.nodes.push(nextNode);
      nodes.push(nextNode);
      map.edges.push(createEdge(currentNode.id, nextNode.id));
      currentNode = nextNode;
    }
    map.edges.push(createEdge(currentNode.id, endNode2id));

    // create nubs
    for (let i = 0; i < maxNodes - nodeCount; i++) {
      nextNode = createNode(map.nodes.length, cid);
      map.nodes.push(nextNode);
      nodes.push(nextNode);
      map.edges.push(createEdge(currentNode.id, nextNode.id));
      // random node
      currentNode = nodes[randomInt(0, nodes.length - 1)];
    }
  }

  return {
    nodes: nodes,
    edges: edges
  };
}


//split1 - fork at end node and connect to +/- 1 circle node
//split2 - fork at second last node, generate a new node and connect at +/- 2  circle node
//branch1 - loose path of length 1
//branch2 - loose path of length 2
//branch3 - 2 loose paths of length 1
// merge1 - fork at start node, and merge 1 node later
// merge2 - fork at start node, and merge 2 nodes later

export function createConnection(startNode, endNode, map) {
  const minNodes = 2;
  const maxNodes = 3;
  let nodes: Array<MapNode> = [];
  let edges: Array<MapEdge> = [];

  let nodeCount = randomInt(minNodes, maxNodes);
  let currentNode = startNode;
  let nextNode;
  const cid = map.nodes.length;

  for (let i = 0; i < nodeCount; i++) {
    nextNode = createNode(map.nodes.length, cid);
    map.nodes.push(nextNode);
    nodes.push(nextNode);
    map.edges.push(createEdge(currentNode.id, nextNode.id));
    currentNode = nextNode;
  }

  map.edges.push(createEdge(nextNode.id, endNode.id));

  const type = randomInt(0, 2);
  // Split the last node to the outer circle
  if (type == 0) {
    let i = randomInt(-1, 1);
    while (i == 0) {
      i = randomInt(-1, 1);
    }
    let endNode2id = endNode.id + i;
    if (endNode2id > 20) {
      endNode2id -= 16;
    };
    if (endNode2id < 5) {
      endNode2id += 16;
    };

    if (nodeCount == maxNodes) {
      // map.edges.push(createEdge(nextNode.id, endNode.id + i));

      let loopNode = createNode(map.nodes.length, cid);
      map.nodes.push(loopNode);
      map.edges.push(createEdge(loopNode.id, nextNode.id - 1));
      map.edges.push(createEdge(loopNode.id, endNode.id + i));
    }
    else {
      nextNode = createNode(map.nodes.length, cid);
      map.nodes.push(nextNode);

      map.edges.push(createEdge(nodes[nodes.length - 2].id, nextNode.id));
      map.edges.push(createEdge(nextNode.id, endNode.id + i));

      let loopNode = createNode(map.nodes.length, cid);
      map.nodes.push(loopNode);
      map.edges.push(createEdge(loopNode.id, nextNode.id));
      map.edges.push(createEdge(loopNode.id, nodes[nodes.length - 1].id));

      nodes.push(nextNode);
    }
  }
  // branch
  else if (type == 1) {
    currentNode = nodes[randomInt(0, nodes.length - 1)];
    for (let i = 0; i < maxNodes - nodeCount + 1; i++) {
      nextNode = createNode(map.nodes.length, cid);
      map.nodes.push(nextNode);
      nodes.push(nextNode);
      map.edges.push(createEdge(currentNode.id, nextNode.id));

      currentNode = nodes[randomInt(0, nodes.length - 1)]; //this line chooses a random node to branch from
      // currentNode = nextNode; //this line chooses the last node to branch from
    }
  }
  // merge
  else if (type == 2) {
    // create a branch that starts at the start node, and merges back at the end node
    let currentNode = startNode;
    let endNode = nodes[nodes.length - 1];
    let subNodeCount = maxNodes - nodeCount + 1;
    for (let i = 0; i < subNodeCount; i++) {
      nextNode = createNode(map.nodes.length, cid);
      map.nodes.push(nextNode);
      nodes.push(nextNode);
      map.edges.push(createEdge(currentNode.id, nextNode.id));
      currentNode = nextNode;
    }
    map.edges.push(createEdge(currentNode.id, endNode.id));
  }
  // nubs
  /* else{
    currentNode = nodes[randomInt(0,nodes.length-1)];
    for(let i = 0; i < maxNodes-nodeCount+1; i++){
      nextNode = createNode(map.nodes.length, cid);
      map.nodes.push(nextNode);
      nodes.push(nextNode);
      map.edges.push(createEdge(currentNode.id, nextNode.id));
      currentNode = nextNode;
    }
  } */

  return {
    nodes: nodes,
    edges: edges
  }
}


import { createBattle, createBossBattle } from '../models/battle.type';
import { getIcon } from '../icon/icons';
import { screens } from '../../store';
import { getEvent, getEventByID } from '../models/events/eventsAll';
import { EventType, Events } from "../models/eventList";

function buildNodes(map) {
  var types: Array<string> = [];
  for (let i = 0; i < 60; i++) {
    types.push('battle');
  }
  for (let i = 0; i < 5; i++) {
    // console.log(i)
    types.push('event');
    types.push('scout');
    types.push('camp');
  }
  //randomly sort types array
  types.sort(() => Math.random() - 0.5);
  types.sort(() => Math.random() - 0.5);
  types.sort(() => Math.random() - 0.5);
  types.sort(() => Math.random() - 0.5);

  types.unshift('town');
  var battle = 0;
  var count = 0;

  map.nodes.forEach(node => {
    const type = types.shift();

    if (/battle/.test(type)) {
      node.type = 'battle';
      node.battle = createBattle(undefined, battle);
      node.icon = 'battle' + node.battle.level;
      node.image = getIcon(node.icon);
      node.visit = () => {
        screens.push('battlestart', { battle: node.battle });
        return new Promise((resolve) => {
          node.battle.resolve = resolve;
        });
      };

      if (count == 3) {
        count = 0;
        battle++;
        if (battle > 6) {
          battle = 6;
        }
      }
      count++;
    }
    if (/scout/.test(type)) {
      node.type = 'event';
      node.icon = 'scout';
      node.image = getIcon(node.icon);
      node.visit = () => {
        screens.push('event', {event: node.event, map, node});
      };
      node.event = getEvent(EventType.Scout);
    }
    if (/event/.test(type)) {
      node.type = 'event';
      node.icon = 'event';
      node.image = getIcon(node.icon);
      node.event = getEvent(EventType.Event);
      node.visit = () => {
        screens.push('event', {event: node.event, map, node});
      };
    }
    if (/camp/.test(type)) {
      node.type = 'event';
      node.icon = 'campfire';
      node.image = getIcon(node.icon);
      node.event = getEvent(EventType.Campfire);
      node.visit = () => {
        screens.push('event', {event: node.event, map, node});
      };
    }
    if (/town/.test(type)) {
      node.type = 'town';
      node.icon = 'town';
      node.image = getIcon(node.icon);
      node.shape = 'circle';
      node.visited = true;
    }
  });

  // get two inner nodes and two outer nodes
  let bossNodes = [];
  let index1 = randomInt(0, map.middleNodes.length - 1);
  bossNodes.push(map.middleNodes[index1]);
  let index2 = randomInt(0, map.outerNodes.length - 1);
  bossNodes.push(map.outerNodes[index2]);

  // same for outer nodes
  index1 = randomInt(0, map.edgeNodes.length - 1);
  index2 = randomInt(0, map.edgeNodes.length - 1);
  while (index1 == index2) {
    index2 = randomInt(0, map.edgeNodes.length - 1);
  }
  bossNodes.push(map.edgeNodes[index1]);
  bossNodes.push(map.edgeNodes[index2]);

  bossNodes.forEach((node, index) => {
    // create new boss node
    var bossNode = createNode(map.nodes.length, index);
    bossNode.type = 'boss';
    bossNode.icon = 'boss' + (index+1);
    bossNode.image = getIcon(bossNode.icon);
    bossNode.battle = createBossBattle();
    bossNode.battle.level = (index + 1) * 2;
    bossNode.visit = () => {
      screens.push('battlestart', { battle: bossNode.battle });
      return new Promise((resolve) => {
        bossNode.battle.resolve = resolve;
      });
    };

    // add it to the map
    map.nodes.push(bossNode);
    map.edges.push(createEdge(node.id, bossNode.id));
  });
}

export function createCampaign(){
  let map = createMap();
  buildNodes(map);

  // For testing, create node adjacent to start node
  let node = createNode(map.nodes.length, 0);
  map.nodes.push(node);
  map.edges.push(createEdge(0, node.id));
  node.type = 'event';
  node.icon = 'event';
  node.image = getIcon(node.icon);
  node.event = getEventByID(Events.TravellingCartographer);
  node.visit = () => {
    screens.push('event', { event: node.event, map, node });
  };

  return map;
}