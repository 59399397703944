import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';
import { addModifier, dealDamage, healDamage } from '../player.type';
import type { Technique } from '../techniques.type';
import { techniqueHistory } from '../../../store';
import type { Die } from '../die.type';
import type { Player } from '../player.type';
import { StatusEffect } from "../status-effects";

export class Haste extends Modifier {
  getTechniques(): Technique[] {
    return [{
      title: 'Follow Up',
      text: 'Deal 1 Damage.',
      tags: ['chain', 'damage'],
      ingredients: [],
      requirements: [{
        text: 'Previous technique was an attack',
        icon: 'fist',
        test: (source, target) => {
          const lastAction = techniqueHistory[0];
          return lastAction && lastAction.tags && lastAction.tags.includes('damage') && !lastAction.tags.includes('chain');
        }
      }],
      activate: (target: Player, source: Player, dice: Array<Die>) => {
        dealDamage({ value: 1, target, source, dice });
      }
    }, {
      title: 'Buckle Up',
      text: 'Gain 1 Block.',
      tags: ['chain', 'defence'],
      ingredients: [],
      requirements: [{
        text: 'Previous technique was a defence',
        icon: 'bone',
        test: (source, target) => {
          const lastAction = techniqueHistory[0];
          return lastAction && lastAction.tags && lastAction.tags.includes('defence') && !lastAction.tags.includes('chain');
        }
      }],
      activate: (target: Player, source: Player, dice: Array<Die>) => {
        addModifier(source, StatusEffect.Block, 1);
      }
    }, {
      title: 'Follow Through',
      text: 'Heal 1 Health.',
      tags: ['chain', 'heal'],
      ingredients: [],
      requirements: [{
        text: 'Previous technique was a heal',
        icon: 'regeneration',
        test: (source, target) => {
          const lastAction = techniqueHistory[0];
          return lastAction && lastAction.tags && lastAction.tags.includes('heal') && !lastAction.tags.includes('chain');
        }
      }],
      activate: (target: Player, source: Player, dice: Array<Die>) => {
        healDamage({ value: 1, target: source, source, dice });
      }
    }]
  }

  update(stacks: number, params?) {
    super.update(stacks, params);
    this.delay = true;
  }

  startTurn() {
    if (this.delay) {
      const focus = this.owner.statusEffects.find(modifier => modifier.type === 'focus');
      if (!focus) {
        this.delay = false;
      }
    }
  }

  endTurn() {
    if (!this.delay) {
      this.removeStacks(1);
    }
  }
}