import { Modifier } from "./modifier";

export class Item extends Modifier {

  constructor(itemDefinition: itemDefinition) {
    super();
    this.title = itemDefinition.title;
    this.text = itemDefinition.text;
    this.type = itemDefinition.type;
    this.icon = itemDefinition.icon;
    this.bonusIcon = itemDefinition.bonusIcon;
    this.stacks = 1;
  }
}

export type itemDefinition = {
  type: Items;
  title: string;
  text: string;
  icon: string;
  bonusIcon?: string;

  class: typeof Item;
}

export enum Items {
  BombSmoke = 'Smoke Bomb',
  BombFlash = 'Flash Bomb',
  BombFire = 'Fire Bomb',
  BombSticky = 'Sticky Bomb',

  CorestoneFragmentFire = 'Corestone Fragment (fire)',
  CorestoneFragmentLightning = 'Corestone Fragment (lightning)',
  CorestoneFragmentIce = 'Corestone Fragment (ice)',
  CorestoneFragmentEarth = 'Corestone Fragment (earth)',
  RustyShiv = 'Rusty Shiv',
  RustyCoin = 'Rusty Coin',

  PotionHealth = 'healthpotion',
  PotionMana = 'Mana Potion',
  PotionClarity = 'Clarity Potion',
  PotionStrength = 'Strength Potion',

  RazielsWhisper = 'raziels-whisper',
}