import type { Player } from "../player.type";
import { addItem, addModifier, addMoney, dealDamage, healthRemove } from "../player.type";
import { screens } from '../../../store';
import { createDie } from "../die.type";
import type { MapNode, MapData } from "../map.type";
import type { Step, MapEvent, Option } from "../events.type";
import { createHasCondition, playerHasAttribute } from "../events.type";
import { createEdge, createNode } from "../../utils/map";
import { AStarPathBetweenNodes } from "../../utils/astar";
import { getIcon } from '../../icon/icons';
import { Events, EventType } from '../eventList';
import { StatusEffect } from "../status-effects";
import { Items } from "../item.type";
import { vendors } from "./vendors";
import { campevents } from "./campevents";
import { scoutevents } from "./scoutevents";
import { DieFace } from "../face.type";

const leave = {
  text: 'Leave',
  activate: (player, map, node) => {
    screens.pop();
  }
};

// events to write
// wasp nest
// dropped coin
// path of gold
// nest egg
// poison ivy

const events: Array<MapEvent> = [
  {
    title: 'Sentinel\'s Sanctuary',
    text: 'In the distance, you spot an imposing altar, fashioned in the shape of a shield, its metallic surface glinting in the light, radiating a sense of protection and ancient power.',
    type: 'event',
    image: 'altarShield',
    id: Events.SentinelSanctuary,
    step: {
      text: 'As you draw closer to the altar, the air seems to ripple with a soothing aura, and you can\'t help but feel a comforting embrace, as if the Sentinel\'s Altar recognizes your presence and stands ready to offer you its guardian\'s blessings.',
      options: [
        {
          text: 'Kneel before the Sentinel\'s Altar and offer a heartfelt prayer.',
          activate: [(player) => {
            addModifier(player, StatusEffect.Delay, 5, { imbue: StatusEffect.Block, duration: 2 });
          }, (player) => {
            addModifier(player, StatusEffect.Delay, 5, { imbue: StatusEffect.Ward, duration: 2 });
          }],
          next: [{
            text: 'Touched by your sincerity, a faint glow envelops you, and a basic blessing of enhanced protection surrounds you, increasing your resistance to incoming attacks for a limited time.',
            image: 'blessing1',
            options: [leave]
          }, {
            text: 'Touched by your sincerity, a faint glow envelops you, and a basic blessing of enhanced protection surrounds you, increasing your resistance to incoming attacks for a limited time.',
            image: 'blessing1',
            options: [leave]
          }]
        }, {
          text: 'Offer a donation before the Sentinel\'s Altar, expressing your dedication and respect.',
          condition: {
            text: '10 gold',
            // icon: 'money',
            fulfils: (player: Player) => {
              return player.money > 10;
            }
          },
          activate: [(player: Player, map: MapData, node: MapNode) => {
            player.money -= 10;
            addModifier(player, StatusEffect.Armoured, 2, { duration: 2 });
          }, (player: Player, map: MapData, node: MapNode) => {
            player.money -= 10;
            addModifier(player, StatusEffect.Barrier, 5, { duration: 2 });
          }],
          next: [{
            text: 'Grateful for your offering, the altar responds with a radiant surge of energy, bestowing upon you an advanced blessing of fortified defense, empowering you with unparalleled protection and resilience in battles for an extended period.',
            image: 'blessing1',
            options: [leave]
          }, {
            text: 'Grateful for your offering, the altar responds with a radiant surge of energy, bestowing upon you an advanced blessing of fortified defense and augmented strength, empowering you with unparalleled protection and resilience in battles for an extended period.',
            image: 'blessing1',
            options: [leave]
          }]
        }, {
          text: 'Steal the money from the offerings meant for the Sentinel\'s Sanctuary.',
          activate: (player: Player, map: MapData, node: MapNode) => {
            // gain money and a curse
            player.money += 10;
            addModifier(player, StatusEffect.Vulnerable, 2, { duration: 2 });
          },
          next: {
            text: 'Instantly, a foreboding aura envelops you, and the ever-watchful sentinels of the altar respond with swift justice placing upon you a curse of vulnerability, leaving you more susceptible to damage until you atone for your act of theft and return what you\'ve taken.',
            image: 'curse1',
            options: [leave]
          }
        },
        leave
      ]
    }
  }, {
    title: 'Veil of Lamentations',
    text: 'In the shadows, you glimpse an elusive altar dedicated to stealth and roguery, its sleek design concealed within the darkness, emanating an enigmatic allure that beckons those with cunning hearts.',
    type: 'event',
    image: 'altarStealth',
    id: Events.VeilLamentations,
    step: {
      text: 'As you cautiously approach the altar shrouded in secrecy, a subtle breeze seems to guide you closer, and you sense an alluring aura of subtlety surrounding it, as if the altar itself is a silent mentor in the art of stealth and trickery.',
      options: [
        {
          text: 'Offer a prayer to the altar of stealth, seeking its elusive favor.',
          activate: [(player) => {
            addModifier(player, StatusEffect.Delay, 3, { imbue: StatusEffect.Dodge, duration: 2 });
          }, (player) => {
            addModifier(player, StatusEffect.Accuracy, 2, { duration: 2 });
          }],
          next: [{
            text: 'In response to your plea, a subtle shimmer envelops you, granting a basic blessing of enhanced agility and concealment, allowing you to move with swiftness and evade detection more easily for a limited time.',
            options: [leave]
          }, {
            text: 'In response to your plea, a subtle shimmer envelops you, granting a basic blessing of enhanced agility and concealment, allowing you to execute sneak attacks with enhanced precision for an extended period.',
            options: [leave]
          }]
        }, {
          text: 'Place a carefully chosen donation before the altar of stealth, symbolizing your commitment to the craft of roguery.',
          condition: {
            text: '10 Gold',
            // icon: 'money',
            fulfils: (player: Player) => {
              return player.money > 10;
            }
          },
          activate: [(player: Player, map: MapData, node: MapNode) => {
            player.money -= 10;
            addModifier(player, StatusEffect.Stealth, 2, { duration: 2 });
          }, (player: Player, map: MapData, node: MapNode) => {
            player.money -= 10;
            addModifier(player, StatusEffect.Reactive, 2, { duration: 2 });
          }],
          next: [{
            text: 'Pleased by your tribute, the altar responds with a shadowy embrace, granting you an advanced blessing of unparalleled stealth, augmenting your ability to move undetected, blend into shadows effortlessly, and execute sneak attacks with enhanced precision for an extended period.',
            options: [leave]
          }, {
            text: 'Pleased by your dedication, the altar responds with a surge of swift energy, bestowing upon you an advanced blessing of heightened reflexes, allowing you to react with remarkable speed and perform lightning-fast maneuvers with unparalleled precision for an extended period.',
            options: [leave]
          }]
        }, {
          text: 'Reach out and pilfer a valuable artifact from the shrine.',
          activate: (player: Player, map: MapData, node: MapNode) => {
            // gain  10 money
            addItem(player, Items.RazielsWhisper);
            // gain a curse
            addModifier(player, StatusEffect.Cursed, 2, { duration: -1 });
          },
          next: {
            text: 'As you clasp the stolen treasure in your hands, a malevolent aura surrounds you, and a cursed mark manifests, draining your vitality and leaving you vulnerable to misfortune and adversity until you can atone for your act of theft.',
            options: [leave]
          }
        },
        leave
      ]
    }
  }, {
    title: 'Elemental Corestone',
    text: 'An ancient elemental corestone, with magical bursts of earth, fire, water, and air, emanates a mesmerizing glow, inviting daring adventurers to seek the blessings and powers of the elements within.',
    type: 'event',
    image: 'corestone',
    id: Events.ElementalCorestone,
    step: {
      text: 'As you approach the Corestone, an ethereal hum fills the air, resonating with the beating heart of the elements, and a pulsating aura of raw energy surrounds the majestic crystal, inviting you to harness its formidable power.',
      options: [
        {
          text: 'Embrace the Blaze.',
          condition: createHasCondition('fire'),
          activate: (player) => {
            // add imbue burning and infuse fire
            addModifier(player, StatusEffect.Imbue, 1, { imbue: StatusEffect.Burning, duration: 2 });
            addModifier(player, StatusEffect.Infusion, 1, { infusion: DieFace.Fire, duration: 2 });
          },
          next: {
            text: 'You reach out to the Corestone and focus on the intense heat. A rush of envelops you and you feel an overwhelming surge of fiery power coursing through your entire being, igniting a newfound confidence and mastery over the flames.',
            options: [leave]
          }
        }, {
          text: 'Grasp the Thunder.',
          condition: createHasCondition('lightning'),
          activate: (player) => {
            // add imbue shock and infuse lightning
            addModifier(player, StatusEffect.Imbue, 1, { imbue: StatusEffect.Shocked, duration: 2 });
            addModifier(player, StatusEffect.Infusion, 1, { infusion: DieFace.Lightning, duration: 2 });
          },
          next: {
            text: 'Boldly, you reach out to the Corestone, embracing the crackling electricity with open arms, and in that electrifying moment, you feel a surge of sheer power, gaining control over lightning magic that can unleash devastating bolts of energy upon your foes.',
            options: [leave]
          }
        }, {
          text: 'Seize the Ice.',
          condition: createHasCondition('ice'),
          activate: (player) => {
            // add imbue freeze and infuse ice
            addModifier(player, StatusEffect.Imbue, 1, { imbue: StatusEffect.Freezing, duration: 2 });
            addModifier(player, StatusEffect.Infusion, 1, { infusion: DieFace.Ice, duration: 2 });
          },
          next: {
            text: 'With a sense of serenity, you embrace the Corestone\'s chilling touch, letting the icy essence envelop you, and in that tranquil moment, you feel a profound connection with the frost element, gaining mastery over ice magic.',
            options: [leave]
          }
        }, {
          text: 'Tap the Earth.',
          condition: createHasCondition('earth'),
          activate: (player) => {
            // add imbue brittle and infuse earth
            addModifier(player, StatusEffect.Imbue, 1, { imbue: StatusEffect.Brittle, duration: 2 });
            addModifier(player, StatusEffect.Infusion, 1, { infusion: DieFace.Earth, duration: 2 });
          },
          next: {
            text: 'With a resolute spirit, you reach out to the Corestone, grounding yourself in the solidity of the earth\'s essence, and in that steadfast moment, you feel an unyielding connection with the earth element, gaining the power to manipulate the very ground beneath your feet.',
            options: [leave]
          }
        }, {
          text: 'Forge a connection with the primal forces.',
          activate: (player) => {
            addModifier(player, StatusEffect.Powerful, 2, { duration: 2 });
          },
          next: {
            text: 'Responding to your resolute spirit the Corestone acknowledges your sincerity and grants you a blessing of enhanced prowess, augmenting your skills for a limited time, empowering you to face the challenges ahead with newfound might.',
            options: [leave]
          }
        }, {
          text: 'Smash the Corestone.',
          activate: (player) => {
            dealDamage({ value: 3, target: player, source: player, dice: [] });
            addModifier(player, StatusEffect.Blind, 2, { duration: 2 });

            // add a corestone fragment in one of the four elements (ice, fire, lightning, earth)
            const items = [Items.CorestoneFragmentEarth, Items.CorestoneFragmentFire, Items.CorestoneFragmentIce, Items.CorestoneFragmentLightning];
            const item = items[Math.floor(Math.random() * items.length)];
            addItem(player, item);
          },
          next: {
            text: 'As the Corestone shatters, a surge of chaotic energy is released, causing a violent explosion that inflicts severe damage to you and disrupts the delicate balance of the elements. After it settles, you notice a fragment of the Corestone, and you pick it up, hoping to find a use for it later.',
            options: [leave]
          }
        },
        leave
      ]
    }
  }, /* {
    title: 'The Great Library',
    text: 'A towering library, filled with ancient tomes and scrolls, stands before you, its vast collection of knowledge and wisdom waiting to be discovered.',
    type: 'event',
    image: 'library',
    id: Events.GreatLibrary,
    step: {
      text: 'As you enter the library, you are greeted by a friendly librarian, who offers to assist you in your search for knowledge.',
      options: [
        {
          text: 'Ask the librarian for a book on the art of combat.',
          activate: (player) => {
            addModifier(player, 'block', 2, { duration: 2 });
          }
        }, {
          text: 'Ask the librarian for a book on the art of stealth.',
          activate: (player) => {
            addModifier(player, 'stealth', 2, { duration: 2 });
          }
        }, {
          text: 'Ask the librarian for a book on the art of magic.',
          activate: (player) => {
            addModifier(player, 'infusion', 2, { duration: 2 });
          }
        }, {
          text: 'Ask the librarian for a book on the art of survival.',
          activate: (player) => {
            addModifier(player, 'ward', 2, { duration: 2 });
          }
        }, {
          text: 'Ask the librarian for a book on the art of the elements.',
          activate: (player) => {
            addModifier(player, StatusEffect.Powerful, 2, { duration: 2 });
          },
          next: {
            text: 'The librarian hands you a book, and you spend some time reading it, learning new techniques and gaining a deeper understanding of the art of combat.',
            options: [leave]
          }
        }
      ],
    }
  } */
  {
    title: 'Enigmatic Elixir',
    text: 'Amidst the forest\'s depths, a mysterious potion sits on a table, its swirling, iridescent glow hinting at untold magical properties.',
    type: 'event',
    image: 'potion1',
    id: Events.EnigmaticElixir,
    step: {
      text: 'Amidst the forest\'s depths, a mysterious potion sits on a table, its swirling, iridescent glow hinting at untold magical properties.',
      options: [
        {
          text: 'Seize the vial, uncork it and take a gulp.',
          activate: [(player) => {
            addModifier(player, StatusEffect.Powerful, 2, { duration: 2 });
          }, (player) => {
            addModifier(player, StatusEffect.Shocked, 2, { duration: 2 });
          }],
          next: [
            {
              text: 'As the elixir\'s essence surges through your being, a profound transformation occurs, imbuing you with an extraordinary surge of power.',
              image: 'blessing1',
              options: [leave]
            }, {
              text: 'In the wake of swallowing the elixir, a dizzying sensation overcomes you, and your vision blurs as an unsettling disorientation takes hold. The elixir\'s magical properties prove too potent for your constitution, leading to temporary confusion and vulnerability.',
              image: 'curse1',
              options: [leave]
            }
          ]
        }, {
          condition: createHasCondition('brain'),
          text: 'Inspect the swirling patterns within the vial and decipher the cryptic symbols etched on its surface.',
          next: {
            text: 'Drawing upon your keen intelligence and vast knowledge of arcane lore, you realize that this elixir is a rare "Elixir of Clarity" - a potion renowned for heightening one\'s mental faculties.',
            image: 'blessing1',
            options: [
              {
                text: 'Drink the elixir.',
                activate: (player) => {
                  addModifier(player, StatusEffect.Witty, 2, { duration: 2 });
                }
              }, {
                text: 'Take the elixir.',
                activate: (player) => {
                  addItem(player, Items.PotionClarity);
                }
              },
              leave
            ]
          }
        }
      ],
    }
  }
  //@ts-ignore
].concat(vendors).concat(campevents).concat(scoutevents);

let eventsArray = [];
export const getEvent = (eventType: EventType = EventType.Event) => {
  var filteredEvents = eventsArray.filter(event => {
    return event.type == eventType;
  });

  if (!filteredEvents.length) {
    let filteredEvents2 = events.filter(event => {
      return event.type == eventType;
    });
    eventsArray.push(...filteredEvents2);
    eventsArray.sort(() => Math.random() - 0.5);
    // return getEvent(eventType);
    filteredEvents = eventsArray.filter(event => {
      return event.type == eventType;
    });
  }


  eventsArray.splice(eventsArray.indexOf(filteredEvents[0]), 1);
  return filteredEvents.shift();
};

export const getEventByID = (id: Events) => {
  let eventt = events.find(event => {
    return event.id == id;
  });

  return eventt;
}
