<script lang="ts">
  import { screens, playerTruth } from '../store';
  import Portrait from "./components/portrait.svelte";
  import { tweened } from 'svelte/motion';
  import { addModifier } from './models/player.type';
  import { StatusEffect } from './models/status-effects';
    import { track } from './utils/playerTracking';

  export let data;

  let experienceGain = 5 + data.battle.level * 5;
  let moneyGain = 5;

  // reduce modifiers duraction by 1
  $playerTruth.statusEffects.forEach(modifier => {
    if(modifier.duration){
      modifier.duration --;
    }
  });
  $playerTruth.statusEffects = $playerTruth.statusEffects.filter(modifier => {
    return modifier.duration > 0;
  });


  let experienceUI = tweened($playerTruth.experience);
  let moneyUI = tweened($playerTruth.money);

  if(data.battle.outcome === 'win'){
    $playerTruth.experience += experienceGain;
    $playerTruth.money += moneyGain;
  }
  else{
    addModifier($playerTruth, StatusEffect.Wound, 1);
  }

  setTimeout(() => {
    experienceUI.set($playerTruth.experience);
    setTimeout(() => {
      moneyUI.set($playerTruth.money);
    }, 500);
  }, 1000);

  function back(){
    screens.pop();

    // resolve promise?
    if(data.battle.resolve){
      data.battle.resolve(data.battle.outcome);
    }
  }

  let experienceRequired = ($playerTruth.level + 1) * 5 * 3;
  function levelUp(){
    if($playerTruth.experience >= experienceRequired){
      $playerTruth.experience -= experienceRequired;
      experienceUI.set($playerTruth.experience);
      screens.push('upgrade');
    }
  }


  track('Battle Over', {
    outcome: data.battle.outcome,

    player_class: $playerTruth.type,
    player_archetype: $playerTruth.archetype,
    player_level: $playerTruth.level,

    enemy_class: data.battle.enemy.type,
    enemy_level: data.battle.enemy.level
  });
</script>

<div class='container'>
  {#if data.battle.outcome === 'win'}
    <h1>Victory!</h1>
  {:else}
    <h1>Defeat!</h1>
  {/if}

  <div class='vs'>
    <div>
      <h2>{$playerTruth.title}</h2>
      <div class='portrait-container'>
        <Portrait data={$playerTruth}/>
      </div>
    </div>

    <!-- <div class='center-flex'>
    </div> -->

    <div class=''>
      <h2>Experience</h2>
      <div class='bar-container yellow' on:click={levelUp} on:keydown={()=>{}}>
        <div class='bar-inner' style='width: {$experienceUI / experienceRequired*100}%'>
        </div>
        {#if $experienceUI >= experienceRequired}
          <p>Level Up!</p>
        {:else}
          <p>{Math.round($experienceUI)} / {experienceRequired}</p>
        {/if}
      </div>

      <h2>Money</h2>
      <h2>+{Math.round($moneyUI)}</h2>
    </div>
  </div>

  <div class='description'>
  </div>

  <button on:click={back} class='btn'>
    Back
  </button>
</div>


<style lang='less'>
  @import "../defines.less";

  :root{
    background-color: var(--background-colour);
  }

  .container{
    display: flex;
    flex-direction: column;
    grid-gap: 50px;
    margin: auto;

    button{
      background-color: var(--background-colour-dark);
    }

    .vs{
      display: grid;
      grid-template-columns: max-content max-content;
      grid-gap: 25px;
      align-items: center;
      justify-content: center;
      width: 100vw;
      max-width: 1000px;
      margin: auto;

      .portrait-container{
        padding: 0 10px;
      }
    }

    .bar-container{
      width: 100%;
      height: 25px;
      background: white;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .bar-inner{
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
        transition: width 0.5s;
      }

      p{
        margin: 0;
        position: relative;
      }

      &.red{
        color: white;
        .bar-inner{
          background: red;
        }
      }
      &.yellow{
        color: black;
        .bar-inner{
          background: yellow;
        }
      }
    }
  }

  :global(.battle-icon){
    --icon-size: 200px;
  }
  @media @medium{
    :global(.battle-icon){
      --icon-size: 150px;
    }
    .container{
      grid-gap: 25px;
      .vs{
        max-width: 600px;
        grid-gap: 10px;
      }
    }
  }
  @media @small{
    :global(.battle-icon){
      --icon-size: 75px;
    }
    .container{
      .vs{
        max-width: 500px;
        grid-gap: 5px;
      }
    }
  }
</style>