import { Modifier } from "./modifier";

export class Trait extends Modifier {

  constructor(itemDefinition: traitDefinition) {
    super();
    this.title = itemDefinition.title;
    this.text = itemDefinition.text;
    this.type = itemDefinition.type;
    this.icon = itemDefinition.icon;
  }
}

export type traitDefinition = {
  type: string;
  title: string;
  text: string;
  icon: string;

  class: typeof Trait;
}
