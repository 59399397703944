import { Modifier } from "../modifier";

export class Dopey extends Modifier {
  update(stacks: number, params?) {
    super.update(stacks, params);
    this.delay = true;
  }

  endTurn() {
    if (!this.delay) {
      this.removeStacks(1);
    }
  }

  startTurn(state) {
    state.rerollMax -= 1;

    /* const focus = this.owner.statusEffects.find(modifier => modifier.type === 'focus');
    if (!focus) {
      this.delay = false;
    } */

    return state;
  }
}