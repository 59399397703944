import { Modifier, type ModifierParams } from "../modifier";
import type { Player } from '../player.type';
import { actionHistory } from '../../../store';
import { openAmmo } from "../../modals/modals";

export enum AmmoType {
  Poison = "poison",
  Blood = "serrated",
  Blind = "blinding",
  Web = "crippling",
  Burning = "burning",
  Fist = "heavy",
  Silver = "silver",
  Blank = "basic"
};

export const definitions = {
  [AmmoType.Poison]: {
    text: 'Deal 1 Damage, Apply 1 Poison.',
  },
  [AmmoType.Blood]: {
    text: 'Deal 1 Damage, Apply 1 Bleed.',
  },
  [AmmoType.Blind]: {
    text: 'Deal 1 Damage, Apply 1 Blind.',
  },
  [AmmoType.Web]: {
    text: 'Deal 1 Damage, Apply 1 Snare.',
  },
  [AmmoType.Burning]: {
    text: 'Deal 1 Damage, Apply 1 Burning.',
  },
  [AmmoType.Fist]: {
    text: 'Deal 3 Damage.',
  },
  [AmmoType.Silver]: {
    text: 'Deal 4 Damage.',
  },
  [AmmoType.Blank]: {
    text: 'Deal 2 Damage.',
  }
};

export class Ammo extends Modifier {
  ammo: Array<AmmoType>;

  clone(newOwner: Player) {
    var clone = super.clone(newOwner);
    clone.ammo = [...this.ammo];

    return clone;
  }

  init(params?: ModifierParams): void {
    actionHistory.push(`Created ${this.stacks} ${this.type} on ${this.owner.title}`);

    this.ammo = [];
    for (let i = 0; i < this.stacks; i++) {
      this.ammo.push(params.ammoType);
    }
    this.bonusIcon = this.ammo[0];

    // restricting to 10 ammo
    while(this.ammo.length > 10){
      this.ammo.shift();
    }

    this.stacks = this.ammo.length;
  }

  update(stacks: number, params: any) {
    for (let i = 0; i < stacks; i++) {
      this.ammo.push(params.ammoType);
    }
    this.bonusIcon = this.ammo[0];
    this.stacks = this.ammo.length;

    actionHistory.push(`Added ${stacks} ${params.ammoType} ammo to ${this.owner.title}.`);
  }

  getAmmo(): AmmoType {
    const ammo = this.ammo.shift();
    this.stacks = this.ammo.length;
    this.bonusIcon = this.ammo[0];

    if (this.stacks <= 0) {
      this.remove();
    }
    return ammo;
  }

  onClick() {
    openAmmo({ammo: this.ammo});
  }

  getHTML(): string {
    let definition = definitions[this.ammo[0]];
    return `
      <h3> ${this.ammo[0]} </h3>
      <p> ${definition.text} </p>
    `
  }
}