<script lang="ts">
  import Icon from '../../icon/icon.svelte';
  import type { FaceDefinition } from '../../models/face.type';

  export var data: FaceDefinition;

</script>

<div class='container'>
  <div class='information'>
    <Icon name={data.icon}/>

    <div>
      <h2>{data.id}</h2>
      <h3>{data.text}</h3>
    </div>

    <div>
      {#each data.tags as tag}
        <p>{tag}</p>
      {/each}
    </div>
  </div>
</div>

<style lang='less'>
  @import "../../../defines.less";
  .container{
    border: 2px solid var(--background-colour-light);

    display: flex;
    flex-direction: row;

    .information{
      display: grid;
      grid-template-columns: 75px 1fr 100px;
      grid-gap: 20px;
      width: 100%;

      padding: 5px;
      text-align: left;

      @media @medium{
        grid-template-columns: 50px 1fr 100px;
      }
    }
  }

  h2{
    text-transform: capitalize;
  }
  h3{
    margin-bottom: 5px;
  }
</style>