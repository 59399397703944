<script lang="ts">
  import { screens, playerTruth } from '../store';
  import Die from './dice-tray/die.svelte';
  import type { Die as DieType } from './models/die.type';
  import { createDie } from "./models/die.type";
  import DiceTray from "./dice-tray/dice-tray.svelte";
  import Icon from "./icon/icon.svelte";
  import { send, receive } from '../animation';

  let playerDie: Array<DieType> = $playerTruth.dice.map((die, index) => {
    return createDie(die, index, 'p'+index);
  });

  $playerTruth.healthMax += 2;
  $playerTruth.healthCurrent += 2;
  $playerTruth.level += 1;

  let selectedDie = [];
  let upgradedDie = [];

  const clickDie = (die) => {
    var index = playerDie.indexOf(die);
    // selectedDie.push(playerDie[index]);
    if(selectedDie.length){
      playerDie.push(selectedDie[0]);
    }
    selectedDie = [die];
    playerDie.splice(index, 1);

    let level = selectedDie[0].level + 1;

    if(level == 3){
      upgradedDie[0] = createDie(`${selectedDie[0].type}>0`, 100, 'new'+Math.random());
      upgradedDie[1] = createDie(`${selectedDie[0].type}>0`, 100, 'new'+Math.random());
    }
    else{
      upgradedDie = [createDie(`${selectedDie[0].type}>${level}`, 100, 'new'+Math.random())];
    }
    playerDie = playerDie;
  }

  function confirm(){
    screens.pop();

    var index = $playerTruth.dice.indexOf(selectedDie[0].label);
    $playerTruth.dice[index] = upgradedDie[0].label;

    if(upgradedDie[1]){
      $playerTruth.dice.push(upgradedDie[1].label);
    }
  }
</script>

<div class='container'>
  <h1>Choose a die to upgrade</h1>

  <div class='upgrade'>
    <div>
      <h2>
        {#if selectedDie[0]}
          {selectedDie[0].label}
        {/if}&nbsp;
      </h2>
      <div class='die'>
        <div class='slot'></div>
        {#each selectedDie as die, index (die.id)}
          <div
            class='die-holder'
            in:receive="{{key: die.id}}"
            out:send="{{key: die.id}}">
            <Die die={die} index='0' hover={true} die3D={false}/>
          </div>
        {/each}
      </div>
    </div>

    <div class='arrow'>
      <h2>&nbsp;</h2>
      <Icon name='nextarrow' width='100%' height='100%'/>
    </div>

    <div class='transform-die'>
      <div>
        <h2>
          {#if upgradedDie[0]}
            {upgradedDie[0].label.replace('>', ' ')}
          {/if}&nbsp;
        </h2>
        <div class='die'>
          <div class='slot'>
          </div>
          {#each upgradedDie as die, index (die.id)}
            <div
              class='die-holder'
              in:receive="{{key: die.id}}"
              out:send="{{key: die.id}}">
            <Die die={die} index='0' hover={true} die3D={false}/>
          </div>
          {/each}
        </div>
      </div>

      {#if upgradedDie[1]}
        <div>
          <h2>
            {#if upgradedDie[0]}
              {upgradedDie[0].label.replace('>', ' ')}
            {/if}&nbsp;
          </h2>
          <div class='die'>
            <div class='slot'>
            </div>
            {#each upgradedDie as die, index (die.id)}
              <div
                class='die-holder'
                in:receive="{{key: die.id}}"
                out:send="{{key: die.id}}">
              <Die die={die} index='0' hover={true} die3D={false}/>
            </div>
            {/each}
          </div>
        </div>
      {/if}
    </div>
  </div>

  <DiceTray
    clickEvent={clickDie}
    dice={playerDie}
    class='horizontal'
  />

  {#if selectedDie.length}
    <button on:click={confirm}>
      Confirm Upgrade
    </button>
  {/if}
  {#if !selectedDie.length}
    <div style='height: 41px;'>&nbsp;</div>
  {/if}

</div>


<style lang='less'>
  .container{
    display: flex;
    flex-direction: column;
    grid-gap: 50px;

    .upgrade{
      display: grid;
      grid-template-columns: 1fr 75px 1fr;

      h2{
        text-align: center;
        margin-bottom: 10px;
      }

      .arrow{
        display: flex;
        flex-direction: column;
      }
      .die-holder{
        position: absolute;
      }
      .die{
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
      }

      .slot{
        border-radius: 4px;
        height: calc(var(--dice-size) + 20px);
        width: calc(var(--dice-size) + 20px);
        background: var(--background-colour);
        box-shadow: inset var(--background-colour-dark) 5px 5px 10px;
        outline: var(--background-colour-dark) 2px solid;
        display: flex;
        align-items: center;
        justify-content: center;
      }

      .transform-die{
        display: flex;
        justify-content: center;
        grid-gap: 20px;
      }
    }
  }
</style>