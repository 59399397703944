import { actionHistory } from '../../store';
import { lockedDie } from '../../store';
import { DieFace, faces as faceList } from './face.type';

export type DieCreation = {
  type: DieType;
  level: number;
  material: DieMaterial;
}

export function invokeDie(type: Array<DieFace>){
  var die = new InvokedDie(type, lockedDie.length+1, 'invoke'+Math.random());
  die.material = DieMaterial.Invoked;
  lockedDie.push(die);

  actionHistory.push(`invoked a '${type.join("+")}' die`);
}

export function createDie(dieIn: string | DieCreation, index?: number, id?: string){
  if(typeof dieIn === 'string'){
    let creation: DieCreation = {
      type: dieIn.split('>')[0] as DieType,
      level: parseInt(dieIn.split('>')[1]) || 0,
      material: DieMaterial.Normal
    }
    return new Die(creation, index, id);
  }
  return new Die(dieIn, index, id);
};

export class Die {
  id: string;
  type: DieType;
  colour: string;
  index: number;
  faces: Array<Array<DieFace>>;
  material: DieMaterial;

  used: boolean;
  // locked: boolean;
  selected: boolean;
  level: number;
  activeFace: number;

  constructor(dieIn: DieCreation, index?: number, id?: string){
    this.type = dieIn.type;
    this.level = dieIn.level;
    this.material = dieIn.material;

    this.activeFace = 5;

    this.index = index;
    this.id = id;

    this.used = false;
    // this.locked = false;
    this.selected = false;

    if(!dieCollection[this.type]){
      console.error(`No die of type: ${this.type}`);
      return;
    }

    this.colour = dieCollection[this.type].colour;
    this.faces = dieCollection[this.type].faces.slice(2+this.level, 2+this.level+6);
  }

  clone(){
    var clone = new (Object.getPrototypeOf(this)).constructor({
      type: this.type,
      level: this.level,
      material: this.material
    }, this.index, this.id);

    clone.type = this.type;
    clone.colour = this.colour;
    clone.index = this.index;
    clone.faces = this.faces;

    clone.material = this.material;

    clone.activeFace = this.activeFace;
    clone.used = this.used;
    // clone.locked = this.locked;
    clone.selected = this.selected;
    clone.id = this.id;//Math.random();

    return clone;
  }

  value(){
    return this.faces[this.activeFace];
  }

  get label(){
    return `${this.type}>${this.level}`;
  }

  setLevel(levelIn: number){
    this.level = levelIn;
    this.faces = dieCollection[this.type].faces.slice(2 + this.level, 2 + this.level + 6);
  }
}

export class InvokedDie extends Die {
  constructor(dieIn, index?: number, id?: string){
    super({
      type: DieType.Blank,
      level: 0,
      material: DieMaterial.Invoked
    }, index, id);

    this.colour = 'white';
    this.faces = new Array(6).fill(dieIn);
  }
}

export enum DieMaterial {
  Normal = 'normal',
  Stone = 'stone',
  Bone = 'bone',
  Glass = 'glass',
  Steel = 'steel',
  Shadow = 'shadow',
  Ethereal = 'ethereal',
  Invoked = 'invoked',
  // Blood = 'blood',
  // Weighted = 'weighted',
}

export enum DieType {
  Longsword = 'longsword',
  Shortsword = 'shortsword',
  Shield = 'shield',
  Bracer = 'bracer',
  Helmet = 'helmet',
  Dagger = 'dagger',
  Cloak = 'cloak',
  Flask = 'flask',
  Shivs = 'shivs',
  Javellin = 'javellin',
  Axe = 'axe',
  Hammer = 'hammer',
  Club = 'club',
  Hoof = 'hoof',
  Horn = 'horn',
  Wand = 'wand',
  Staff = 'staff',
  Tome = 'tome',
  Crystal = 'crystal',
  Fire = 'fire',
  Lightning = 'lightning',
  Ice = 'ice',
  Earth = 'earth',
  Circlet = 'circlet',
  Amulet = 'amulet',
  Fangs = 'fangs',
  Claws = 'claws',
  Bite = 'bite',
  Paw = 'paw',
  Wings = 'wings',
  Stinger = 'stinger',
  Poison = 'poison',
  Web = 'web',
  Shell = 'shell',
  Pincer = 'pincer',
  Beak = 'beak',
  Bow = 'bow',
  Crossbow = 'crossbow',
  Blowgun = 'blowgun',
  Quiver = 'quiver',
  AmmoBag = 'ammo bag',
  Bandolier = 'bandolier',
  Case = 'case',
  Blank = 'blank',
  Test = 'test',
  Pipes = 'pipes',
  Spear = 'spear',
  Net = 'net',
  LuckyCoin = 'lucky coin',
  Mana = 'mana',
  Arcana = 'arcana',
  Intelligence = 'intelligence',
  Reflex = 'reflex',
  Speed = 'speed',
  Strength = 'strength',
  Resilience = 'resilience',
  Blood = 'blood',
  Stealth = 'stealth',
  Talented = 'talented',
  Rhythmic = 'rhythmic',
  Tentacle = 'tentacle',
  Tail = 'tail',
}

type DieDefinition = {
  colour: string;
  faces: Array<Array<DieFace>>;
}

interface deff {
  [key: string]: DieDefinition
}

export const dieCollection: deff = {
  [DieType.Longsword]: {
    colour: 'red',
    faces: [[DieFace.Sword, DieFace.Sword], [DieFace.Sword, DieFace.Sword], [DieFace.Sword], [DieFace.Sword], [DieFace.Fist], [DieFace.Fist], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Shortsword]: {
    colour: 'red',
    faces: [[DieFace.Sword, DieFace.Sword], [DieFace.Sword, DieFace.Agility], [DieFace.Sword], [DieFace.Sword], [DieFace.Agility], [DieFace.Agility], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Shield]: {
    colour: 'blue',
    faces: [[DieFace.Shield, DieFace.Shield] , [DieFace.Bone, DieFace.Bone], [DieFace.Shield], [DieFace.Shield], [DieFace.Bone], [DieFace.Bone], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Bracer]: {
    colour: 'blue',
    faces: [[DieFace.Shield, DieFace.Shield], [DieFace.Fist, DieFace.Fist], [DieFace.Shield], [DieFace.Shield], [DieFace.Fist], [DieFace.Fist], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Helmet]: {
    colour: 'yellow',
    faces: [[DieFace.Wildcard], [DieFace.Wildcard], [DieFace.Shield], [DieFace.Shield], [DieFace.Brain], [DieFace.Brain], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },

  [DieType.Dagger]: {
    colour: 'yellow',
    faces: [[DieFace.Dagger, DieFace.Dagger], [DieFace.Dagger, DieFace.Dagger], [DieFace.Dagger], [DieFace.Dagger], [DieFace.Fist], [DieFace.Fist], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Cloak]: {
    colour: 'purple',
    faces: [[DieFace.Cloak, DieFace.Cloak], [DieFace.Cloak, DieFace.Cloak], [DieFace.Cloak], [DieFace.Cloak], [DieFace.Agility], [DieFace.Agility], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Flask]: {
    colour: 'pink',
    faces: [[DieFace.Poison, DieFace.OR, DieFace.Blood], [DieFace.Poison, DieFace.OR, DieFace.Blood], [DieFace.Poison], [DieFace.Poison], [DieFace.Blood], [DieFace.Blood], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Shivs]: {
    colour: 'purple',
    faces: [[DieFace.Shiv, DieFace.Shiv], [DieFace.Shiv, DieFace.Shiv], [DieFace.Shiv], [DieFace.Shiv], [DieFace.Blank], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Javellin]: {
    colour: 'purple',
    faces: [[DieFace.Spear, DieFace.Fist], [DieFace.Spear, DieFace.Fist], [DieFace.Spear], [DieFace.Spear], [DieFace.Dart], [DieFace.Dart], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },

  [DieType.Axe]: {
    colour: 'red',
    faces: [[DieFace.Axe, DieFace.Axe], [DieFace.Axe, DieFace.Axe], [DieFace.Axe], [DieFace.Axe], [DieFace.Fist], [DieFace.Fist], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Hammer]: {
    colour: 'red',
    faces: [[DieFace.Hammer, DieFace.Hammer], [DieFace.Hammer, DieFace.Fist], [DieFace.Hammer], [DieFace.Hammer], [DieFace.Fist], [DieFace.Fist], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Club]: {
    colour: 'red',
    faces: [[DieFace.Hammer, DieFace.Hammer], [DieFace.Hammer, DieFace.Agility], [DieFace.Hammer], [DieFace.Hammer], [DieFace.Agility], [DieFace.Agility], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Hoof]: {
    colour: 'green',
    faces: [[DieFace.Hoof, DieFace.Hoof], [DieFace.Hoof, DieFace.Hoof], [DieFace.Hoof], [DieFace.Hoof], [DieFace.Agility], [DieFace.Agility], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Horn]: {
    colour: 'blue',
    faces: [[DieFace.Horn, DieFace.Horn], [DieFace.Horn, DieFace.Horn], [DieFace.Horn], [DieFace.Horn], [DieFace.Bone], [DieFace.Bone], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },

  [DieType.Wand]: {
    colour: 'red',
    faces: [[DieFace.Fist, DieFace.Fire], [DieFace.Fist, DieFace.OR, DieFace.Fire], [DieFace.Fire], [DieFace.Fire], [DieFace.Fist], [DieFace.Fist], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Staff]: {
    colour: 'yellow',
    faces: [[DieFace.Reflex, DieFace.Lightning], [DieFace.Reflex, DieFace.OR, DieFace.Lightning], [DieFace.Lightning], [DieFace.Lightning], [DieFace.Reflex], [DieFace.Reflex], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Tome]: {
    colour: 'cyan',
    faces: [[DieFace.Brain, DieFace.Ice], [DieFace.Brain, DieFace.OR, DieFace.Ice], [DieFace.Ice], [DieFace.Ice], [DieFace.Brain], [DieFace.Brain], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Crystal]: {
    colour: 'green',
    faces: [[DieFace.Bone, DieFace.Earth], [DieFace.Bone, DieFace.OR, DieFace.Earth], [DieFace.Earth], [DieFace.Earth], [DieFace.Bone], [DieFace.Bone], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },

  [DieType.Fire]: {
    colour: 'red',
    faces: [[DieFace.Mana, DieFace.Fire], [DieFace.Mana, DieFace.OR, DieFace.Fire], [DieFace.Fire], [DieFace.Fire], [DieFace.Mana], [DieFace.Mana], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Lightning]: {
    colour: 'yellow',
    faces: [[DieFace.Mana, DieFace.Lightning], [DieFace.Mana, DieFace.OR, DieFace.Lightning], [DieFace.Lightning], [DieFace.Lightning], [DieFace.Mana], [DieFace.Mana], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Ice]: {
    colour: 'cyan',
    faces: [[DieFace.Mana, DieFace.Ice], [DieFace.Mana, DieFace.OR, DieFace.Ice], [DieFace.Ice], [DieFace.Ice], [DieFace.Mana], [DieFace.Mana], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Earth]: {
    colour: 'green',
    faces: [[DieFace.Mana, DieFace.Earth], [DieFace.Mana, DieFace.OR, DieFace.Earth], [DieFace.Earth], [DieFace.Earth], [DieFace.Mana], [DieFace.Mana], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },

  [DieType.Circlet]: {
    colour: 'green',
    faces: [[DieFace.Wildcard], [DieFace.Wildcard], [DieFace.Mana], [DieFace.Mana], [DieFace.Brain], [DieFace.Brain], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Amulet]: {
    colour: 'purple',
    faces: [[DieFace.Fire, DieFace.OR, DieFace.Ice], [DieFace.Earth, DieFace.OR, DieFace.Lightning], [DieFace.Fire], [DieFace.Ice], [DieFace.Lightning], [DieFace.Earth], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },

  [DieType.Fangs]: {
    colour: 'red',
    faces: [[DieFace.Bite, DieFace.Blood], [DieFace.Bite, DieFace.OR, DieFace.Blood], [DieFace.Bite], [DieFace.Bite], [DieFace.Blood], [DieFace.Blood], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Claws]: {
    colour: 'red',
    faces: [[DieFace.Claws, DieFace.Claws], [DieFace.Claws, DieFace.Claws], [DieFace.Claws], [DieFace.Claws], [DieFace.Blood], [DieFace.Blood], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },

  [DieType.Bite]: {
    colour: 'red',
    faces: [[DieFace.Bite, DieFace.Bite], [DieFace.Bite, DieFace.Bite], [DieFace.Bite], [DieFace.Bite], [DieFace.Note], [DieFace.Note], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Paw]: {
    colour: 'yellow',
    faces: [[DieFace.Claws, DieFace.Claws], [DieFace.Claws, DieFace.Claws], [DieFace.Claws], [DieFace.Claws], [DieFace.Agility], [DieFace.Agility], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },

  [DieType.Wings]: {
    colour: 'purple',
    faces: [[DieFace.Wings, DieFace.Wings], [DieFace.Wings, DieFace.Wings], [DieFace.Wings], [DieFace.Wings], [DieFace.Agility], [DieFace.Agility], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Stinger]: {
    colour: 'yellow',
    faces: [[DieFace.Stinger, DieFace.Stinger], [DieFace.Stinger, DieFace.Stinger], [DieFace.Stinger], [DieFace.Stinger], [DieFace.Poison], [DieFace.Poison], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Poison]: {
    colour: 'green',
    faces: [[DieFace.Poison, DieFace.Poison], [DieFace.Poison, DieFace.Poison], [DieFace.Poison], [DieFace.Poison], [DieFace.Poison], [DieFace.Poison], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Web]: {
    colour: 'purple',
    faces: [[DieFace.Web, DieFace.Web], [DieFace.Web, DieFace.Web], [DieFace.Web], [DieFace.Web], [DieFace.Agility], [DieFace.Agility], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Shell]: {
    colour: 'blue',
    faces: [[DieFace.Shell, DieFace.Shell], [DieFace.Shell, DieFace.Shell], [DieFace.Shell], [DieFace.Shell], [DieFace.Bone], [DieFace.Bone], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Pincer]: {
    colour: 'red',
    faces: [[DieFace.Pincer, DieFace.Pincer], [DieFace.Pincer, DieFace.Pincer], [DieFace.Pincer], [DieFace.Pincer], [DieFace.Fist], [DieFace.Fist], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Beak]: {
    colour: 'red',
    faces: [[DieFace.Beak, DieFace.Beak], [DieFace.Beak, DieFace.Note], [DieFace.Beak], [DieFace.Beak], [DieFace.Note], [DieFace.Note], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },

  [DieType.Bow]: {
    colour: 'red',
    faces: [[DieFace.Bow, DieFace.Bow], [DieFace.Bow, DieFace.Ammo], [DieFace.Bow], [DieFace.Bow], [DieFace.Bow], [DieFace.Ammo], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Crossbow]: {
    colour: 'red',
    faces: [[DieFace.Crossbow, DieFace.Crossbow], [DieFace.Crossbow, DieFace.Shield], [DieFace.Crossbow], [DieFace.Crossbow], [DieFace.Shield], [DieFace.Shield], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Blowgun]: {
    colour: 'red',
    faces: [[DieFace.Blowgun, DieFace.Blowgun], [DieFace.Blowgun, DieFace.Poison], [DieFace.Blowgun], [DieFace.Blowgun], [DieFace.Poison], [DieFace.Poison], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Quiver]: {
    colour: 'purple',
    faces: [[DieFace.Ammo, DieFace.Ammo], [DieFace.Ammo, DieFace.Ammo], [DieFace.Ammo], [DieFace.Ammo], [DieFace.Eye], [DieFace.Eye], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.AmmoBag]: {
    colour: 'purple',
    faces: [[DieFace.Ammo, DieFace.Poison], [DieFace.Ammo, DieFace.Blood], [DieFace.Ammo, DieFace.OR, DieFace.Poison], [DieFace.Ammo, DieFace.OR, DieFace.Blood], [DieFace.Ammo], [DieFace.Ammo], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Bandolier]: {
    colour: 'purple',
    faces: [[DieFace.Ammo, DieFace.Eye], [DieFace.Ammo, DieFace.Eye], [DieFace.Dart], [DieFace.Dart], [DieFace.Ammo], [DieFace.Ammo], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Case]: {
    colour: 'purple',
    faces: [[DieFace.Ammo, DieFace.Fist], [DieFace.Ammo, DieFace.Fist], [DieFace.Fist], [DieFace.Fist], [DieFace.Ammo], [DieFace.Ammo], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Blank]: {
    colour: 'green',
    faces: [[DieFace.Blank], [DieFace.Blank], [DieFace.Blank], [DieFace.Blank], [DieFace.Blank], [DieFace.Blank], [DieFace.Blank], [DieFace.Blank], [DieFace.Blank], [DieFace.Blank]]
  },

  [DieType.Pipes]: {
    colour: 'red',
    faces: [[DieFace.Blowgun, DieFace.Blowgun], [DieFace.Blowgun, DieFace.Note], [DieFace.Blowgun], [DieFace.Blowgun], [DieFace.Note], [DieFace.Note], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },


  [DieType.Spear]: {
    colour: 'red',
    faces: [[DieFace.Spear, DieFace.Spear], [DieFace.Spear, DieFace.Spear], [DieFace.Spear], [DieFace.Spear], [DieFace.Fist], [DieFace.Fist], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Net]: {
    colour: 'yellow',
    faces: [[DieFace.Web, DieFace.Web], [DieFace.Web, DieFace.OR, DieFace.Fist], [DieFace.Web], [DieFace.Web], [DieFace.Fist], [DieFace.Fist], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },

  [DieType.LuckyCoin]: {
    colour: 'yellow',
    faces: [[DieFace.Wildcard], [DieFace.Wildcard], [DieFace.Wildcard], [DieFace.Broken], [DieFace.Broken], [DieFace.Broken], [DieFace.Broken], [DieFace.Broken], [DieFace.Broken], [DieFace.Broken]]
  },


  [DieType.Mana]: {
    colour: 'blue',
    faces: [[DieFace.Mana, DieFace.Mana], [DieFace.Mana, DieFace.Mana], [DieFace.Mana], [DieFace.Mana], [DieFace.Mana], [DieFace.Mana], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Arcana]: {
    colour: 'blue',
    faces: [[DieFace.Mana, DieFace.Brain], [DieFace.Mana, DieFace.Brain], [DieFace.Mana], [DieFace.Mana], [DieFace.Brain], [DieFace.Brain], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Intelligence]: {
    colour: 'blue',
    faces: [[DieFace.Wildcard], [DieFace.Wildcard], [DieFace.Brain], [DieFace.Brain], [DieFace.Bone], [DieFace.Bone], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Reflex]: {
    colour: 'green',
    faces: [[DieFace.Eye, DieFace.Reflex], [DieFace.Eye, DieFace.OR, DieFace.Reflex], [DieFace.Eye], [DieFace.Eye], [DieFace.Reflex], [DieFace.Reflex], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Speed]: {
    colour: 'green',
    faces: [[DieFace.Agility, DieFace.Agility], [DieFace.Reflex, DieFace.Reflex], [DieFace.Agility], [DieFace.Agility], [DieFace.Reflex], [DieFace.Reflex], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Strength]: {
    colour: 'purple',
    faces: [[DieFace.Fist, DieFace.Bone], [DieFace.Fist, DieFace.OR, DieFace.Bone], [DieFace.Fist], [DieFace.Fist], [DieFace.Bone], [DieFace.Bone], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Resilience]: {
    colour: 'purple',
    faces: [[DieFace.Bone, DieFace.Blood], [DieFace.Bone, DieFace.OR, DieFace.Blood], [DieFace.Bone], [DieFace.Bone], [DieFace.Blood], [DieFace.Blood], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Blood]: {
    colour: 'red',
    faces: [[DieFace.Blood, DieFace.Blood], [DieFace.Blood, DieFace.Blood], [DieFace.Blood], [DieFace.Blood], [DieFace.Fist], [DieFace.Fist], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Stealth]: {
    colour: 'purple',
    faces: [[DieFace.Cloak, DieFace.Eye], [DieFace.Cloak, DieFace.OR, DieFace.Eye], [DieFace.Cloak], [DieFace.Cloak], [DieFace.Eye], [DieFace.Eye], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Talented]: {
    colour: 'green',
    faces: [[DieFace.Eye, DieFace.OR, DieFace.Agility], [DieFace.Bone, DieFace.OR, DieFace.Fist], [DieFace.Eye], [DieFace.Agility], [DieFace.Bone], [DieFace.Fist], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Rhythmic]: {
    colour: 'green',
    faces: [[DieFace.Note, DieFace.Note], [DieFace.Note, DieFace.Note], [DieFace.Note], [DieFace.Note], [DieFace.Reflex], [DieFace.Reflex], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Tentacle]: {
    colour: 'purple',
    faces: [[DieFace.Prehensile, DieFace.Prehensile], [DieFace.Prehensile, DieFace.Fist], [DieFace.Prehensile], [DieFace.Prehensile], [DieFace.Fist], [DieFace.Fist], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
  [DieType.Tail]: {
    colour: 'purple',
    faces: [[DieFace.Prehensile, DieFace.Prehensile], [DieFace.Prehensile, DieFace.Reflex], [DieFace.Prehensile], [DieFace.Prehensile], [DieFace.Reflex], [DieFace.Reflex], [DieFace.Blank], [DieFace.Blank], [DieFace.Broken], [DieFace.Broken]]
  },
}

const parseDice = () => {
  Object.keys(dieCollection).forEach(key => {
    dieCollection[key].faces.reverse();
  });
}
parseDice();