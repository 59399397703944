import type { itemDefinition } from '../item.type';
import { type Player, healDamage, hasItem, removeItem, addModifier } from "../player.type";
import { invokeDie } from "../die.type";
import type { Technique } from '../techniques.type';
import { Item, Items } from '../item.type';
import type { Die } from '../die.type';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';

export const potions: Array<itemDefinition> = [
  {
    type: Items.PotionHealth,
    title: 'Health Potion',
    text: 'Consume in battle to Heal 5 Damage.',
    icon: 'potionsmall',
    bonusIcon: 'regeneration',
    class: class extends Item {
      getTechniques(): Array<Technique> {
        return [{
          requirements: [
            {
              text: 'Consumes Potion',
              icon: 'potionsmall',
              test: (source: Player) => {
                return hasItem(source, Items.PotionHealth);
              }
            }
          ],
          ingredients: [],
          title: 'Quaff Health Potion',
          text: 'Heal 5 Damage. Consume Potion.',
          tags: ['consumable', 'heal'],
          activate: (target: Player, source: Player, dice: Array<Die>) => {
            healDamage({ value: 5, target: source, source, dice});
            removeItem(source, Items.PotionHealth);
          }
        }];
      }
    }
  }, {
    type: Items.PotionMana,
    title: 'Mana Potion',
    text: 'Consume in battle to Invoke 2 Mana.',
    icon: 'potionsmall',
    bonusIcon: 'mana',
    class: class extends Item {
      getTechniques(): Array<Technique> {
        return [{
          requirements: [
            {
              text: 'Consumes Potion',
              icon: 'potionsmall',
              test: (source: Player) => {
                return hasItem(source, Items.PotionMana);
              }
            }
          ],
          ingredients: [],
          title: 'Quaff Mana Potion',
          text: 'Invoke 2 Mana. Consume Potion.',
          tags: ['consumable', 'buff'],
          activate: (target: Player, source: Player, dice: Array<Die>) => {
            healDamage({ value: 5, target: source, source, dice });
            removeItem(source, Items.PotionMana);
          }
        }];
      }
    }
  }, {
    type: Items.PotionClarity,
    title: 'Clarity Potion',
    text: 'Consume in battle to Invoke 2 Brain and Gain 1 Witty.',
    icon: 'potionsmall',
    bonusIcon: 'brain',
    class: class extends Item {
      getTechniques(): Array<Technique> {
        return [{
          requirements: [
            {
              text: 'Consumes Potion',
              icon: 'potionsmall',
              test: (source: Player) => {
                return hasItem(source, Items.PotionClarity);
              }
            }
          ],
          ingredients: [],
          title: 'Quaff Clarity Potion',
          text: 'Invoke Brain and Gain 1 Witty.',
          tags: ['consumable', 'buff'],
          activate: (target: Player, source: Player, dice: Array<Die>) => {
            invokeDie([DieFace.Brain]);
            invokeDie([DieFace.Brain]);
            addModifier(source, StatusEffect.Witty, 1);

            removeItem(source, Items.PotionClarity);
          }
        }];
      }
    }
  }, {
    type: Items.PotionStrength,
    title: 'Strength Potion',
    text: 'Consume in battle to Invoke 2 Fist and Gain 1 Strengthen.',
    icon: 'potionsmall',
    bonusIcon: 'fist',
    class: class extends Item {
      getTechniques(): Array<Technique> {
        return [{
          requirements: [
            {
              text: 'Consumes Potion',
              icon: 'potionsmall',
              test: (source: Player) => {
                return hasItem(source, Items.PotionStrength);
              }
            }
          ],
          ingredients: [],
          title: 'Quaff Strength Potion',
          text: 'Invoke 2 Fist and Gain 1 Strengthen.',
          tags: ['consumable', 'buff'],
          activate: (target: Player, source: Player, dice: Array<Die>) => {
            invokeDie([DieFace.Fist]);
            invokeDie([DieFace.Fist]);
            addModifier(source, StatusEffect.Strength, 1);

            removeItem(source, Items.PotionStrength);
          }
        }];
      }
    }
  }
];