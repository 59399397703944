import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';


export const horn: Technique[] = [
  {
    ingredients: [DieFace.Horn],
    title: 'Bunt',
    text: 'Deal 1 Damage.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Horn, DieFace.Horn],
    title: 'Ram',
    text: 'Deal 2 Damage, Apply 1 Wound.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, StatusEffect.Wound, 1);
    }
  },  {
    ingredients: [DieFace.Horn, DieFace.Horn, DieFace.Horn],
    title: 'Gore',
    text: 'Deal 4 damage, Apply 2 Wound.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 4, target, source, dice });
      addModifier(target, StatusEffect.Wound, 2);
    }
  }, {
    ingredients: [DieFace.Horn, DieFace.Hoof],
    title: 'Bull Rush',
    text: 'Deal 6 damage, Gain 1 stunned.',
    tags: ['damage', 'suffer'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 6, target, source, dice });
      addModifier(source, StatusEffect.Stunned, 1);
    }
  },
];
