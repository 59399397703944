<script lang='ts'>
  import DieIcon from './die-icon.svelte';
  import { onMount } from 'svelte';

  export let die;
  export let mouseover: boolean;
  let hoverContainer;
  let marginOffset: string = '0';

  let title = die.type + ' ' + (die.level == 0 ? '' : die.level);
  $: if (die !== '') {
    title = die.type + ' ' + (die.level == 0 ? '' : die.level);
  }

  $: if (mouseover !== null) {
    calculateOffset();
  }

  let calculateOffset = () => {
    // if hover container's calculated left position is less than 0, set it to 0
    let left = 0;
    let right = 0;

    if(hoverContainer){
      const rect = hoverContainer.getBoundingClientRect();
      const offset = parseInt(marginOffset);
      if(rect.left - offset < 0){
        left = rect.x - 5 - offset;
      }
      else if(rect.right- offset > window.innerWidth){
        right = rect.x + rect.width + 5 - offset;
      }

      if(left < 0){
        marginOffset = -left + 'px';
      }
      else if(right > window.innerWidth){
        marginOffset = window.innerWidth - right + 'px';
      }
    }
    if(!marginOffset){
      marginOffset = 'inherit';
    }
  }

  // in svelte style, when resize, calculate offset
  onMount(() => {
		window.addEventListener('resize', calculateOffset);

		return () => {
			window.removeEventListener('resize', calculateOffset);
		}
	});
</script>

<!-- <div class='shadow {mouseover == "true" ? "hovering":""}'></div> -->

<div class='hover-container {mouseover == true ? "hovering" : ""}'>
  <div class='hover' bind:this="{hoverContainer}" style="margin-left: {marginOffset}">
    <h1>{title}</h1>
    <div class='die-hover-container'>
      <div class='die {die.colour} {die.activeFace == 0 ? "selected" : ""}'>
        <div class='die-2d face'>
          <div class='face-inner'></div>
          <DieIcon face={die.faces[0]} sizeMod={1}/>
        </div>
      </div>
      <div class='die {die.colour} {die.activeFace == 1 ? "selected" : ""}'>
        <div class='die-2d face'>
          <div class='face-inner'></div>
          <DieIcon face={die.faces[1]} sizeMod={1}/>
        </div>
      </div>
      <div class='die {die.colour} {die.activeFace == 2 ? "selected" : ""}'>
        <div class='die-2d face'>
          <div class='face-inner'></div>
          <DieIcon face={die.faces[2]} sizeMod={1}/>
        </div>
      </div>
      <div class='die {die.colour} {die.activeFace == 3 ? "selected" : ""}'>
        <div class='die-2d face'>
          <div class='face-inner'></div>
          <DieIcon face={die.faces[3]} sizeMod={1}/>
        </div>
      </div>
      <div class='die {die.colour} {die.activeFace == 4 ? "selected" : ""}'>
        <div class='die-2d face'>
          <div class='face-inner'></div>
          <DieIcon face={die.faces[4]} sizeMod={1}/>
        </div>
      </div>
      <div class='die {die.colour} {die.activeFace == 5 ? "selected" : ""}'>
        <div class='die-2d face'>
          <div class='face-inner'></div>
          <DieIcon face={die.faces[5]} sizeMod={1}/>
        </div>
      </div>
    </div>
  </div>
</div>

<style lang='less'>
  @import "../../defines.less";
  .shadow{
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    pointer-events: none;
    background: rgba(0,0,0,0.2);
    opacity: 0;
    z-index: 11;
    transition: opacity 0.3s;

    &.hovering{
      opacity: 1;
      transition-delay: 0.3s;
    }
  }

  .die-2d{
    /*border-width: 3px;*/
    /*border-style: outset;*/
    min-width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 2px;

    // background-image: url('../../assets/die.png');
    // background-size: cover;
    // background-position: center;
  }

  .hover-container{
    position: fixed;
    z-index: 111;
    height: 0;
    opacity: 0;
    transition: opacity 0.3s;
    pointer-events: none ;
    margin-left: calc(var(--dice-size) / 2 - (335px / 2));

    @media @large{
      margin-left: calc(var(--dice-size) / 2 - (310px / 2));
    }
    @media @medium{
      margin-left: calc(var(--dice-size) / 2 - (280px / 2));
    }
    @media @small{
      margin-left: calc(var(--dice-size) / 2 - (250px / 2));
    }

    .hover{
      position: relative;
      height: 0;
      overflow: hidden;
      z-index: 11;
      background-color: var(--background-colour);
      border-radius: 4px;
      display: flex;
      flex-direction: column;
      transition: width 0.3s, height 0.3s, padding 0.3s;
      outline: 1px solid black;

      width: 325px;
      padding: 5px;
      transition-delay: 0.3s;

      @media @large{
        width: 300px;
      }
      @media @medium{
        width: 270px;
      }
      @media @small{
        width: 240px;
      }

      h1{
        font-size: 20px;
        color: white;
        text-transform: capitalize;
        margin-bottom: 5px;
      }

      .die-hover-container{
        display: flex;
        grid-gap: var(--dice-gap);

        .die{
          width: calc(var(--dice-size) / 2 + 10px);
          height: calc(var(--dice-size) / 2 + 10px);
          position: relative;
        }
      }
    }

    &.hovering{
      display: flex;
      opacity: 1;
      transition-delay: 0.3s;

      .hover{
        height: 77px;
        padding: 5px;
        transition-delay: 0.3s;

        @media @medium{
          height: 70px;
        }
        @media @small{
          height: 65px;
        }
      }
    }
  }
</style>