import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Die } from '../die.type';
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import { getAmmoEffect, hasAmmo, shootAmmo } from './ammo';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';

export const blowgun: Technique[] = [
  {
    requirements: [hasAmmo],
    ingredients: [DieFace.Blowgun],
    title: 'Shoot dart',
    text: 'Shoot one stored Ammo, add +1 to it\'s status effect stack.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const effect = getAmmoEffect(target, source, dice);
      effect.statusEffects.forEach(modifier => {
        modifier.stacks += 1;
      });
      shootAmmo(effect, target);
    }
  }, {
    requirements: [hasAmmo],
    ingredients: [DieFace.Blowgun, DieFace.Blowgun],
    title: 'Dosed dart',
    text: 'Shoot one stored Ammo, add +3 to it\'s status effect stack.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const effect = getAmmoEffect(target, source, dice);
      effect.statusEffects.forEach(modifier => {
        modifier.stacks += 3;
      });
      shootAmmo(effect, target);
    }
  }, {
    requirements: [hasAmmo],
    ingredients: [DieFace.Blowgun, DieFace.Blowgun, DieFace.Blowgun],
    title: 'Extremely dosed dart',
    text: 'Shoot one stored Ammo, add +5 to it\'s status effect stack.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const effect = getAmmoEffect(target, source, dice);
      effect.statusEffects.forEach(modifier => {
        modifier.stacks += 5;
      });
      shootAmmo(effect, target);
    }
  }, {
    requirements: [hasAmmo],
    ingredients: [DieFace.Blowgun, DieFace.Poison],
    title: 'Improvised dart',
    text: 'Deal 1 damage, Apply 2 poison.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Poison, 2);
    }
  }, {
    requirements: [hasAmmo],
    ingredients: [DieFace.Blowgun, DieFace.Cloak],
    title: 'Cloaked dart',
    text: 'Gain 1 stealth, shoot one stored ammo.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Stealth, 1);
      const effect = getAmmoEffect(target, source, dice);
      shootAmmo(effect, target);
    }
  }, {
    requirements: [hasAmmo],
    ingredients: [DieFace.Blowgun, DieFace.Agility],
    title: 'Run and Gun',
    text: 'Shoot one stored ammo, gain 2 dodge.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const effect = getAmmoEffect(target, source, dice);
      shootAmmo(effect, target);
      addModifier(source, StatusEffect.Dodge, 2);
    }
  }, /* {
    requirements: [hasAmmo],
    ingredients: [DieFace.Blowgun, DieFace.Note],
    title: 'Run and Gun',
    text: 'Shoot one stored ammo, gain 2 dodge.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const effect = getAmmoEffect(target, source, dice);
      shootAmmo(effect, target);
      addModifier(source, 'dodge', 2);
    }
  }, */
];
