import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import type { Ammo } from '../status-effects/ammo';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';

export enum AmmoType {
  Poison = "poison",
  Blood = "serrated",
  Blind = "blinding",
  Web = "crippling",
  Burning = "burning",
  Fist = "heavy",
  Silver = "silver",
  Blank = "basic"
};

export interface AmmoOutput {
  ammoType: AmmoType;
  damages: Array<Damage>;
  statusEffects: Array<{type: string, stacks: number}>;
}

export const getAmmoEffect = (target: Player, source: Player, dice: Array<Die>) => {
  const ammo: Ammo = source.statusEffects.find(modifier => {
    return modifier.type == 'ammo';
  }) as Ammo;

  let type: AmmoType = ammo.getAmmo();
  if(type === undefined){
    return;
  }

  let output: AmmoOutput = {
    ammoType: type,
    damages: [],
    statusEffects: []
  };

  switch(type){
    case AmmoType.Poison:
      output.damages.push({ value: 1, target, source, dice });
      output.statusEffects.push({type: 'poisoned', stacks: 1});
      break;

    case AmmoType.Blood:
      output.damages.push({ value: 1, target, source, dice });
      output.statusEffects.push({type: 'bleed', stacks: 1});
      break;

    case AmmoType.Blind:
      output.damages.push({ value: 1, target, source, dice });
      output.statusEffects.push({type: 'blind', stacks: 1});
      break;

    case AmmoType.Web:
      output.damages.push({ value: 1, target, source, dice });
      output.statusEffects.push({type: 'snared', stacks: 1});
      break;

    case AmmoType.Burning:
      output.damages.push({ value: 1, target, source, dice });
      output.statusEffects.push({type: 'burning', stacks: 1});
      break;

    case AmmoType.Fist:
      output.damages.push({ value: 3, target, source, dice });
      break;

    case AmmoType.Silver:
      output.damages.push({ value: 4, target, source, dice });
      break;

    case AmmoType.Blank:
    default:
      // dealDamage({value: 2, target, source, dice});

      output.damages.push({ value: 2, target, source, dice });
      break;
  }

  return output;
}

export function shootAmmo(ammo: AmmoOutput, target: Player){
  if (ammo) {
    ammo.damages.forEach(damage => {
      dealDamage(damage);
    });
    ammo.statusEffects.forEach(statusEffect => {
      addModifier(target, statusEffect.type as StatusEffect, statusEffect.stacks);
    });
  }
}

export const hasAmmo: Requirement = {
  text: 'Requires Ammo',
  // iconBonus: '',
  icon: 'ammo',
  test: (source: Player, target: Player) => {
    const ammo = source.statusEffects.find(modifier => {
      return modifier.type == 'ammo';
    });
    return !!ammo;
  }
}

export const ammo: Technique[] = [
  {
    ingredients: [DieFace.Ammo],
    title: 'Rearm',
    text: 'Gain 1 Ammo (Basic).',
    tags: ['buff', 'ammo'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Ammo, 1, {ammoType: AmmoType.Blank});
    }
  },{
    ingredients: [DieFace.Ammo, DieFace.Ammo],
    title: 'Restock',
    text: 'Gain 3 Ammo (Basic).',
    tags: ['buff', 'ammo'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Ammo, 3, {ammoType: AmmoType.Blank});
    }
  },{
    ingredients: [DieFace.Ammo, DieFace.Ammo, DieFace.Ammo],
    title: 'Refill',
    text: 'Gain 7 Ammo (Basic).',
    tags: ['buff', 'ammo'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Ammo, 7, {ammoType: AmmoType.Blank});
    }
  },{
    ingredients: [DieFace.Ammo, DieFace.Poison],
    title: 'Poisoned Ammo.',
    text: 'Gain 2 Ammo (Poison).',
    tags: ['buff', 'ammo'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Ammo, 2, {ammoType: AmmoType.Poison});
    }
  },{
    ingredients: [DieFace.Ammo, DieFace.Blood],
    title: 'Serated Ammo.',
    text: 'Gain 2 Ammo (Bleeding).',
    tags: ['buff', 'ammo'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Ammo, 2, {ammoType: AmmoType.Blood});
    }
  },{
    ingredients: [DieFace.Ammo, DieFace.Eye],
    title: 'Blinding Ammo.',
    text: 'Gain 2 Ammo (Blinding).',
    tags: ['buff', 'ammo'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Ammo, 2, {ammoType: AmmoType.Blind});
    }
  },{
    ingredients: [DieFace.Ammo, DieFace.Agility],
    title: 'Crippling Ammo.',
    text: 'Gain 2 Ammo (Crippling).',
    tags: ['buff', 'ammo'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Ammo, 2, {ammoType: AmmoType.Web});
    }
  },{
    ingredients: [DieFace.Ammo, DieFace.Fire],
    title: 'Burning Ammo.',
    text: 'Gain 2 Ammo (Burning).',
    tags: ['buff', 'ammo'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Ammo, 2, {ammoType: AmmoType.Burning});
    }
  },{
    ingredients: [DieFace.Ammo, DieFace.Fist],
    title: 'Heavy Ammo.',
    text: 'Gain 2 Ammo (Heavy).',
    tags: ['buff', 'ammo'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Ammo, 2, {ammoType: AmmoType.Fist});
    }
  }
];
