import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import type { Infusion } from '../status-effects/infusion';
import { DieFace } from '../face.type';
import { StatusEffect } from '../status-effects';

export const earth: Technique[] = [
  {
    ingredients: [DieFace.Earth],
    requirements: [{
      text: 'No Infusion other than earth',
      iconBonus: 'no',
      icon: 'infusion',
      test: (source: Player, target: Player) => {
        const infusion = source.statusEffects.find((modifier: Infusion) => {
          return modifier.type == 'infusion' && modifier.infusion != 'earth';
        });
        return !infusion;
      }
    }],
    title: 'Elemental Infusion (earth)',
    text: 'Gain 1 Infusion (Earth).',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Infusion, 1, { infusion: DieFace.Earth });
    }
  }, {
    ingredients: [DieFace.Earth, DieFace.Earth],
    title: 'Unstable Boulder Toss',
    text: 'Deal 3 damage, Apply 3 Brittle. Take 2 damage, Gain 1 Brittle.',
    tags: ['damage', 'suffer'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
      addModifier(target, StatusEffect.Brittle, 3);
      addModifier(target, StatusEffect.Petrified, 3);

      dealDamage({ value: 2, target: source, source, dice });
      addModifier(source, StatusEffect.Brittle, 1);
      addModifier(source, StatusEffect.Petrified, 1);
    }
  }, {
    ingredients: [DieFace.Earth, DieFace.Earth, DieFace.Mana],
    title: 'Focused Boulder Toss',
    text: 'Deal 3 damage, Apply 3 Brittle and 1 Petrified.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
      addModifier(target, StatusEffect.Brittle, 3);
      addModifier(target, StatusEffect.Petrified, 1);
    }
  }, {
    ingredients: [DieFace.Earth, DieFace.Mana],
    title: 'Stone\'s Throw',
    text: 'Deal 1 damage, Apply 2 Brittle.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Brittle, 2);
    }
  }, {
    ingredients: [DieFace.Earth, DieFace.Brain],
    title: 'Stone Skin',
    text: 'Convert Block to Barrier, Gain 2 Barrier.',
    tags: ['defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const block = source.statusEffects.find(modifier => {
        return modifier.type == 'block';
      });

      if (block){
        let stacks = block.stacks;
        if (stacks > 0) {
          block.removeStacks(stacks);
          addModifier(source, StatusEffect.Barrier, stacks);
        }
      }
      addModifier(source, StatusEffect.Barrier, 2);
    }
  }, {
    ingredients: [DieFace.Earth, DieFace.Bone],
    title: 'Stone Wall',
    text: 'Gain 2 Block and 1 Armoured',
    tags: ['defence', 'buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Block, 2);
      addModifier(source, StatusEffect.Armoured, 1);
    }
  }
];
