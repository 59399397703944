import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import type { Infusion } from '../status-effects/infusion';
import { DieFace } from '../face.type';
import { StatusEffect } from '../status-effects';


export const ice: Technique[] = [
  {
    ingredients: [DieFace.Ice],
    requirements: [{
      text: 'No Infusion other than ice',
      iconBonus: 'no',
      icon: 'infusion',
      test: (source: Player, target: Player) => {
        const infusion = source.statusEffects.find((modifier: Infusion) => {
          return modifier.type == 'infusion' && modifier.infusion != 'ice';
        });
        return !infusion;
      }
    }],
    title: 'Elemental Infusion (ice)',
    text: 'Gain 1 Infusion (ice).',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Infusion, 1, { infusion: DieFace.Ice });
    }
  }, {
    ingredients: [DieFace.Ice, DieFace.Ice, DieFace.Mana],
    title: 'Focused Permafrost',
    text: 'Deal 3 Damage, Apply 3 Freezing and 1 Slow.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
      addModifier(target, StatusEffect.Freezing, 3);
      addModifier(target, StatusEffect.Slow, 1);
    }
  }, {
    ingredients: [DieFace.Ice, DieFace.Ice],
    title: 'Unstable Permafrost',
    text: 'Deal 3 Damage, Apply 3 Freezing. Take 1 damage, Gain 2 Freezing.',
    tags: ['damage', 'debuff', 'suffer'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
      addModifier(target, StatusEffect.Freezing, 3);

      dealDamage({ value: 1, target: source, source, dice });
      addModifier(source, StatusEffect.Freezing, 2);
    }
  }, {
    ingredients: [DieFace.Ice, DieFace.Mana],
    title: 'Chilling Wind',
    text: 'Deal 1 Damage, Apply 2 Freezing.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Freezing, 2);
    }
  }, {
    ingredients: [DieFace.Ice, DieFace.Brain],
    title: 'Ice Block',
    text: 'Gain 3 Ward.',
    tags: ['defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Ward, 3);
    }
  }
];
