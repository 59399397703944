import type { Player } from "./player.type";
import type { traitDefinition } from "./trait.type";
import { boss } from "./traits/boss";
import { elementals } from "./traits/elementals";
import { player } from "./traits/player";
import { regular } from "./traits/regular";

export const createTrait = (owner: Player, type: string) => {
  const traitDefinition = traits.find(trait => trait.type == type);
  if(!traitDefinition){
    console.error(`Trait ${type} not found`);
    return;
  }
  const trait = new traitDefinition.class(traitDefinition);

  trait.owner = owner;
  trait.stacks = undefined;
  trait.init();

  return trait;
};


export const traits: Array<traitDefinition> = []
//@ts-ignore
.concat(boss).concat(elementals).concat(player).concat(regular);
