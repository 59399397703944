import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';


export const claws: Technique[] = [
  {
    ingredients: [DieFace.Claws],
    title: 'Claw',
    text: 'Deal 1 Damage',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
    }
  }, {
    ingredients: [DieFace.Claws, DieFace.Claws],
    title: 'Scratch',
    text: 'Deal 2 Damage, Apply 1 Bleed.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, StatusEffect.Bleed, 1);
    }
  }, {
    ingredients: [DieFace.Claws, DieFace.Claws, DieFace.Claws],
    title: 'Scratchfest',
    text: 'Deal 4 Damage, Apply 2 Bleed.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 4, target, source, dice });
      addModifier(target, StatusEffect.Bleed, 2);
    }
  }, {
    ingredients: [DieFace.Claws, DieFace.Agility],
    title: 'Pounce',
    text: 'Deal 2 Damage, Gain 1 dodge',
    tags: ['damage', 'defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(source, StatusEffect.Dodge, 1);
    }
  }, {
    ingredients: [DieFace.Claws, DieFace.Bite],
    title: 'Rip & Tear',
    text: 'Deal 2 Damage, Apply 2 Bleed.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, StatusEffect.Bleed, 2);
    }
  },
];
