import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';


export const hammer: Technique[] = [
  {
    ingredients: [DieFace.Hammer],
    title: 'Whack',
    text: 'Deal 1 Damage.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
    }
  },{
    ingredients: [DieFace.Hammer, DieFace.Hammer],
    title: 'Double Whack',
    text: 'Deal 2 Damage, Apply 1 Stunned.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, StatusEffect.Stunned, 1);
    }
  },{
    ingredients: [DieFace.Hammer, DieFace.Hammer, DieFace.Hammer],
    title: 'Triple Whack',
    text: 'Deal 3 Damage, Apply 2 Stunned.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
      addModifier(target, StatusEffect.Stunned, 2);
    }
  }, {
    ingredients: [DieFace.Hammer, DieFace.Fist],
    title: 'Whabam',
    text: 'Deal 2 Damage, Apply 1 Vulnerable.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, StatusEffect.Vulnerable, 1);
    }
  }, {
    ingredients: [DieFace.Hammer, DieFace.Blood],
    title: 'Knockout',
    text: 'Deal 2 Damage, Apply 1 Clumsy.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, StatusEffect.Clumsy, 1);
      /* const gains = source.modifiers.find(modifier => {
        return modifier.tags.includes('gain');
      });

      var stacks = 2;
      if(gains){
        gains.forEach(gain => {
          while(gain.stacks > 0 && stacks > 0){
            gain.removeStacks(1);
            stacks --;
          }
        });
      } */
    }
  }
];
