
<script lang='ts'>
  import { options } from '../../store';
  import { memory } from '../utils/memory';
  import { optionData } from './modals';
  import * as Sentry from "@sentry/svelte";
  import { prompt } from './modals';

  let onConfirm = null;

  optionData.subscribe(value => {
    if(value && value.callback){
      onConfirm = value.callback;
    }
    else{
      onConfirm = null;
    }
  });

  const confirm = () => {
    if(onConfirm){
      onConfirm();
    }
    optionData.set(null);
  }

  options.subscribe(value => {
    for(let key of Object.keys(value)){
      memory.persist(key, value[key]);
    }
  });

  async function reportBug(){
    confirm();
    var text = await prompt({
      title: 'Report Bug',
      text: 'Please describe the bug you encountered.',
    });

    if(text){
      Sentry.captureMessage(text);
    }
  }
</script>

{#if $optionData}
<div class='modal-container'>
  <div class='modal'>
    <div class='header'>
      <h1>Options</h1>
    </div>

    <div class='content'>
      <label class="container"> Show Keyword Tooltips
        <input type="checkbox" bind:checked={$options.tooltipsKeywords}>
        <span class="checkmark"></span>
      </label>

      <label class="container"> Auto-play
        <input type="checkbox" bind:checked={$options.autoPlay}>
        <span class="checkmark"></span>
      </label>

      <label class="container"> Invincible
        <input type="checkbox" bind:checked={$options.invincible}>
        <span class="checkmark"></span>
      </label>

      <button on:click={reportBug}>Report Bug</button>
    </div>

    <div class='functions'>
      <button class='btn' on:click={confirm}>Close</button>
    </div>
  </div>
</div>
{/if}

<style lang='less'>
  /* Customize the label (the container) */
  .container {
    display: block;
    position: relative;
    padding-left: 35px;
    margin-bottom: 12px;
    cursor: pointer;
    font-size: 22px;
    -webkit-user-select: none;
    -moz-user-select: none;
    -ms-user-select: none;
    user-select: none;
  }

  /* Hide the browser's default checkbox */
  .container input {
    position: absolute;
    opacity: 0;
    cursor: pointer;
    height: 0;
    width: 0;
  }

  /* Create a custom checkbox */
  .checkmark {
    position: absolute;
    top: 0;
    left: 0;
    height: 25px;
    width: 25px;
    background-color: #eee;
  }

  /* On mouse-over, add a grey background color */
  .container:hover input ~ .checkmark {
    background-color: #ccc;
  }

  /* When the checkbox is checked, add a blue background */
  .container input:checked ~ .checkmark {
    background-color: #2196F3;
  }

  /* Create the checkmark/indicator (hidden when not checked) */
  .checkmark:after {
    content: "";
    position: absolute;
    display: none;
  }

  /* Show the checkmark when checked */
  .container input:checked ~ .checkmark:after {
    display: block;
  }

  /* Style the checkmark/indicator */
  .container .checkmark:after {
    left: 9px;
    top: 5px;
    width: 5px;
    height: 10px;
    border: solid white;
    border-width: 0 3px 3px 0;
    -webkit-transform: rotate(45deg);
    -ms-transform: rotate(45deg);
    transform: rotate(45deg);
  }
</style>