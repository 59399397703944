import { writable } from 'svelte/store';
import type { Die } from '../models/die.type';
import type { AmmoType } from '../models/techniques/ammo';
import type { Player } from '../models/player.type';
import type { Item } from '../models/item.type';

//modals
export const alertData = writable(null);
export const confirmData = writable(null);
export const promptData = writable(null);
export const codeData = writable(null);
export const selectDieData = writable(null);
export const selectFaceData = writable(null);
export const optionData = writable(null);
export const ammoData = writable(null);
export const inventoryData = writable(null);
export const tutorialData = writable(null);

type alertData = {
  title: string,
  text: string,
  callback?: Function
};

type confirmData = {
  title: string,
  text: string,
  callbackConfirm?: Function
  callbackCancel?: Function
};

type promptData = {
  title: string,
  text: string,
  callbackConfirm?: Function
  callbackCancel?: Function
};

type diceData = {
  dice: Array<Die>,
  callback?: Function
};

type dieData = {
  die: Die,
  callback?: Function
};

type optionData = {
  callback?: Function
};

type ammoData = {
  callback?: Function,
  ammo: Array<AmmoType>
};

type inventoryData = {
  callback?: Function,
  player: Player;
  inventory: Array<Item>
};

export type TutorialData = {
  callback?: Function,
  title: string,
  text: string,
  anchor?: {
    selector: string,
    position: 'above' | 'below' | 'left' | 'right' | 'center'
  },
  size?: 'small' | 'medium' | 'large'
};

export const alert = (data: alertData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    alertData.set(data);
  });
};

export const confirm = (data: confirmData) => {
  return new Promise<any>((accept, reject) => {
    data.callbackConfirm = (res: any) => {
      accept(res);
    };
    data.callbackCancel = (res: any) => {
      reject(res);
    };

    confirmData.set(data);
  });
};


export const prompt = (data: promptData) => {
  return new Promise<any>((accept, reject) => {
    data.callbackConfirm = (res: any) => {
      accept(res);
    };
    data.callbackCancel = (res: any) => {
      reject(res);
    };

    promptData.set(data);
  });
};

export const code = (data: alertData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    codeData.set(data);
  });
};

export const selectDie = (data: diceData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    selectDieData.set(data);
  });
};

export const selectFace = (data: dieData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    selectFaceData.set(data);
  });
};

export const openOptions = (data: optionData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    optionData.set(data);
  });
};

export const openAmmo = (data: ammoData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    ammoData.set(data);
  });
};

export const openInventory = (data: inventoryData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    inventoryData.set(data);
  });
};

export const openTutorial = (data: TutorialData) => {
  return new Promise<any>((accept, reject) => {
    data.callback = (res: any) => {
      accept(res);
    };

    tutorialData.set(data);
  });
};