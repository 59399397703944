import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';

export class Dodge extends Modifier{
  damageIn(damage: Damage){
    //get modifier stealth
    let dodgeStacks = this.stacks;

    /* const stealth = this.owner.statusEffects.find(modifier => modifier.type === 'stealth');
    if(stealth){
      dodgeStacks += stealth.stacks;
    } */

    /* if (damage.value < dodgeStacks){
      damage.value = 0;
      this.removeStacks(1);
    } */

    // When attacked, if the damage is higher than stacks, negate the damage and reduce stacks by the difference.Temporary.
    if(damage.value >= dodgeStacks){
      damage.value = 0;
      this.removeStacks(damage.value - dodgeStacks);
    }
  }

  startTurn(){
    this.removeStacks(this.stacks);
  }

  modifierAdd(type: string, stacks: number){
    if(type === 'snared'){
      let difference = this.stacks - stacks;
      this.removeStacks(Math.min(this.stacks, stacks));

      if(difference >= 0){
        stacks = 0;
      }
      else{
        stacks = Math.abs(difference);
      }
    }
    return stacks;
  }
}