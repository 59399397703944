import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';
import { healthRemove } from '../player.type';
import { actionHistory } from '../../../store';

export class Freezing extends Modifier {
  update(stacks: number) {
    if (stacks) {
      actionHistory.push(`Added ${stacks} ${this.type} to ${this.owner.title}.`);
      this.stacks += stacks;
    }

    this.checkDied();
  }

  checkDied() {
    if (this.stacks >= this.owner.healthCurrent) {
      const damage: Damage = {
        value: this.stacks,
        target: this.owner,
        source: this.owner,
        dice: []
      };

      actionHistory.push(`Shatter`);
      healthRemove(damage);
    }
  }

  modifyNotches(notches: Array<string>) {
    var first = notches.indexOf('health');
    if (first == -1) {
      return;
    }
    for (var i = 0; i < this.stacks; i++) {
      if (notches[first + i] && notches[first + i].includes('health')) {
        notches[first + i] += ' freezing';
      }
    }
  }

  useTechnique() {
    this.removeStacks(1);
  }
}