<script lang='ts'>
  import DiceTray from '../dice-tray/dice-tray.svelte';
  import { selectFaceData } from './modals';

  let onConfirm = null;
  let face = null;
  let faces = [];

  selectFaceData.subscribe(value => {
    if(value && value.callback){
      onConfirm = value.callback;

      for(var i = 0; i < 6; i++){
        faces[i] = value.die.clone();
        faces[i].activeFace = i;
        faces[i].id = 'x'+i;
        faces[i].selected = false;
      }
    }
    else{
      onConfirm = null;
    }
  });

  const confirm = () => {
    if(onConfirm){
      onConfirm(face);
    }
    selectFaceData.set(null);
  }

  function chooseDie(selectedDie){
    /*if(selectedDie.used){
      return;
    }*/
    faces.forEach(die => {
      die.selected = false;
    });

    selectedDie.selected = true;
    face = selectedDie.activeFace;

    faces = faces;
  }
</script>

{#if $selectFaceData}
<div class='modal-container'>
  <div class='modal'>
    <div class='header'>
      <h1>Select a Die</h1>
    </div>

    <div class='body'>
      <DiceTray
        dice={faces}
        clickEvent="{(die) => {chooseDie(die)}}"
        class='horizontal'
      />
    </div>

    <div class='functions'>
      <button class='btn' on:click={confirm}>Confirm</button>
    </div>
  </div>
</div>
{/if}

<style lang="less">

</style>