<script lang='ts'>
  import Hover from './hover.svelte';
  import type { Player } from '../models/player.type';
  export let data: Player;
  export let reverse = false;
  var notches: Array<string>;

  var level1 = [
    {
      title: 'health',
      value: 0,
      level2: [
        {
          title: 'freezing',
          value: 0
        },{
          title: 'health',
          value: 0
        },{
          title: 'poison',
          value: 0
        }
      ]
    },{
      title: 'empty',
      value: 0,
      level2: [
        {
          title: 'regen',
          value: 0
        },{
          title: 'empty',
          value: 0
        }
      ]
    },{
      title: 'over',
      value: 0,
      level2: [
        {
          title: 'block',
          value: 0
        },{
          title: 'barrier',
          value: 0
        },{
          title: 'armoured',
          value: 0
        }
      ]
    }
  ];

  function calcNotches(){
    notches = Array(data.healthMax).fill("").map((notch, index) => {
      return data.healthCurrent > index ? "health" : "empty";
    });

    data.statusEffects.filter(mod => 'modifyNotches' in mod)
    .forEach(mod => {
      mod.modifyNotches(notches);
    });

    level1.forEach(level => {
      level.value = notches.filter(notch => notch.includes(level.title)).length;

      level.level2.forEach(level => {
        level.value = notches.filter(notch => notch.includes(level.title)).length;
      });
    });
    level1[2].value = notches.length - level1[0].value - level1[1].value;
  }

  $: if (data !== undefined) {
    calcNotches();
  }
  calcNotches();
</script>

<div class='health-container {reverse? "reverse" : ""}'>
  <div class='container'>
    {#each notches as notch, index}
        <div class='notch {notch}'></div>
    {/each}

    <div class='hover-container'>
      <div class='level level1'>
        {#each level1 as level, index}
          <div class='{level.title}' style='flex-grow: {level.value};'>
            <!-- {level.value} {level.title} -->

            <div class='level level2'>
              {#each level.level2 as level2, index}
                {#if level2.value}
                  <div class='{level2.title}' style='flex-grow: {level2.value};'>
                    {level2.value} {level2.title}
                  </div>
                {/if}
              {/each}
            </div>
          </div>
        {/each}
      </div>
    </div>
  </div>
</div>

<style lang='less'>
  .health-container{
    display: flex;
    justify-content: end;
    flex-direction: column-reverse;
    border: 1px solid black;
    grid-gap: 0px;
    transition: grid-gap 0.3s;
    position: relative;

    &:hover{
      grid-gap: 10px;

      .container{
        .hover-container{
          pointer-events: all;
          opacity: 1;
          left: var(--health-bar-width);
        }
      }
      &.reverse{
        .container{
          .hover-container{
            right: var(--health-bar-width);
          }
        }
      }
    }

    .container{
      display: flex;
      justify-content: start;
      flex-direction: column-reverse;
      position: relative;
      font-family: 'Roboto Condensed';

      .hover-container{
        position: absolute;
        opacity: 0;
        transition: left 0.3s, right 0.3s, opacity 0.3s;
        z-index: 111;
        height: 100%;
        left: 0px;
        display: flex;
        pointer-events: none;

        .level{
          display: flex;
          flex-direction: column-reverse;

          &.level1{
            position: relative;
            width: 20px;
          }

          &.level2{
            position: absolute;
            top: 0;
            bottom: 0;
            left: 20px;
            right: -100px;
            width: 100px;

            div{
              display: flex;
              align-items: center;
              justify-content: end;
              padding-right: 5px;
            }
          }
          div{
            background: linear-gradient(to right, var(--color1,transparent) 0%, var(--color2,transparent) 20%);
          }

          .health{
            background-color: red;
            position: relative;
            --color1: red;
          }
          .empty{
            background-color: black;
            position: relative;
            --color1: black;
          }
          .over{
            position: relative;
          }

          .block{
            background-color: gray;
          }
          .poison{
            background-color: #017406;
          }
          .armoured{
            background-color: #333;
          }
          .barrier{
            background-color: yellow;
            color: black;
          }
          .regen{
            background-color: rgb(185, 0, 130);
          }
          .freezing{
            background-color: cornflowerblue;
            color: black;
          }
        }

        /*h3{Score Sheer Jock - Sudan Sugar

L
          margin: 0;
        }*/
      }
    }

    &.reverse{
      justify-content: start;
      flex-direction: column;

      .notch{
        background: linear-gradient(210deg, var(--color1, pink) 40%, var(--color2, pink) 60%);
      }

      .container{
        display: flex;
        justify-content: start;
        flex-direction: column;

        .hover-container{
          left: unset;
          right: 0px;

          .level{
            flex-direction: column;

            .level2{
              right: 20px;
              left: -100px;

              div{
                justify-content: start;
                padding-left: 5px;
                background-image: linear-gradient(to left, var(--color1,transparent) 0%, var(--color2,transparent) 20%);
              }
            }
          }
        }
      }
    }

    .notch{
      height: 4px;
      border-radius: 4px;
      margin: 2px;
      background: linear-gradient(30deg, var(--color1,pink) 40%, var(--color2,pink) 60%);
      transition: background 0.5s;

      &.health{
        --color1: red;
        --color2: red;
      }
      &.empty{
        --color1: black;
        --color2: black;
      }
      &.block{
        --color1: gray;
        --color2: gray;
      }

      &.poison{
        --color1: green;
        // --color1: #5e0000;
      }
      &.armoured{
        --color1: black;
        --color2: grey;
      }
      &.barrier{
        --color1: yellow;
        --color2: yellow;
      }
      &.regen{
        --color2: rgb(185, 0, 130);
      }
      &.freezing{
        --color1: cornflowerblue;
      }
    }

    .hover{
      position: absolute;
      background: black;
      z-index: 1111;
    }
  }
</style>