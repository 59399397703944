<script lang='ts'>
  import {icons} from "./icons";
  export let name: string;
  export let width = '100%';
  export let height = '100%';

  export let colour = 'white';

  let displayIcon = icons[name];

  $: if (name !== '') {
    displayIcon = icons[name];
    if(!displayIcon || !displayIcon.svg){
      console.error('No icon for:', name);
    }
  }
  else{
    displayIcon = icons['blank'];
  }
</script>

<!-- width={width} -->
<!-- height={height} -->
<svg
  class={$$props.class}
  style="
    width: {width != '100%'? width : 'var(--icon-size)'};
    height: {height != '100%'? height : 'var(--icon-size)'};
    fill: {colour}"
  viewBox="0 0 500 500">{@html displayIcon.svg}</svg>


<style lang='less'>
  svg{
    stroke: black;
    stroke-width: 10px;
    z-index: 1;

    width: var(--icon-size);
    height: var(--icon-size);
  }
</style>

