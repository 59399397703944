import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';

export class Ethereal extends Modifier{
  damageIn(damage: Damage){
    if (damage.value > 0 && damage.value <= this.stacks){
      damage.value = 0;
      this.removeStacks(1);
    }
  }

  startTurn(){
    this.removeStacks(this.stacks);
  }
}