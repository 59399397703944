<script lang="ts">
  import { onMount, onDestroy } from 'svelte';
  import { screens, playerTruth } from '../store';
  import type { MapEdge, MapNode } from './models/map.type';
  import { Network } from "vis-network";

  import Portrait from "./components/portrait.svelte";
  import Scrollable from './components/scrollable.svelte';

  import { mergeIcons } from './icon/icons';
  import type { Player } from './models/player.type';

  import { saveGame } from './utils/savefile';
  import { continueTutorial } from './utils/tutorial';

  let mapEl: HTMLDivElement;
  let networkMap: Network;
  let selected: MapNode;
  let playerUI: Player = $playerTruth;

  export let data: any;
  let map = data.map;
  let experienceRequired = ($playerTruth.level + 1) * 5 * 3;

  screens.subscribe(value => {
    // if last value equal map
    let last = value.findLast(value => {
      return value.state == 'visible';
    });
    if(mapEl && last && last.type == 'map'){
      // refresh playerUI variables
      playerUI = $playerTruth;
      updateMap();
      saveGame(map, $playerTruth);

      continueTutorial({type: 'map', value: 'map'});
    }
    experienceRequired = ($playerTruth.level + 1) * 5 * 3;
  });

  function openNode(node: MapNode){
    node.color = 'lightgreen';
    node.open = true;

    map.edges.filter(edge => {
      return edge.from == node.id || edge.to == node.id;
    })
    .forEach((edge: MapEdge) => {
      edge.dashes = false;
      let nextNodeId = edge.to == node.id ? edge.from : edge.to;
      let nextNode = map.nodes.find(node => {
        return node.id == nextNodeId;
      });

      if(nextNode.open === false){
        nextNode.color = 'lightgreen';
        nextNode.open = true;
      }
    });
  }

  function visitNode(){
    let node = selected;
    selected = undefined;

    var result = node.visit();
    if(!result){
      openNode(node);
      node.color = 'white';
      node.visited = true;
      node.type = 'clear';
      node.image = '';
      node.shape = 'circle';
      updateMap();
      saveGame(map, $playerTruth);
    }
    else{
      result.then((outcome: string) => {
        if(outcome == 'win'){
          openNode(node);
          node.color = 'white';
          node.visited = true;
          node.type = 'clear';
          node.image = '';
          node.shape = 'circle';
          updateMap();
          saveGame(map, $playerTruth);
        }
      });
    }
  }

  function levelUp(){
    if($playerTruth.experience >= experienceRequired){
      $playerTruth.experience -= experienceRequired;
      screens.push('upgrade');
    }
  }

  function updateMap(){
    map.nodes.forEach(node => {
      if(node.scouted){
        node.image = mergeIcons(node.icon, 'scouted');
      }
    });

    //set edges to point towards the cleared node
    map.edges.forEach(edge => {
      let fromNode = map.nodes.find(node => {
        return node.id == edge.from;
      });
      let toNode = map.nodes.find(node => {
        return node.id == edge.to;
      });

      if(fromNode.visited){
        edge.from = edge.to;
        edge.to = fromNode.id;
      }
      else if(!toNode.visited && fromNode.open){
        edge.from = edge.to;
        edge.to = fromNode.id;
      }
    });
    networkMap.setData(map);
  }

  const selectNode = (event) => {
    let node = map.nodes.find(node => {
      return node.id === event.nodes[0];
    });

    selected = node;
    console.log(selected)

    continueTutorial({type: 'nodeSelected', value: node.event?.type || node.type});
  };

  onMount(() => {
    let mapOptions = {
      interaction: {
        dragNodes: false,
        dragView: false,
        zoomView: false,
        multiselect: false,
        // selectConnectedEdges: false
      },
      physics: {
        // enabled: false
      },
      groups:{
        useDefaultGroups: true,
      },
      layout:{
        improvedLayout: true
      }
    };

    // if(map == undefined){
      // map.set(data.map);
      openNode(map.nodes[0]);
      map.nodes[0].visited = true;
      map.nodes[0].color = 'white';
    // }
    if(!networkMap){
      networkMap = new Network(mapEl, map, mapOptions);
      /* $networkMap.clustering.cluster({
        joinCondition: function (nodeOptions) {
          console.log(nodeOptions)
          return false;
        }
      }); */
      console.log(networkMap, map)

      networkMap.on('selectNode', selectNode);
    }
    else{
      // add network map div to page
      // @ts-ignore
      mapEl.appendChild(networkMap.body.container);
    }
    updateMap();

	});

  onDestroy(() => {
    networkMap.off('selectNode', selectNode);
  });
</script>

<div class='container'>
  <div class='player'>
    <Portrait data={playerUI}/>

    <div class='details'>
      <div class='section'>
        <h3>Health</h3>
        <div class='bar-container red'>
          <div class='bar-inner' style='width: {playerUI.healthCurrent / playerUI.healthMax*100}%'>
          </div>
          <p>{playerUI.healthCurrent} / {playerUI.healthMax}</p>
        </div>

        <h3>Experience</h3>
        <div class='bar-container yellow' on:click={levelUp} on:keydown={()=>{}}>
          <div class='bar-inner' style='width: {playerUI.experience / experienceRequired*100}%'>
          </div>
          {#if playerUI.experience >= experienceRequired}
            <p>Level Up!</p>
          {:else}
            <p>{playerUI.experience} / {experienceRequired}</p>
          {/if}
        </div>
      </div>

      <div class='section'>
        <h3>Money</h3>
        <div>
            <p>{playerUI.money}</p>
        </div>
      </div>
    </div>
  </div>

  <div class='map-container'>
    <Scrollable zoomable={true}>
      <div class='map' bind:this={mapEl}>
      </div>
    </Scrollable>
  </div>

  {#if selected?.type == 'battle'}
    <div class='encounter battle'>
      {#if selected?.scouted}
        <div class='portrait battle'>
          <Portrait data={selected?.battle?.enemy}/>
        </div>

        <div class='details'>
          <h2>{selected?.battle?.enemy?.title} lvl.{selected?.battle?.level}</h2>
          <p>{selected?.battle?.enemy?.text}</p>

          {#if selected?.open}
          <button on:click={visitNode} class='btn'>
            Start Battle
          </button>
          {:else}
            <p>Cannot reach location</p>
          {/if}
        </div>
      {:else}
        <div class='portrait battle'>
          <div class='frame'>?</div>
        </div>

        <div class='details'>
          <h2>Unknown Enemy</h2>
          {#if selected?.open}
            <button on:click={visitNode} class='btn'>
              Start
            </button>
          {:else}
            <p>Cannot reach location</p>
          {/if}
        </div>
      {/if}
    </div>
  <!-- {:else if selected?.type == 'scout'}
    <div class='encounter scout'>
      {#if selected?.scouted || selected?.open}
        <div class='portrait scout'>
          <Portrait data={{type:selected.event?.image}}/>
        </div>

        <div class='details'>
          <h2>Scout</h2>
          <p>
            {selected.event?.text}
          </p>
          {#if selected?.open}
            <button on:click={visitNode} class='btn'>
              Start
            </button>
          {:else}
            <p>Cannot reach location</p>
          {/if}
        </div>
      {:else}
        <div class='portrait scout'>
          <div class='frame'>?</div>
        </div>

        <div class='details'>
          <h2>Scout</h2>
          <p>
          </p>
          {#if selected?.open}
            <button on:click={visitNode} class='btn'>
              Start
            </button>
          {:else}
            <p>Cannot reach location</p>
          {/if}
        </div>
      {/if}
    </div>
  {:else if selected?.type == 'camp'}
    <div class='encounter campfire'>
      {#if selected?.scouted || selected?.open}
        <div class='portrait campfire'>
          <Portrait data={{type:'campfire1'}}/>
        </div>

        <div class='details'>
          <h2>Campfire</h2>
          <p>
            {selected.event?.text}
          </p>
          {#if selected?.open}
            <button on:click={visitNode} class='btn'>
              Start
            </button>
          {:else}
            <p>Cannot reach location</p>
          {/if}
        </div>
      {:else}
        <div class='portrait campfire'>
          <div class='frame'>?</div>
        </div>

        <div class='details'>
          <h2>Campfire</h2>
          <p>
          </p>
          {#if selected?.open}
            <button on:click={visitNode} class='btn'>
              Start
            </button>
          {:else}
            <p>Cannot reach location</p>
          {/if}
        </div>
      {/if}
    </div> -->
  {:else if selected?.type == 'event'}
    <div class='encounter {selected.event.type}'>
      {#if selected?.scouted || selected?.open}
        <div class='portrait {selected.event.type}'>
          <Portrait data={{type:selected.event.image}}/>
        </div>

        <div class='details'>
          <h2>{selected.event.type}</h2>
          <h2>{selected.event.title}</h2>
          <p>
            {selected.event?.text}
          </p>
          {#if selected?.open}
            <button on:click={visitNode} class='btn'>
              Start
            </button>
          {:else}
            <p>Cannot reach location</p>
          {/if}
        </div>
      {:else}
        <div class='portrait {selected.event.type}'>
          <div class='frame'>?</div>
        </div>

        <div class='details'>
          <h2>{selected.event.type}</h2>
          <p>
            It could be anything
          </p>
          {#if selected?.open}
            <button on:click={visitNode} class='btn'>
              Start
            </button>
          {:else}
            <p>Cannot reach location</p>
          {/if}
        </div>
      {/if}
    </div>
  {:else if selected?.type == 'boss'}
    <div class='encounter boss'>
      {#if selected?.scouted || selected?.open}
        <div class='portrait boss'>
          <Portrait data={selected?.battle?.enemy}/>
        </div>

        <div class='details'>
          <h2>Boss</h2>
          <h2>{selected?.battle?.enemy?.title} lvl.{selected?.battle?.level}</h2>
          <p>
            {selected.battle?.enemy.text}
          </p>

          {#if selected?.open}
            <button on:click={visitNode} class='btn'>
              Start Battle
            </button>
          {:else}
            <p>Cannot reach location</p>
          {/if}
        </div>
      {:else}
        <div class='portrait boss'>
          <div class='frame'>?</div>
        </div>

        <div class='details'>
          <h2>Boss</h2>
          <p>
            It could be anything
          </p>
          {#if selected?.open}
            <button on:click={visitNode} class='btn'>
              Start
            </button>
          {:else}
            <p>Cannot reach location</p>
          {/if}
        </div>
      {/if}
    </div>
  <!-- {:else if selected?.type == 'quest'}
    <div class='encounter quest'>
      <div class='portrait quest'>
        <Portrait data={{type:selected.event.image}}/>
      </div>

      <div class='details'>
        <h2>Quest</h2>
        <p>
          {selected.event?.text}
        </p>
        {#if selected?.open}
          <button on:click={visitNode} class='btn'>
            Start
          </button>
        {:else}
          <p>Cannot reach location</p>
        {/if}
      </div>
    </div> -->
  {/if}
</div>


<style lang='less'>
  @import "../defines.less";

  :root{
    background-color: var(--background-colour);
  }

  .container{
    display: grid;
    width: 100%;
    height: 100%;
    grid-template-columns: var(--column-size) 1fr var(--column-size);

    @media @tall {
      grid-template-columns: 1fr;
      grid-template-rows: var(--column-size) 1fr var(--column-size);
    }

    .map-container{
      width: 100%;
      height: 100vh;
      overflow: auto;

      .map {
        width: 100%;
        height: 100%;
        // width: 100%;
        // height: 100%;

        background-image: url('../assets/backgrounds/worldMap3.webp');
        background-size: cover;
        background-position: center;
      }
    }

    /* .encounter{
      &.enemy{
      }
      &.scout{
      }
    } */

    .portrait{
      height: var(--column-size);
      width: var(--column-size);
      display: flex;
      align-items: center;
      justify-content: center;
      font-family: auto;
      box-shadow: inset black 0px 0px var(--icon-size) 10px;
      font-size: 250px;
      position: relative;

      @media @large {
        font-size: 175px;
      }
      @media @medium {
        font-size: 140px;
      }
      @media @small {
        font-size: 120px;
      }

      .frame{
        position: absolute;
        top: 0;
        bottom: 0;
        left: 0;
        right: 0;
        border-style: solid;
        border-width: 4px;
        border-color: black;

        outline-style: solid;
        outline-width: 2px;
        outline-color: var(--background-colour);

        margin: 3px;
        box-shadow: 0 0 4px 4px var(--background-colour) inset;

        @media @medium{
          border-width: 2px;
          margin: 2px;
        }
        @media @small{
          border-width: 1px;
          outline-width: 1px;
          box-shadow: 0 0 2px 2px var(--background-colour) inset;
        }
      }

      &.battle{
        background: #C72E17;
        color: #50120A;
        --background-colour: #C72E17;
      }

      &.scout{
        background: #4CAF50;
        color: #388E3C;
        --background-colour: #4CAF50;
      }

      &.campfire{
        background: #FF9800;
        color: #E65100;
        --background-colour: #FF9800;
      }

      &.event{
        background: #FFEB3B;
        color: #8D6E00;
        --background-colour: #FFEB3B;
      }

      &.quest{
        background: #2196F3;
        color: #0D47A1;
        --background-colour: #2196F3;
      }

      &.boss{
        background: #9370DB;
        color: #4B0082;
        --background-colour: #9370DB;
      }
    }

    .details{
      padding: 10px;

      h2, h3{
        margin-bottom: 10px;
        text-transform: capitalize;
      }
      p{
        margin-bottom: 20px;
      }

      .btn{
        margin: auto;
      }
    }

    .bar-container{
      width: 100%;
      height: 25px;
      background: white;
      border-radius: 4px;
      overflow: hidden;
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;

      .bar-inner{
        width: 50%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      p{
        margin: 0;
        position: relative;
      }

      &.red{
        color: white;
        .bar-inner{
          background: red;
        }
      }
      &.yellow{
        color: black;
        .bar-inner{
          background: yellow;
        }
      }
    }
  }

  @media @tall {
    .container {
      grid-template-columns: 1fr;
      grid-template-rows: var(--column-size) 1fr var(--column-size);

      .player{
        display: flex;

        .details{
          display: flex;
          overflow: auto;
          width: calc(100vw - var(--column-size) - 20px);
          padding: 0 10px;

          .section{
            min-width: 150px;
          }
        }
      }

      .map-container{
        max-height: calc(100vh - var(--column-size) - var(--column-size));
      }

      .encounter{
        display: flex;

        .details{
          width: calc(100vw - var(--column-size) - 20px);
          overflow: auto;
        }
      }
    }
  }
  /* .asd{
    // yellow
    color: #FFEB3B;
    color: #8D6E00;

    color: #2196F3;
    color: #0D47A1;

    color: #FF9800;
    color: #E65100;

    color: #4CAF50;
    color: #388E3C;
  } */
</style>