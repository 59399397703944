import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import type { Infusion } from '../status-effects/infusion';
import { DieFace } from '../face.type';
import { StatusEffect } from '../status-effects';


export const lightning: Technique[] = [
  {
    ingredients: [DieFace.Lightning],
    requirements: [{
      text: 'No Infusion other than lightning',
      iconBonus: 'no',
      icon: 'infusion',
      test: (source: Player, target: Player) => {
        const infusion = source.statusEffects.find((modifier: Infusion) => {
          return modifier.type == 'infusion' && modifier.infusion != 'lightning';
        });
        return !infusion;
      }
    }],
    title: 'Elemental Infusion (lightning)',
    text: 'Gain 1 Infusion (lightning).',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Infusion, 1, { infusion: DieFace.Lightning });
    }
  }, {
    ingredients: [DieFace.Lightning, DieFace.Lightning],
    title: 'Unstable Lighting Bolt',
    text: 'Deal 3 Damage, Apply 3 Spark. Take 1 Damage, Gain 2 Spark.',
    tags: ['damage', 'debuff', 'suffer'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
      addModifier(target, StatusEffect.Spark, 3);

      dealDamage({ value: 1, target: source, source, dice });
      addModifier(source, StatusEffect.Spark, 2);
    }
  }, {
    ingredients: [DieFace.Lightning, DieFace.Lightning, DieFace.Mana],
    title: 'Focused Lighting Bolt',
    text: 'Deal 3 Damage, Apply 3 Spark and 1 Shocked.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
      addModifier(target, StatusEffect.Spark, 3);
      addModifier(target, StatusEffect.Shocked, 1);
    }
  }, {
    ingredients: [DieFace.Lightning, DieFace.Mana],
    title: 'Shock Wave',
    text: 'Deal 1 damage, Apply 2 Spark.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Shocked, 2);
    }
  }, {
    ingredients: [DieFace.Lightning, DieFace.Brain],
    title: 'Lightning Speed',
    text: 'Gain 1 Haste.',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Haste, 1);
    }
  },{
    ingredients: [DieFace.Lightning, DieFace.Reflex],
    title: 'Lightning Reflexes',
    text: 'Gain 2 Dodge and 1 Reactive.',
    tags: ['buff', 'defence'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Dodge, 2);
      addModifier(source, StatusEffect.Reactive, 1);
    }
  }
];
