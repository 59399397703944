import { Modifier } from "../modifier";
import { modifiers } from '../status-effects';

export class Ward extends Modifier {
  modifierAdd(type: string, stacks: number) {
    if (modifiers[type].tags.includes('pain')) {
      let difference = this.stacks - stacks;
      this.removeStacks(Math.min(this.stacks, stacks));

      if (difference >= 0) {
        stacks = 0;
      }
      else {
        stacks = Math.abs(difference);
      }
    }
    return stacks;
  }
}