<script lang='ts'>
  import Icon from '../icon/icon.svelte';
  import { DieFace } from '../models/face.type';

  export let face: Array<DieFace>;
  export let sizeMod = 0;

  let icons: string | any[];
  let size: number;
  let isSplit: boolean;

  function calcFaces(){
    icons = face.filter(facc => {
      return facc !== DieFace.OR;
    });
    size = 20;
    isSplit = face.indexOf(DieFace.OR) !== -1;

    switch(icons.length + sizeMod){
      case 1:
        size = 100;//40*(sizeMod/100);
        break;
      case 2:
        size = 75;//30*(sizeMod/100);
        break;
      case 3:
        size = 50;//25*(sizeMod/100);
        break;
      case 4:
        size = 25;//25*(sizeMod/100);
        break;
    }
    // size += 'px';
  }

  $: if (face.length > 0 && face !== undefined) {
    calcFaces();
  }
  calcFaces();
</script>

{#each icons as icon, index}
  <div class='icon icon-{size}'>
    <Icon name={icon}/>
  </div>

  {#if isSplit && index != icons.length-1}
    <div class="vr" style='height: 75%;'></div>
  {/if}
{/each}


<style lang='less'>
  @import "../../defines.less";

  .icon {
    display: flex;
  }
  :global(.icon-100){
    --icon-size: 40px;
  }
  :global(.icon-75){
    --icon-size: 30px;
  }
  :global(.icon-50){
    --icon-size: 20px;
  }
  :global(.icon-25){
    --icon-size: 15px;
  }

  @media @medium{
    :global(.icon-100){
      --icon-size: 35px;
    }
    :global(.icon-75){
      --icon-size: 22px;
    }
    :global(.icon-50){
      --icon-size: 15px;
    }
    :global(.icon-25){
      --icon-size: 12px;
    }
  }

  @media @small{
    :global(.icon-100){
      --icon-size: 23px;
    }
    :global(.icon-75){
      --icon-size: 17px;
    }
    :global(.icon-50){
      --icon-size: 15px;
    }
    :global(.icon-25){
      --icon-size: 12px;
    }
  }

  .vr{
    width: 1px;
    margin: 0 2px;
    background: linear-gradient(to top, transparent, #fff, transparent);
  }
</style>