import { Modifier, type ModifierParams } from "../modifier";
import { invokeDie } from '../die.type';
import type { Player } from '../player.type';
import { actionHistory } from '../../../store';
import type { DieFace } from '../face.type';

export class Infusion extends Modifier {
  infusion: DieFace;

  clone(newOwner: Player) {
    var clone = super.clone(newOwner);
    clone.infusion = this.infusion;

    return clone;
  }

  init(params?: ModifierParams) {
    if (actionHistory) {
      actionHistory.push(`${this.owner.title} gained Infusion (${params.infusion}).`);
    }
    this.update(0, params);
  }

  update(stacks: number, params) {
    if(this.infusion !== params.infusion){
      actionHistory.push(`${this.owner.title} changed infusion from ${this.infusion} to ${params.infusion}.`);
      this.infusion = params.infusion;
      this.title = `${this.type} (${this.infusion})`;
      this.stacks = 0;
    }
    this.bonusIcon = this.infusion;

    if (stacks) {
      this.stacks += stacks;
      actionHistory.push(`Added ${stacks} ${this.type} to ${this.owner.title}.`);
    }
  }

  actionPhaseStart() {
    invokeDie([this.infusion]);
    this.removeStacks(1);
  }
}