import { openTutorial } from "../modals/modals";
import { screens } from "../../store";
import scout from "../icon/map-icons/scout.svg?raw";
import { getIcon } from "../icon/icons";

export let tutorialActive: boolean = false;

let screenratio = window.innerWidth / window.innerHeight;
let portrait = screenratio < 1;
let landscape = screenratio > 1;

export function continueTutorial(data){
  if (tutorialActive){
    //emit continue tutorial event to tutorial modal
    window.dispatchEvent(new CustomEvent('tutorial', {detail: data}));
  }
}

function timeout(ms) {
  return new Promise(resolve => setTimeout(resolve, ms));
}

function listenEvent(data){
  return new Promise((accept, reject) => {
    //create an event listener that will destroy itself after the event is triggered
    function listener(event){
      console.log(event.detail)
      if(event.detail.type == data.type && event.detail.value == data.value){
        window.removeEventListener('tutorial', listener);
        accept(event);
      }
    }
    window.addEventListener('tutorial', listener);
  });
}

export function activateTutorial(){
  tutorialActive = true;

  openTutorial({
    title: 'Welcome',
    text: 'This is Odam, an adventure dice building Role Playing Game.'
  })
  .then((skip) => {
    if(skip) return skip;
    return openTutorial({
      title: 'Event',
      text: 'This is the event screen. This is just an introductory event.'
    });
  })
  .then((skip) => {
    if (skip) return skip;
    return openTutorial({
      title: 'Event',
      text: 'This is the event text. It will tell you what is happening in the story.',
      anchor: {
        selector: '.event-text',
        position: 'above'
      }
    });
  })
  .then((skip) => {
    if (skip) return skip;
    return openTutorial({
      title: 'Event',
      text: 'This is the event options. Usually you will have more than one but for now you can click continue.',
      anchor: {
        selector: '.options',
        position: 'above'
      }
    });
  })
  .then(() => {
    // wait until map is loaded
    return new Promise((accept, reject) => {
      const unsubscribe = screens.subscribe(value => {
        let last = value.findLast(value => {
          return value.state == 'visible';
        });
        if(last && last.type == 'map'){
          unsubscribe();
          accept(openTutorial({
            title: 'Map',
            text: 'This is the map screen. Here you will explore the map and choose where you will travel.'
          }));
        }
      });
    });
  })
  .then((skip) => {
    if (skip) return skip;
    return openTutorial({
      title: 'Map',
      text: `This is the map. You can move around the map by clicking on the nodes. Please select the node with the <img src="${getIcon('scout')}"> icon.`,
    });
  })
  .then(async (skip) => {
    if (skip) return skip;
    // wait until they have clicked, need a listener for this
    await listenEvent({ type: 'nodeSelected', value: 'scout' });

    return openTutorial({
      title: 'Map',
      text: `The event information is displayed here. You can see what type it is and a short description.`,
      anchor: {
        selector: '.encounter.scout',
        position: landscape ? 'left' : 'above'
      }
    });
  })
  .then( async (skip) => {
    if (skip) return skip;
    return openTutorial({
      title: 'Map',
      text: `Click this button to start the event.`,
      anchor: {
        selector: '.encounter.scout .btn',
        position: landscape ? 'left' : 'above'
      }
    });
  })
  // Scout Event Screen
  .then(() => {
    return new Promise((accept, reject) => {
      const unsubscribe = screens.subscribe(value => {
        let last = value.findLast(value => {
          return value.state == 'visible' || value.state == 'loading';
        });
        if (last && last.type == 'event') {
          unsubscribe();
          accept(openTutorial({
            title: 'Scout Event',
            text: 'A scout event is a special event that will always allow you to scout other nodes on the map, but may have other outcomes too.'
          }));
        }
      });
    });
  })
  .then(() => {
    return new Promise((accept, reject) => {
      const unsubscribe = screens.subscribe(value => {
        let last = value.findLast(value => {
          return value.state == 'visible' || value.state == 'loading';
        });
        if (last && last.type == 'map') {
          unsubscribe();
          accept(openTutorial({
            title: 'Scouted Nodes',
            text: `All the map nodes have now been scouted. You can see this by the scouted <img src="${getIcon('eye', { fill: '#fff' })}"> icon.`
          }));
        }
      });
    });
  })
  .then((skip) => {
    if (skip) return skip;
    return openTutorial({
      title: 'Battle',
      text: `Time for battle, select the battle node <img src="${getIcon('battle0')}"> to continue.`,
    });
  })
  // Battle Start screen
  .then(() => {
    return new Promise((accept, reject) => {
      const unsubscribe = screens.subscribe(value => {
        let last = value.findLast(value => {
          return value.state == 'visible' || value.state == 'loading';
        });
        if (last && last.type == 'battlestart') {
          unsubscribe();
          accept(openTutorial({
            title: 'Battle',
            text: `This is the start of battle screen.`
          }));
        }
      });
    });
  })
  .then((skip) => {
    if (skip) return skip;
    return openTutorial({
      title: 'Battle',
      text: `Here you can view your enemy.`,
      anchor: {
        selector: '.portrait-container.enemy',
        position: 'left'
      }
    });
  })
  .then((skip) => {
    if (skip) return skip;
    return openTutorial({
      title: 'Battle',
      text: `You can open the glossary page for the enemy here to see more information.`,
      anchor: {
        selector: '.portrait-container.enemy .btn',
        position: 'left'
      }
    });
  })
  .then((skip) => {
    if (skip) return skip;
    return openTutorial({
      title: 'Battle',
      text: `Click this button to start the battle.`,
      anchor: {
        selector: '.start.btn',
        position: 'above'
      }
    });
  })
  // Battle Screen
  .then(() => {
    return new Promise((accept, reject) => {
      const unsubscribe = screens.subscribe(value => {
        let last = value.findLast(value => {
          return value.state == 'visible' || value.state == 'loading';
        });
        if (last && last.type == 'battle') {
          unsubscribe();
          accept(openTutorial({
            title: 'Battle',
            text: `This is the battle screen. <br/>The outcome of this battle is unimportant so feel free to experiment. Almost all actions are undoable and many elements have hover over tooltips.`
          }));
        }
      });
    });
  })
  .then((skip) => {
    if (skip) return skip;
    return openTutorial({
      title: 'Battle - Player Display',
      text: `This panel shows your portrait & effects, your health, and your dice. In the battle, if your health reaches 0, you will lose and get sent back to the map screen with a wound. Your goal is to defeat the enemy by reducing their health to 0 first.`,
      anchor: {
        selector: '.column.player',
        position: landscape ? 'right' : 'above'
      }
    });
  })
  .then((skip) => {
    if (skip) return skip;
    return openTutorial({
      title: 'Battle - Dice Board',
      text: `This is the main board where you will roll your dice.`,
      anchor: {
        selector: '.dice-container ',
        position: 'center'
      }
    });
  })
  .then((skip) => {
    if (skip) return skip;
    return openTutorial({
      title: 'Battle - Dice Board',
      text: `This is one of your die. Each face has a different value and you can hover over the die to see the possible faces.`,
      anchor: {
        selector: '.dice-container .slot',
        position: 'center'
      }
    });
  })
  .then((skip) => {
    if (skip) return skip;
    return openTutorial({
      title: 'Battle - Dice Board',
      text: `If the die has a face value you want, you can click it to lock it in which will move it to your dice tray.<br/>You can click on any locked die to return them to the board.`,
      anchor: {
        selector: '.dice-container .slot',
        position: 'center'
      }
    });
  })
  .then(async (skip) => {
    if (skip) return skip;

    await timeout(3000);

    return openTutorial({
      title: 'Battle - Dice Board',
      text: `To re-roll all unlocked die, click this button. The number in the bottom right represents how many re-rolls you have left to use this turn.`,
      anchor: {
        selector: '.actions .action.btn.reroll',
        position: 'right'
      }
    });
  })
  .then(async (skip) => {
    if (skip) return skip;

    return openTutorial({
      title: 'Battle - Lock In',
      text: `Once you have used all your re-rolls or are happy with all your dice, you can click this button to lock them in and move on to the action phase.`,
      anchor: {
        selector: '.actions .action.btn.lockin',
        position: 'left'
      }
    });
  })
  .then(async () => {
    // wait till action phase
    await listenEvent({ type: 'battleStateChange', value: 'actionPhase' });

    return openTutorial({
      title: 'Battle - Action Phase',
      text: `You have now entered the action phase. Here you can use your dice to perform techniques.`,
    });
  })
  .then(async (skip) => {
    if (skip) return skip;

    return openTutorial({
      title: 'Battle - Action Phase',
      text: `Here is a list of your characters techniques. Techniques that can be activated with the die faces you have locked are highlighted. Click on the technique button to execute it.`,
      anchor: {
        selector: '.skills',
        position: 'center'
      }
    });
  })
  .then(async (skip) => { //use technique
    if (skip) return skip;
    await new Promise<void>(accept => {
      let done = false;
      listenEvent({ type: 'techniqueUsed', value: true })
      .then(() => {
        if(!done){
          done = true;
          accept();
        }
      });
      timeout(3000)
      .then(() => {
        if(!done){
          done = true;
          accept();
        }
      });
    })

    return openTutorial({
      title: 'Battle - Action Phase',
      text: `When a technique is used, it is added to the history bar at the top. You can hover over these items to see when effect the technique had on the battle.`,
      anchor: {
        selector: '.history-container',
        position: 'below'
      }
    });
  })
  .then(async (skip) => {
    if (skip) return skip;

    await timeout(3000);

    return openTutorial({
      title: 'Battle - Action Phase',
      text: `You can click the undo button to undo the technique and any effects it applied, and refund the die.`,
      anchor: {
        selector: '.actions .action.btn.undo',
        position: 'right'
      }
    });
  })
  .then(async (skip) => {
    if (skip) return skip;

    return openTutorial({
      title: 'Battle - Action Phase',
      text: `When you have done all the actions you want to do, you can click this button to end your turn and allow your enemy to take their turn.`,
      anchor: {
        selector: '.actions .action.btn.endturn',
        position: 'left'
      }
    });
  })
  .then(async (skip) => {
    if (skip) return skip;
    await listenEvent({ type: 'battleStateChange', value: 'rolling' });

    return openTutorial({
      title: 'Battle - Enemy Turn',
      text: `The enemy will automatically play out their turn for you to watch.<br/>This is all for the basic battle walkthrough, consult the manual <img src="${getIcon('glossary', { fill: '#fff' })}"> for more information.`,
    });
  });
}

// improvements
// pop in effect
// switch between slides

// explain the user display
// explain the event display
// click button to start event

// event
// explain options
// explain conditions
// explain subtext / consequence

// explain battle node

// battle
// explain dice
// explain dice faces
// explain dice rolls
// explain dice rerolls
// explain dice reroll cost/limit
// explain locking/unlocking dice
// explain lock all
// explain back to die rolling

// explain techniques
// explain technique cost and condition
// explain executing technique
// explain undoing technique
// explain end turn

