import { Modifier } from "../modifier";
import type { Damage } from '../damage.type';
import { healthRemove } from '../player.type';

export class Spikes extends Modifier {
  damageIn(damage: Damage) {
    if(damage.source !== damage.target) {
      healthRemove({
        value: this.stacks,
        target: damage.source,
        source: damage.target,
        dice: []
      });

      this.removeStacks(1);
    }
  }

  startTurn() {
    this.removeStacks(this.stacks);
  }
}