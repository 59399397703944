import { Modifier } from "../modifier";

export class Focus extends Modifier {
  update(stacks: number, params?) {
    super.update(stacks, params);
    this.delay = true;
  }

  startTurn({ activeDie, rerollMax, activePlayer, inactivePlayer }: { activeDie: any; rerollMax: any; activePlayer: any; inactivePlayer: any; }): void | { activeDie: any; rerollMax: any; activePlayer: any; inactivePlayer: any; } {
    this.delay = false;
    return { activeDie, rerollMax, activePlayer, inactivePlayer };
  }

  endTurn() {
    if (!this.delay) {
      this.removeStacks(1);
    }
  }
}