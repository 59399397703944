import type { EnemyDefinition } from "./enemy.type";
import { enemies, bosses, getEnemyDefinition } from "./enemy.type";

let battleArray = [];
export function createBattle(type?: string, level?: number) {
  if (!battleArray.length){
    battleArray = [...enemies];
    //random sort battle Array
    battleArray.sort(() => Math.random() - 0.5);
  }

  var enemy: EnemyDefinition;
  if(type){
    enemy = getEnemyDefinition(type);
  }
  else {
    enemy = battleArray.pop();
  }

  let battle: Battle = { //Battle
    enemy: enemy,
    environment: enemy.environments[Math.floor(Math.random() * enemy.environments.length)],
    level: level || 0,
    type: 'normal',
    outcome: 'active',
  };
  return battle;
}

let bossArray = [];
export function createBossBattle() {
  if (!bossArray.length){
    bossArray = [...bosses];
    //random sort boss Array
    bossArray.sort(() => Math.random() - 0.5);
  }

  const boss = bossArray.pop();

  let battle: Battle = { //Battle
    enemy: boss,
    environment: boss.environments[Math.floor(Math.random() * boss.environments.length)],
    level: 1,
    type: 'boss',
    outcome: 'active',
  };
  return battle;
}

export type Battle = {
  enemy: EnemyDefinition;
  environment: string;
  level: number;
  type: 'normal' | 'boss';
  // modifiers?: Array<string>;
  outcome: 'active' | 'win' | 'lose';
  resolve?: Function;
};
