<script lang='ts'>
  import Icon from "../icon/icon.svelte";
  import Hover from './hover.svelte';
  import Scrollable from './scrollable.svelte';

  export let history;
</script>

<Scrollable>
  <div class='history-container'>
    <div class='history'>
      {#each history as item, index}
        <div class='item {item.player.id} {item.player.archetype || item.player.type} {item.ingredients[0] == "swap" ? "swap" : ""}'>
          <div class='shadow'></div>
          {#if item.ingredients && item.ingredients.length}
            {#each item.ingredients as icon}
              <Icon name={icon}/>
            {/each}
          {/if}

          <Hover>
            <h2>{item.title}</h2>
            <p>{item.text}</p>

            {#if item.actions.length}
              <ul>
              {#each item.actions as text}
                <li>{text}</li>
              {/each}
              </ul>
            {/if}
          </Hover>
        </div>
      {/each}
    </div>
  </div>
</Scrollable>

<style lang='less'>
  @import "../../defines.less";

  :global(.history-container) {
    --item-size: 90px;
    --icon-size: 30px;
  }

  @media @medium{
    :global(.history-container) {
      --item-size: 70px;
      --icon-size: 25px;
    }
  }

  @media @small{
    :global(.history-container) {
      --item-size: 60px;
      --icon-size: 20px;
    }
  }

  .history-container{
    width: 100%;
    padding: 5px;

    &::-webkit-scrollbar {
      width: 0px;
      height: 0px;
    }

    .history{
      display: flex;
      flex-direction: row;
      grid-gap: 10px;
      align-items: center;
      width: fit-content;

      .item{
        width: var(--item-size);
        height: var(--item-size);
        border-radius: 4px;
        display: flex;
        align-items: center;
        justify-content: center;
        position: relative;
        // background: #242424;
        background-position: top center;
        background-size: 200%;

        .shadow{
          position: absolute;
          top: 0;
          bottom: 0;
          left: 0;
          right: 0;
        }

        &.player{
          border: 2px solid var(--player-colour);

          .shadow{
            background-color: var(--player-colour);
            opacity: 0.2;
          }
          &.swap{
            border-right: 2px solid var(--background-colour-light);
            border-bottom: 2px solid var(--background-colour-light);

            .shadow{
              background: linear-gradient(135deg, var(--player-colour), var(--background-colour-light));
              opacity: 0.2;
            }
          }
        }

        &.enemy{
          border: 2px solid var(--background-colour-light);

          .shadow{
            background-color: var(--background-colour-light);
            opacity: 0.2;
          }
          &.swap{
            border-right: 2px solid var(--player-colour);
            border-bottom: 2px solid var(--player-colour);
            .shadow{
              background: linear-gradient(135deg, var(--background-colour-light), var(--player-colour));
              opacity: 0.2;
            }
          }
        }

        h2, p{
          line-height: 1;
        }

        ul{
          line-height: initial;
          list-style-position: inside;
          padding: 0;
        }
      }
    }
  }
</style>