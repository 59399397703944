<script lang='ts'>
  import { confirmData } from './modals';

  let onConfirm = null;

  confirmData.subscribe(value => {
    if(value && value.callbackConfirm){
      onConfirm = value.callbackConfirm;
    }
    else{
      onConfirm = null;
    }
  });

  const confirm = () => {
    if(onConfirm){
      onConfirm();
    }
    confirmData.set(null);
  }

  const cancel = () => {
    confirmData.set(null);
  }
</script>

{#if $confirmData}
<div class='modal-container'>
  <div class='modal'>
    {#if $confirmData.title}
      <div class='header'>
        <h1>{$confirmData.title}</h1>
      </div>
    {/if}

    {#if $confirmData.text}
      <div class='body'>
        <p>{$confirmData.text}</p>
      </div>
    {/if}

    <div class='functions'>
      <button class='btn' on:click={cancel}>Cancel</button>
      <button class='btn' on:click={confirm}>Confirm</button>
    </div>
  </div>
</div>
{/if}

<style lang='less'>
</style>