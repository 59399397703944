import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Die } from '../die.type';
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import { getAmmoEffect, hasAmmo, shootAmmo } from './ammo';
import { Stances } from '../status-effects/stance';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';

export const crossbow: Technique[] = [
  {
    requirements: [hasAmmo],
    ingredients: [DieFace.Crossbow],
    title: 'Pikow',
    text: 'Shoot one stored Ammo, Deal 1 pure damage.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const effect = getAmmoEffect(target, source, dice);
      shootAmmo(effect, target);

      healthRemove({ value: 1, target, source, dice });
    }
  }, {
    requirements: [hasAmmo],
    ingredients: [DieFace.Crossbow, DieFace.Crossbow],
    title: 'Thwunk',
    text: 'Shoot 1 stored Ammo, increasing damage by 2. Deal 2 pure damage',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const effect = getAmmoEffect(target, source, dice);
      effect.damages.forEach(damage => {
        damage.value += 2;
      });
      shootAmmo(effect, target);

      healthRemove({ value: 2, target, source, dice });
    }
  }, {
    requirements: [hasAmmo],
    ingredients: [DieFace.Crossbow, DieFace.Crossbow, DieFace.Crossbow],
    title: 'Kachonk',
    text: 'Shoot every stored ammo.',
    tags: ['damage'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const Ammo = source.statusEffects.find(modifier => {
        return modifier.type == 'ammo';
      });
      while(Ammo.stacks > 0){
        const effect = getAmmoEffect(target, source, dice);
      shootAmmo(effect, target);
      }
    }
  }, {
    ingredients: [DieFace.Crossbow, DieFace.Fist],
    title: 'Rifle Butt',
    text: 'Deal 1 damage, apply 1 Dopey.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Dopey, 1);
    }
  }, {
    ingredients: [DieFace.Crossbow, DieFace.Shield],
    title: 'Plant Pavise',
    text: 'Gain 2 Block and 1 Armoured.',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Block, 2);
      addModifier(source, StatusEffect.Armoured, 1);
    }
  }, {
    ingredients: [DieFace.Crossbow, DieFace.Reflex],
    title: 'Rapid Reload',
    text: 'Gain 1 Twincast.',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.TwinSpell, 1);
    }
  }, {
    ingredients: [DieFace.Crossbow, DieFace.Bone],
    title: 'Brace for Recoil',
    text: 'Gain 1 accuracy and 1 focus.',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Accuracy, 1);
      addModifier(source, StatusEffect.Focus, 1);
    }
  },

];
