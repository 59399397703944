import type { itemDefinition } from '../item.type';
import { type Player, dealDamage, healDamage, hasItem, removeItem, addModifier } from "../player.type";
import { invokeDie } from "../die.type";
import { Item, Items } from '../item.type';
import type { Technique } from '../techniques.type';
import type { Die } from '../die.type';
import { StatusEffect } from '../status-effects';

export const consumables: Array<itemDefinition> = [
  // venom sac that grants poison imbue
  {
    type: Items.BombSmoke,
    title: 'Smoke Bomb',
    text: 'A smoke bomb, deployed in battle to envelop the battlefield in a dense fog, concealing the user.',
    icon: 'bomb',
    bonusIcon: 'stealth',
    class: class asd extends Item {
      getTechniques(): Array<Technique> {
        return [{
          requirements: [
            {
              text: 'Consumes Smoke Bomb',
              icon: 'bomb',
              test: (source: Player) => {
                return hasItem(source, Items.BombSmoke);
              }
            }
          ],
          ingredients: [],
          title: 'Toss Smoke Bomb',
          text: 'Gain 2 Stealth and 2 Dodge. Apply 2 Blind. Consume Smoke Bomb.',
          tags: ['consumable', 'defence'],
          activate: (target: Player, source: Player, dice: Array<Die>) => {
            addModifier(source, StatusEffect.Stealth, 2);
            addModifier(source, StatusEffect.Dodge, 2);
            addModifier(target, StatusEffect.Blind, 2);

            removeItem(source, Items.BombSmoke);
          }
        }];
      }
    },
  }, {
    type: Items.BombFlash,
    title: 'Flash Bomb',
    text: 'A flash bomb, deployed in battle to blind and disorient foes.',
    icon: 'bomb',
    bonusIcon: 'blind',
    class: class extends Item {
      getTechniques(): Array<Technique> {
        return [{
          requirements: [
            {
              text: 'Consumes Flash Bomb',
              icon: 'bomb',
              test: (source: Player) => {
                return hasItem(source, Items.BombFlash);
              }
            }
          ],
          ingredients: [],
          title: 'Toss Flash Bomb',
          text: 'Apply 3 Blind and 2 Stunned. Consume Flash Bomb.',
          tags: ['consumable', 'debuff'],
          activate: (target: Player, source: Player, dice: Array<Die>) => {
            addModifier(target, StatusEffect.Blind, 3);
            addModifier(target, StatusEffect.Stunned, 2);

            removeItem(source, Items.BombFlash);
          }
        }];
      }
    }
  }, {
    type: Items.BombFire,
    title: 'Fire Bomb',
    text: 'A fire bomb, deployed in battle to burn and scorch foes.',
    icon: 'bomb',
    bonusIcon: 'burning',
    class: class extends Item {
      getTechniques(): Array<Technique> {
        return [{
          requirements: [
            {
              text: 'Consumes Fire Bomb',
              icon: 'bomb',
              test: (source: Player) => {
                return hasItem(source, Items.BombFire);
              }
            }
          ],
          ingredients: [],
          title: 'Toss Fire Bomb',
          text: 'Deal 3 Damage, Apply 3 Burning. Consume Fire Bomb.',
          tags: ['consumable', 'damage'],
          activate: (target: Player, source: Player, dice: Array<Die>) => {
            dealDamage({ value: 3, target, source, dice });
            addModifier(target, StatusEffect.Burning, 3);

            removeItem(source, Items.BombFire);
          }
        }];
      }
    }
  }, {
    type: Items.BombSticky,
    title: 'Sticky Bomb',
    text: 'A sticky bomb, deployed in battle to slow and immobilize foes.',
    icon: 'bomb',
    bonusIcon: 'web',
    class: class extends Item {
      getTechniques(): Array<Technique> {
        return [{
          requirements: [
            {
              text: 'Consumes Sticky Bomb',
              icon: 'bomb',
              test: (source: Player) => {
                return hasItem(source, Items.BombSticky);
              }
            }
          ],
          ingredients: [],
          title: 'Toss Sticky Bomb',
          text: 'Apply 2 Snared and 2 Vulnerable. Consume Sticky Bomb.',
          tags: ['consumable', 'debuff'],
          activate: (target: Player, source: Player, dice: Array<Die>) => {
            addModifier(target, StatusEffect.Snared, 2);
            addModifier(target, StatusEffect.Vulnerable, 2);

            removeItem(source, Items.BombSticky);
          }
        }];
      }
    }
  }
];