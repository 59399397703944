import { Modifier, type ModifierParams } from "../modifier";
import type { Damage } from '../damage.type';
import { addModifier, type Player } from '../player.type';
import { actionHistory } from '../../../store';
import type { StatusEffect, StatusEffectDefinition } from "../status-effects";
import type { Die } from "../die.type";
import { modifiers } from "../status-effects";

export class Delay extends Modifier {

  init(params?: ModifierParams) {
    this.properties.imbue = params.imbue;
    this.bonusIcon = modifiers[this.properties.imbue].icon;
    this.tags = [...modifiers[this.properties.imbue].tags];
    super.init(params);
  }

  update(stacks: number, params) {
    if (this.properties.imbue !== params.imbue) {
      actionHistory.push(`${this.owner.title} changed delay from ${this.properties.imbue} to ${params.imbue}.`);
      this.properties.imbue = params.imbue;
      this.title = `${this.type} (${this.properties.imbue})`;
      this.bonusIcon = modifiers[this.properties.imbue].icon;
      this.stacks = 0;
    }

    if (stacks) {
      this.stacks += stacks;
      actionHistory.push(`Added ${stacks} ${this.type} to ${this.owner.title}.`);
    }

    this.tags = [...modifiers[this.properties.imbue].tags];
  }

  startTurn({ activeDie, rerollMax, activePlayer, inactivePlayer }: { activeDie: any; rerollMax: any; activePlayer: any; inactivePlayer: any; }): void | { activeDie: Die[]; rerollMax: number; activePlayer: Player; inactivePlayer: Player; } {
    addModifier(activePlayer, this.properties.imbue as StatusEffect, this.stacks);

    this.removeStacks(this.stacks);
  }

  getHTML(): string {
    let description: StatusEffectDefinition = modifiers[this.properties.imbue];
    return `
      <h3> ${this.properties.imbue} </h3>
      <p> ${description.text} </p>
    `;
  }
}