<script lang='ts'>
  import DiceTray from '../dice-tray/dice-tray.svelte';
  import { selectDieData } from './modals';

  let onConfirm = null;
  let die = null;

  selectDieData.subscribe(value => {
    if(value && value.callback){
      onConfirm = value.callback;
    }
    else{
      onConfirm = null;
    }
  });

  const confirm = () => {
    die.selected = false;
    if(onConfirm){
      onConfirm(die);
    }
    selectDieData.set(null);
  }

  function chooseDie(selectedDie){
    if(selectedDie.used){
      return;
    }
    $selectDieData.dice.forEach(die => {
      die.selected = false;
    });

    selectedDie.selected = true;
    die = selectedDie;

    $selectDieData = $selectDieData;
  }
</script>

{#if $selectDieData}
<div class='modal-container'>
  <div class='modal'>
    <div class='header'>
      <h1>Select a Die</h1>
    </div>

    <div class='body'>
      <DiceTray
        dice={$selectDieData.dice}
        clickEvent="{(die) => {chooseDie(die)}}"
        class='horizontal'
      />
    </div>

    <div class='functions'>
      <button class='btn' on:click={confirm}>Confirm</button>
    </div>
  </div>
</div>
{/if}

<style lang="less">

</style>