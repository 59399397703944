export enum Events {
  SentinelSanctuary = 'Sentinel\'s Sanctuary',
  VeilLamentations = 'Veil of Lamentations',
  ElementalCorestone = 'Elemental Corestone',
  EnigmaticElixir = 'Enigmatic Elixir',
  GreatLibrary = 'The Great Library',

  Campfire = 'Campfire',

  SkyPerch = 'Sky Perch',
  AetherwoodApex = 'Aetherwood Apex',
  Signpost = 'Signpost',
  RavenWatch = 'RavenWatch',
  TravellingCartographer = 'TravellingCartographer',

  SalesmanPotion = 'SalesmanPotion',
  SalesmanBomb = 'SalesmanBomb',


  Prologue1 = 'Prologue1',
  Prologue2 = 'Prologue2',
  Prologue3 = 'Prologue3',
  Prologue4 = 'Prologue4',
  Prologue5 = 'Prologue5',
  Prologue6 = 'Prologue6',
}

export enum EventType {
  Event = 'event',
  Campfire = 'campfire',
  Scout = 'scout',
  Vendor = 'vendor',
}
