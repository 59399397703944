import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import { invokeDie } from '../die.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import type { Infusion } from '../status-effects/infusion';
import { DieFace } from '../face.type';
import { StatusEffect } from '../status-effects';


export const fire: Technique[] = [
  {
    ingredients: [DieFace.Fire],
    requirements: [{
      text: 'No Infusion other than fire',
      iconBonus: 'no',
      icon: 'infusion',
      test: (source: Player, target: Player) => {
        const infusion = source.statusEffects.find((modifier: Infusion) => {
          return modifier.type == 'infusion' && modifier.infusion != 'fire';
        });
        return !infusion;
      }
    }],
    title: 'Elemental Infusion (fire)',
    text: 'Gain 1 Infusion (fire).',
    tags: ['buff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Infusion, 1, { infusion: DieFace.Fire });
    }
  }, {
    ingredients: [DieFace.Fire, DieFace.Mana],
    title: 'Flame Strike',
    text: 'Deal 1 damage, Apply 2 Burning.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 1, target, source, dice });
      addModifier(target, StatusEffect.Burning, 2);
    }
  }, {
    ingredients: [DieFace.Fire, DieFace.Fire],
    title: 'Unstable Fireball',
    text: 'Deal 3 damage, Apply 3 Burning. Take 1 damage, Gain 2 Burning.',
    tags: ['damage', 'debuff', 'suffer'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
      addModifier(target, StatusEffect.Burning, 3);

      dealDamage({ value: 1, target: source, source, dice });
      addModifier(source, StatusEffect.Burning, 2);
    }
  }, {
    ingredients: [DieFace.Fire, DieFace.Fire, DieFace.Mana],
    title: 'Focused Fireball',
    text: 'Deal 3 damage, Apply 3 Burn and Purge 1.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 3, target, source, dice });
      addModifier(target, StatusEffect.Burning, 3);

      const boon = target.statusEffects.find(modifier => {
        return modifier.tags.includes('boon');
      });

      if (boon) {
        boon.removeStacks(boon.stacks);
      }
    }
  }, {
    ingredients: [DieFace.Fire, DieFace.Blood],
    title: 'Causterise Wound',
    text: 'If Bleeding heal for bleeding stacks, remove Bleeding. Otherwise heal 2.',
    tags: ['heal'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const bleeding = source.statusEffects.find(modifier => {
        return modifier.type == 'bleeding';
      });

      if (bleeding) {
        const stacks = bleeding.stacks;
        bleeding.removeStacks(stacks);
        healDamage({ value: stacks, target: source, source, dice });
      }
      else {
        healDamage({ value: 2, target: source, source, dice });
      }
    }
  }, {
    ingredients: [DieFace.Fire, DieFace.Brain],
    title: 'Cleansing Fire',
    text: 'Cleanse 1 Self.',
    tags: ['cleanse'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const bane = source.statusEffects.find(modifier => {
        return modifier.tags.includes('bane');
      });

      if (bane) {
        bane.removeStacks(bane.stacks);
      }
    }
  }, {
    ingredients: [DieFace.Fire, DieFace.Fist],
    title: 'Fire Punch',
    text: 'Deal 2 damage, Apply 1 Burning and 1 Clumsy.',
    tags: ['damage', 'debuff'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      dealDamage({ value: 2, target, source, dice });
      addModifier(target, StatusEffect.Burning, 1);
      addModifier(target, StatusEffect.Clumsy, 1);
    }
  },
  /* {
    ingredients: ['fire', 'blood'],
    title: 'Causterise Wound',
    text: 'If Bleeding heal for bleeding stacks, remove Bleeding. Else heal 2.',
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      const bleeding = source.modifiers.find(modifier => {
        return modifier.type == 'bleeding'
      });

      if (bleeding) {
        const stacks = bleeding.stacks;
        bleeding.removeStacks(stacks);
        healDamage({ value: stacks, target: source, source });
      }
      else {
        healDamage({ value: 2, target: source, source });
      }
    }
  }, */
];
