import { Modifier, type ModifierParams } from "../modifier";
import type { Damage } from '../damage.type';
import { addModifier, dealDamage, healthRemove } from '../player.type';
import { actionHistory } from '../../../store';
import type { Player } from '../player.type';
import type { Technique } from "../techniques.type";
import { DieFace } from "../face.type";
import { StatusEffect } from "../status-effects";

export enum Stances {
  Retaliate = 'Retaliate', // on attacked, attack back
  Parry = 'Parry', // on attacked, negate damage and attack back
  Interrupt = 'Interrupt', // on enemy buff/debuff, negate defence and attack
  Deflect = 'Deflect', // on attacked, gain block
  Counterspell = 'Counterspell', // on enemy using mana, negate technique and gain infusion (mana)
  Prepared = 'Prepared', // on enemy using defence, attack before the defence resolves
  // Aggression = 'Aggression', // when attacking, gain 1 temp strength
}

export const definitions = {
  [Stances.Retaliate]: {
    text: 'When attacked, deal 3 damage.',
  },
  [Stances.Parry]: {
    text: 'When attacked, negate damage and deal 2 damage.',
  },
  [Stances.Interrupt]: {
    text: 'When enemy uses a buff/debuff, prevent the technique and deal 2 damage.',
  },
  [Stances.Deflect]: {
    text: 'When attacked, gain 4 block.',
  },
  [Stances.Counterspell]: {
    text: 'When enemy uses mana, prevent the technique and gain 1 Infusion (Mana).',
  },
  [Stances.Prepared]: {
    text: 'When enemy uses a defence, deal 4 damage before the defence resolves.',
  },
};

export class Stance extends Modifier {
  stance: Stances;

  clone(newOwner: Player) {
    var clone = super.clone(newOwner);
    clone.stance = this.stance;

    return clone;
  }

  init(params?: ModifierParams) {
    if (actionHistory) {
      actionHistory.push(`${this.owner.title} gained stance ${this.stance}.`);
    }
    this.update(0, params);
  }

  update(stacks: number, params: ModifierParams) {
    if(params.stance !== this.stance){
      actionHistory.push(`${this.owner.title} changed stance from ${this.stance} to ${params.stance}.`);
      this.stance = params.stance;
      this.title = `${this.type} (${this.stance})`;
      this.bonusIcon = this.stance;
    }

    this.stacks = 1;
  }

  canInterrupt(technique: Technique, player: Player, enemy: Player): { able: boolean } {
    switch(this.stance){
      case Stances.Retaliate:
        if (technique.tags.includes('attack')) {
          dealDamage({ value: 4, target: player, source: enemy, dice: [] });
          this.removeStacks(1);
          return { able: true };
        }
        break;

      case Stances.Parry:
        if (technique.tags.includes('attack')) {
          dealDamage({ value: 2, target: player, source: enemy, dice: [] });
          this.removeStacks(1);
          return { able: false };
        }
        break;

      case Stances.Interrupt:
        if (technique.tags.includes('debuff') || technique.tags.includes('buff')) {
          dealDamage({ value: 2, target: player, source: enemy, dice: [] });
          this.removeStacks(1);
          return { able: false };
        }
        break;

      case Stances.Deflect:
        if (technique.tags.includes('attack')) {
          addModifier(enemy, StatusEffect.Block, 4);
          this.removeStacks(1);
          return { able: true };
        }
        break;

      case Stances.Counterspell:
        if (technique.ingredients.includes(DieFace.Mana)) {
          addModifier(enemy, StatusEffect.Infusion, 1, { infusion: DieFace.Mana });
          this.removeStacks(1);
          return { able: false };
        }
        break;

      case Stances.Prepared:
        if (technique.tags.includes('defence')) {
          dealDamage({ value: 4, target: player, source: enemy, dice: [] });
          this.removeStacks(1);
          return { able: true };
        }
        break;
    };

    return {
      able: true
    };
  }

  getHTML(): string {
    let definition = definitions[this.stance];
    return `
      <h3> ${this.stance} </h3>
      <p> ${definition.text} </p>
    `;
  }
}