import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import type { Technique, Requirement } from "../techniques.type";
import type { Player } from '../player.type';
import { getAmmoEffect, shootAmmo } from './ammo';
import { DieFace } from '../face.type';
import { StatusEffect } from '../status-effects';

export const instant: Technique[] = [
  {
    ingredients: [DieFace.Shiv],
    title: 'Shiv',
    text: 'Instant. Deal 2 Damage. Change face to broken.',
    tags: ['damage', 'instant'],
    activate: (target, source, dice) => {
      dealDamage({ value: 2, target, source, dice });
      if (dice.activeFace) {
        dice.faces[dice.activeFace] = [DieFace.Broken];
      }
    }
  },{
    ingredients: [DieFace.Shiv, DieFace.Shiv],
    title: 'Shiv+',
    text: 'Instant. Deal 3 Damage. Change face to shiv.',
    tags: ['damage', 'instant'],
    activate: (target, source, dice) => {
      dealDamage({ value: 3, target, source, dice });
      if (dice.activeFace) {
        dice.faces[dice.activeFace] = [DieFace.Shiv];
      }
    }
  },{
    ingredients: [DieFace.Dart],
    title: 'Dart',
    text: 'Instant. With Ammo, shoot ammo. Else deal 1 Damage. Change face to blank.',
    tags: ['damage', 'instant'],
    activate: (target, source, dice) => {
      const ammo = source.statusEffects.find(modifier => {
        return modifier.type == StatusEffect.Ammo;
      });
      if (ammo) {
        const effect = getAmmoEffect(target, source, dice);
        shootAmmo(effect, target);
      } else {
        dealDamage({ value: 1, target, source, dice });
      }
      if (dice.activeFace){
        dice.faces[dice.activeFace] = [DieFace.Blank];
      }
    }
  },
];
