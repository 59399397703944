<script lang='ts'>
  import { keywords } from '../models/keywords';
  import { modifiers } from '../models/status-effects';
  import { options } from '../../store';
  import Hover from './hover.svelte';
  import Icon from "../icon/icon.svelte";

  export let data: string = '';

  const keywordsArray = Object.keys(keywords);
  const modifiersArray = Object.keys(modifiers);

  let pattern = new RegExp('^[^a-zA-Z0-9]*?('+ modifiersArray.join('|')+')[^a-zA-Z0-9]*?$', 'i');
  if($options.tooltipsKeywords){
    pattern = new RegExp('^[^a-zA-Z0-9]*?('+keywordsArray.join('|').replace('(', '\\(').replace(')','\\)') +'|'+ modifiersArray.join('|')+')[^a-zA-Z0-9]*?$', 'i');
  }
  // console.log(pattern)


  // if two words that do not match the pattern are together, join them with a space
  /* for(let i = 0; i < dataSplit.length; i++){
    if(!pattern.test(dataSplit[i]) && (dataSplit[i+1] && !pattern.test(dataSplit[i+1]))){
      dataSplit[i] = dataSplit[i] + ' ' + dataSplit[i+1];
      dataSplit.splice(i+1, 1);
      i--;
    }
    else if(!pattern.test(dataSplit[i])){
      dataSplit[i] += ' ';
    }
    else if(pattern.test(dataSplit[i]) && dataSplit[i+1]){
      dataSplit.splice(i+1, 0, ' ');
    }
    else{
    }
  } */

  let dataSplit: Array<string> = [];
  // if data is updated
  $: if(data){
    // Split data string into substrings around matches in the keywords array including the match
    dataSplit = data.split(/\s(?![^()]*\))/g);

    // if the first character of any item in data is a '(', join it with the previous word
    for(let i = dataSplit.length-1; i > 0; i--){
      if(dataSplit[i].charAt(0) == '(' && dataSplit[i-1]){
        dataSplit[i-1] = dataSplit[i-1] + ' ' + dataSplit[i];
        dataSplit.splice(i, 1);
        i--;
      }
    }
  }

</script>

<section class={$$props.class}>
	<p class='p'>
    {#each dataSplit as text}
      {#if $options.tooltipsKeywords && keywordsArray.includes(text.toLowerCase().replace(/\,|\./g, ''))}
        <span class='keyword'>
          {text}<Hover><div class='description'><h2>{text}</h2><br/><p>{keywords[text.toLowerCase().replace(/\,|\./g, '')]}</p></div></Hover></span>&nbsp;
      {:else if modifiersArray.includes(text.toLowerCase().replace(/\,|\.|\(|\)/g, ''))}
        <span class='keyword'>
          {text}<Hover><div class='description'><div class='icon-container'><Icon name={modifiers[text.toLowerCase().replace(/\,|\.|\(|\)/g, '')].icon} width='40px' height='40px' class='icon'/></div><h2>{text.replace(/\,|\.|\(|\)/g, '')}</h2><br/><p>{modifiers[text.toLowerCase().replace(/\,|\.|\(|\)/g, '')].text}</p></div></Hover></span>&nbsp;
      {:else}
        <span>{text} </span>
      {/if}
    {/each}
  </p>
</section>


<style lang='less'>
  p{
    line-height: 1.5;
    margin: 0;
    font-weight: normal;
  }

  .keyword{
    position: relative;
    text-decoration: underline;
    font-weight: bold;
    display: inline-block;
    // border-bottom: 2px solid white;
    // padding-bottom: 2px;
    /* font-weight: bold; */
  }

  .description{
    text-align: center;

    h2{
      text-transform: capitalize;
    }

    .icon-container{
      margin-bottom: 10px;
    }
  }
</style>