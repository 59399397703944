<script lang="ts">
  import { screens, playerTruth, version, glossary } from '../store';
  import Die from './dice-tray/die.svelte';
  import { createDie } from "./models/die.type";
  import type { Die as DieType } from "./models/die.type";
  import { createPlayerInstance } from "./models/player.type";
  import Portrait from "./components/portrait.svelte";
  import { send, receive } from '../animation';
  import { type Player, addItem } from "./models/player.type";
  import Icon from './icon/icon.svelte';
  import { openOptions } from './modals/modals';
  import { createBattle } from './models/battle.type';
  import { createCampaign } from './utils/map';
  import { createPrologue } from './utils/prologue';
  import Text from './components/text.svelte';
  import { Items } from './models/item.type';
  import { track } from './utils/playerTracking';
  import { activateTutorial } from './utils/tutorial';

  let selected;
  let slidesClass: Array<any> = [];
  let slidesArchetype: Array<any> = [];
  let dice:  Array<Array<DieType>> = [];
  let state = 'class';

  let classes: Array<Player> = [
    createPlayerInstance('fighter'),
    createPlayerInstance('rogue'),
    createPlayerInstance('ranger'),
    createPlayerInstance('elementalist')
  ];
  let archetypes: Array<Player> = [];

  $: if(slidesClass.length) {
    slidesClass.forEach((slide, index) => {
      slide.index = index;
      slide.classList.add(`slide-${index}`);
    });
  }
  $: if(slidesArchetype.length) {
    slidesArchetype.forEach((slide, index) => {
      slide.index = index;
      slide.classList.add(`slide-${index}`);
    });
  }

  /* function openGlossary(){
    glossary.open.set(true);
  } */

  const select = (player: Player) => {
    selected = player;

    const root: HTMLElement = document.querySelector(':root');
    root.style.setProperty('--player-colour', selected.colour);
  }
  select(classes[1]);

  const selectClass = () => {
    state = 'archetype';

    archetypes = selected.archetypes.map(archetype => {
      const player = createPlayerInstance(selected.type, archetype.id);
      player.title = archetype.title;
      player.text = archetype.text;

      return player;
    });

    select(archetypes[1]);
  };

  const selectArchetype = () => {
    state = 'summary';
    var index = archetypes.indexOf(selected);

    dice[0] = [createDie(selected.dice1[index], 1, 'p'+1)];
    dice[1] = [createDie(selected.dice2[index], 2, 'p'+2)];
    dice[2] = [createDie(selected.dice3[index], 3, 'p'+3)];
  };

  const goDice = () => {
    state = 'dice';
  };
  const selectDice = () => {
    state = 'summary';
  };

  const goPrev = (slides, objects) => {
    slides.forEach((slide, i) => {
      slide.classList.remove('slide-'+slide.index);

      var prev = slide.index + 1;
      if(prev >= slides.length){
        prev = 0;
      }

      if(prev == 1){
        select(objects[i]);
      }

      slide.classList.add('slide-'+prev);
      slide.index = prev;
    });
  };

  const goNext = (slides, objects) => {
    slides.forEach((slide, i) => {
      slide.classList.remove('slide-'+slide.index);

      var next = slide.index - 1;
      if(next < 0){
        next = slides.length-1;
      }

      if(next == 1){
        select(objects[i]);
      }

      slide.classList.add('slide-'+next);
      slide.index = next;
    });
  };

  const prevDie = (index) => {
    var pos = selected['dice'+(index+1)].indexOf(dice[index][0].type) - 1;

    if(pos < 0){
      pos = selected['dice'+(index+1)].length - 1;
    }

    dice[index] = [createDie(selected['dice'+(index+1)][pos], 1, 'p'+Math.random())];
  };
  const nextDie = (index) => {
    var pos = selected['dice'+(index+1)].indexOf(dice[index][0].type) + 1;

    if(pos >= selected['dice'+(index+1)].length){
      pos = 0;
    }

    dice[index] = [createDie(selected['dice'+(index+1)][pos], 1, 'p'+Math.random())];
  };

  const viewTechniques = () => {
    glossary.open.set(true);
    glossary.selected.set('techniques');
    glossary.searchDie.set(dice.map(die => die[0]));
  };

  function createCharacter(){
    $playerTruth = createPlayerInstance(selected.type, selected.archetype);
    if($playerTruth){
      $playerTruth.dice = [];
      dice.forEach((die, index) => {
        $playerTruth.dice[index*2] = die[0].label;
        $playerTruth.dice[index*2+1] = die[0].label;
      });
    }
  }

  function startPrologue(){
    createCharacter();

    let map = createCampaign();
    let prologueMap = createPrologue();

    screens.set('map', {map});
    screens.push('map', {map: prologueMap});
    screens.push('event', {event: prologueMap.nodes[0].event});

    track('Create Character', {
      class: selected.type,
      archetype: selected.archetype,
      dice: dice.map(die => die[0].label).join(','),
      starting: 'prologue'
    });

    activateTutorial();
  }

  function startPractice(){
    createCharacter();

    screens.push('battlestart', {battle: createBattle('training-dummy')});
  }

  function startCampaign(){
    createCharacter();
    addItem($playerTruth, Items.PotionHealth);

    let map = createCampaign();

    screens.set('map', {map: map});

    track('Create Character', {
      class: selected.type,
      archetype: selected.archetype,
      dice: dice.map(die => die[0].label).join(','),
      starting: 'campaign'
    });
  }

  function back(){
    switch(state){
      case 'class':
        screens.set('menu');
        break;
      case 'archetype':
        state = 'class';
        select(classes.find(classs => classs.type == selected.type));
        break;
      case 'dice':
        state = 'summary';
        break;
      case 'summary':
        state = 'archetype';
        break;
    }
  }
</script>

<div class='container {state == 'class' ? 'visible' : 'hidden' }'>
  <div class='frame'>
    <h1>Choose a Class</h1>
    <div>
      <div class='character-select'>
        <button on:click={()=>{goPrev(slidesClass, classes)}}>
          <Icon name='prevarrow' width='50px' height='50px'/>
        </button>
        <div class='characters'>
          {#each classes as player, index}
            <div class='slide'
              bind:this="{slidesClass[index]}"
            >
              <div class='character {selected == player ? "selected":"unselected"}' style='--player-colour:{player.colour}'>
                <Portrait data={player}/>
              </div>
            </div>
          {/each}

          {#if false}
            <div class='slide slide-0 slide-1 slide-2'/>
          {/if}
        </div>
        <button on:click={()=>{goNext(slidesClass, classes)}}>
          <Icon name='nextarrow' width='50px' height='50px'/>
        </button>
      </div>
      <div class='description'>
        {#if selected}
          <h1>{selected.title}</h1>
          <p>{selected.text}</p>
        {/if}
        <div class='button-container'>
          <button class='btn start' on:click={back}>
            Back
          </button>
          <button class='btn start' on:click={selectClass}>
            Select
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class='container {state == 'archetype' ? 'visible' : 'hidden' }'>
  <div class='frame'>
    <h1>Choose an Archetype</h1>
    <div class='character-select'>
      <button on:click={()=>{goPrev(slidesArchetype, archetypes)}}>
        <Icon name='prevarrow' width='50px' height='50px'/>
      </button>
      <div class='characters'>
        {#each archetypes as player, index}
          <div class='slide'
            bind:this="{slidesArchetype[index]}"
          >
            <div class='character {selected == player ? "selected":"unselected"}' style='--player-colour:{player.colour}'>
              <Portrait data={player}/>
            </div>
          </div>
        {/each}
      </div>
      <button on:click={()=>{goNext(slidesArchetype, archetypes)}}>
        <Icon name='nextarrow' width='50px' height='50px'/>
      </button>
    </div>
    <div class='description'>
      {#if selected}
        <h1>{selected.title}</h1>
        <p>{selected.text}</p>

        <h2>{selected?.traits[0]?.title}</h2>
        <Text class='text' data={selected?.traits[0]?.text}/>
      {/if}
    </div>
    <div class='button-container'>
      <button class='btn start' on:click={back}>
        Back
      </button>
      <button class='btn start' on:click={selectArchetype}>
        Select
      </button>
    </div>
  </div>
</div>

<div class='container {state == 'dice' ? 'visible' : 'hidden' }'>
  <div class='frame'>
    <h1>Choose your Equipment</h1>

    <div class='description'>
      {#if dice.length}
      <div class='die-list'>
        <div class='die-title'>
          <h3>Weapon:</h3>
          <h3>{dice[0][0].type}</h3>
        </div>
        <div class='die-scroll'>
          <button on:click={()=>{prevDie(0)}}>
            <Icon name='prevarrow' width='30px' height='30px'/>
          </button>
          <div class='die-container-outer'>
            {#each dice[0] as die, index (die.id)}
              <div class='die-container'
                in:receive="{{key: die.id}}"
                out:send="{{key: die.id}}"
              >
                <Die die={die} index={die.index} die3D={false} hover={true}/>
              </div>
            {/each}
          </div>
          <button on:click={()=>{nextDie(0)}}>
            <Icon name='nextarrow' width='30px' height='30px'/>
          </button>
        </div>

        <div class='die-title'>
          <h3>Item:</h3>
          <h3>{dice[1][0].type}</h3>
        </div>
        <div class='die-scroll'>
          <button on:click={()=>{prevDie(1)}}>
            <Icon name='prevarrow' width='30px' height='30px'/>
          </button>
          <div class='die-container-outer'>
            {#each dice[1] as die, index (die.id)}
              <div class='die-container'
                in:receive="{{key: die.id}}"
                out:send="{{key: die.id}}"
              >
                <Die die={dice[1][0]} index={dice[1][0].index} die3D={false} hover={true}/>
              </div>
            {/each}
          </div>
          <button on:click={()=>{nextDie(1)}}>
            <Icon name='nextarrow' width='30px' height='30px'/>
          </button>
        </div>

        <div class='die-title'>
          <h3>Ability:</h3>
          <h3>{dice[2][0].type}</h3>
        </div>
        <div class='die-scroll'>
          <button on:click={()=>{prevDie(2)}}>
            <Icon name='prevarrow' width='30px' height='30px'/>
          </button>
          <div class='die-container-outer'>
            {#each dice[2] as die, index (die.id)}
              <div class='die-container'
                in:receive="{{key: die.id}}"
                out:send="{{key: die.id}}"
              >
                <Die die={dice[2][0]} index={dice[2][0].index} die3D={false} hover={true}/>
              </div>
            {/each}
          </div>
          <button on:click={()=>{nextDie(2)}}>
            <Icon name='nextarrow' width='30px' height='30px'/>
          </button>
        </div>
      </div>
      {/if}

      <button class='btn' on:click={viewTechniques}>
        View Techniques
      </button>
    </div>

    <div class='button-container'>
      <!-- <button class='btn start' on:click={back}>
        Back
      </button> -->
      <button class='btn start' on:click={selectDice}>
        Select
      </button>
    </div>
  </div>
</div>

<div class='container {state == 'summary' ? 'visible' : 'hidden' }'>
  <div class='frame'>

    <div class='buttons'>
      <div class='summary'>
        <Portrait data={selected}/>
        {#if state == 'summary'}
        <div class='dice'>
          <div class='die-container'>
            <Die die={dice[0][0]} index={dice[0][0]?.index} die3D={false} hover={true}/>
          </div>
          <div class='die-container'>
            <Die die={dice[1][0]} index={dice[1][0]?.index} die3D={false} hover={true}/>
          </div>
          <div class='die-container'>
            <Die die={dice[2][0]} index={dice[2][0]?.index} die3D={false} hover={true}/>
          </div>
          <button on:click={goDice}>Customise</button>
        </div>
        {/if}
      </div>

      <button class='btn' on:click={startPrologue}>Prologue/Tutorial</button>
      <button class='btn' on:click={startPractice}>Practice</button>
      <button class='btn' on:click={startCampaign}>Campaign</button>
      <button class='btn' on:click={back}>Back</button>

    </div>
  </div>
</div>

<style lang='less'>
  @import "../defines.less";

  .container{
    display: grid;
    height: 100%;
    width: 100%;
    align-items: center;
    background-image: url('../assets/backgrounds/camp.webp');
    background-position: center;
    background-size: cover;
    text-shadow: 2px 2px black;
    text-align: center;
  }
  .frame{
    background: rgba(0,0,0,0.5);
    margin: auto;
    border-radius: 8px;
    padding: 20px;

    @media @small{
      width: min(900px, 85vw);
    }
  }

  .character-select{
    max-width: 1000px;
    width: 100%;
    margin: 20px auto;
    display: grid;
    grid-template-columns: 100px 1fr 100px;
    align-items: center;

    button{
      z-index: 111;
      background-color: transparent;
      box-shadow: none;
      height: 100%;

      &:hover,
      &:focus{
        border-color: transparent;
        outline: none;
      }
    }

    .characters{
      width: 100%;
      perspective: 1000px;
      height: var(--column-size);
      transform-style: preserve-3d;

      .slide{
        width: 100%;
        position: absolute;
        transition: left 0.5s ease-in-out, transform 0.5s ease-in-out, opacity 0.5s;
        background-position: center;
        background-size: contain;
        background-repeat: no-repeat;

        top: 0;
        bottom: 0;
        transform-origin: center center;

        left: 0px;
        opacity: 0.5;
        transform: rotateY(0) translateZ(-200px);

        &.slide-0{
          left: -50px;
          transform: rotateY(30deg) translateZ(-200px);
        }
        &.slide-1{
          left: 0px;
          opacity: 1;
          transform: rotateY(0) translateZ(0);
        }
        &.slide-2{
          left: 50px;
          transform: rotateY(-30deg) translateZ(-200px);
        }
      }

      .character{
        cursor: pointer;
        pointer-events: none;

        filter: saturate(25%);
        transition: filter 0.3s;

        &.selected{
          filter: saturate(100%);
        }
        &.unselected{
          filter: saturate(25%);
        }
      }
    }
  }

  .description{
    display: flex;
    flex-direction: column;
    grid-gap: 20px;
    align-items: center;
    padding: 20px;
    // background-color: rgba(0,0,0,0.5);
    // border-radius: 8px;

    p, :global(.text){
      max-width: 800px;
    }
  }

  .die-list{
    display: flex;
    flex-direction: column;
    grid-gap: 10px;

    .die-title{
      display: flex;
      align-items: center;
      justify-content: space-between;
      width: 80%;
      margin: auto;
      align-items: center;

      h3{
        margin-bottom: 0;
        margin-top: 10px;
        text-transform: capitalize;
      }
    }

    .die-container-outer{
      position: relative;
      width: var(--dice-size);
      height: var(--dice-size);

      .die-container{
        position: absolute;
      }
    }

    .die-scroll{
      display: grid;
      grid-gap: 10px;
      grid-template-columns: 80px var(--dice-size) 80px;
    }
  }

  .summary{
    display: flex;
    grid-gap: 20px;
    align-items: center;
    justify-content: center;

    .dice{
      display: flex;
      flex-direction: column;
      grid-gap: 20px;
      align-items: center;
    }
  }

  .button-container{
    display: flex;
    justify-content: end;
    width: 100%;
    grid-gap: 20px;
  }

  .buttons{
    display: grid;
    grid-gap: 20px;
    align-items: center;
    padding: 20px;
    border-radius: 20px;

    .btn{
      font-size: 2em;
    }
  }
  .btn.start{
    font-size: 2em;
  }
</style>