import { dealDamage, healDamage, addModifier, healthRemove } from '../player.type';
import type { Damage } from '../damage.type';
import type { Die } from '../die.type';
import type { Player } from '../player.type';
import { selectDie, selectFace } from '../../modals/modals';
import { createDie } from "../die.type";
import { lockedDie, activePlayer, inactivePlayer, getStore } from "../../../store";
import type { Technique, Requirement } from "../techniques.type";
import { Songs, type Song } from '../status-effects/song';
import { StatusEffect } from '../status-effects';
import { DieFace } from '../face.type';

// tempo - clear musical effect from enemy
// key change - swap the type of song
// Dynamic Shift - activate the current musical effect

// Music - grants an effect depending on type
// if its a song of a die face, infuse that die at the start of turn
// if its a song of a status effect, apply that at the start of turn
// if its a song of a technique, gain that technique

export const isPerforming: Requirement = {
  text: 'Playing a song',
  icon: 'song',
  test: (source: Player, target: Player) => {
    let song: Song = source.statusEffects.find((modifier) => {
      return modifier.type == 'song';
    }) as Song;

    if (song && song.role == 'performer') {
      return true;
    }

    return false;
  }
}

export const note: Technique[] = [
  {
    ingredients: [DieFace.Note],
    requirements: [isPerforming],
    title: 'Accelerando',
    text: 'Add a stack to the current song for both players.',
    tags: ['song'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      let song: Song = source.statusEffects.find((modifier) => {
        return modifier.type == 'song';
      }) as Song;
      if (song){
        song.update(1);
      }

      song = target.statusEffects.find((modifier) => {
        return modifier.type == 'song';
      }) as Song;
      if (song) {
        song.update(1);
      }
    }
  }, {
    ingredients: [DieFace.Note, DieFace.Note],
    requirements: [isPerforming],
    title: 'Rubato',
    text: 'Remove 2 stacks of the current song from the enemy.',
    tags: ['song'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      let song: Song = target.statusEffects.find((modifier) => {
        return modifier.type == 'song';
      }) as Song;
      song.removeStacks(2);
    }
  }, {
    ingredients: [DieFace.Note, DieFace.Note, DieFace.Note],
    requirements: [isPerforming],
    title: 'Cresendo',
    text: 'Add a stack to the current song for both players. Activate the current song for both players.',
    tags: ['song'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      let song: Song = source.statusEffects.find((modifier) => {
        return modifier.type == 'song';
      }) as Song;
      if (song) {
        song.update(1);
        song.activate();
      }

      song = target.statusEffects.find((modifier) => {
        return modifier.type == 'song';
      }) as Song;
      if (song) {
        song.update(1);
        song.activate();
      }
    }
  }, {
    ingredients: [DieFace.Note, DieFace.Fist],
    title: 'Hymn of Harm',
    text: 'Perform song (Hymn of Harm).',
    tags: ['song'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Song, 1, { role: 'performer', song: Songs.Harm });
      addModifier(target, StatusEffect.Song, 1, { role: 'listener', song: Songs.Harm });
    }
  }, {
    ingredients: [DieFace.Note, DieFace.Bone],
    title: 'Hymn of Healing',
    text: 'Perform song (Hymn of Healing).',
    tags: ['song'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Song, 1, { role: 'performer', song: Songs.Healing });
      addModifier(target, StatusEffect.Song, 1, { role: 'listener', song: Songs.Healing });
    }
  }, {
    ingredients: [DieFace.Note, DieFace.Poison],
    title: 'Sickening Symphony',
    text: 'Perform song (Sickening Symphony).',
    tags: ['song'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Song, 1, { role: 'performer', song: Songs.Poison });
      addModifier(target, StatusEffect.Song, 1, { role: 'listener', song: Songs.Poison });
    }
  }, {
    ingredients: [DieFace.Note, DieFace.Blood],
    title: 'Sanguine Symphony',
    text: 'Perform song (Sanguine Symphony).',
    tags: ['song'],
    activate: (target: Player, source: Player, dice: Array<Die>) => {
      addModifier(source, StatusEffect.Song, 1, { role: 'performer', song: Songs.Regen });
      addModifier(target, StatusEffect.Song, 1, { role: 'listener', song: Songs.Regen });
    }
  }
];
