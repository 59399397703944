import { Modifier } from "../modifier";
import { addModifier } from '../player.type';
import { StatusEffect } from "../status-effects";

export class Armoured extends Modifier {
  update(stacks: number, params?) {
    super.update(stacks, params);
    this.delay = true;
  }

  startTurn() {
    const focus = this.owner.statusEffects.find(modifier => modifier.type === 'focus');
    if(!focus){
      this.delay = false;
    }
  }

  endTurn() {
    addModifier(this.owner, StatusEffect.Block, this.stacks);

    if (!this.delay) {
      this.removeStacks(1);
    }
  }

  modifyNotches(notches: Array<string>) {
    for (let i = 0; i < this.stacks; i++) {
      notches.push('armoured');
    }
  }
}