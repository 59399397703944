import { randomInt } from "./math";
import type { MapNode, MapEdge } from "../models/map.type";
import { getIcon } from '../icon/icons';
import { screens } from '../../store';
import { getEnemyDefinition } from "../models/enemy.type";
import { addModifier, type Player } from "../models/player.type";
import { StatusEffect } from "../models/status-effects";
import { Events } from "../models/eventList";

export function createEdge(to, from, length: number = 50) {
  return {
    from: from,
    to: to,
    width: 3,
    dashes: true,
    length: length == 50 ? randomInt(20, 80) : length,
  };
}

export function createNode(id: number, /* type: string,  */cid?: number) {
  let node: MapNode = {
    id: id,
    group: cid == undefined ? id + 1000 : cid,
    // type: type,
    // shape: type == 'home' ? 'circle' : 'box',
    shape: 'image',
    image: '',
    color: 'red',
    size: 40,
    shadow: true,
    visit: () => { console.warn('visit not implemented'); },

    scouted: false,
    visited: false,
    open: false
  };

  return node;
}

export function createMap() {
  let nodes: Array<MapNode> = [];
  let edges: Array<MapEdge> = [];

  const leave = {
    text: 'Continue',
    activate: (player, map, node) => {
      screens.pop();
    }
  };

  let centerX = document.body.offsetWidth / 2;
  let centerY = document.body.offsetHeight / 2;

  const intro = createNode(-1);
  nodes.push(intro);
  intro.visited = true;
  intro.icon = 'event';
  intro.color = 'white';
  intro.type = 'clear';
  intro.image = '';
  intro.shape = 'circle';
  intro.x = centerX;
  intro.y = centerY-centerY/2;
  intro.event = {
    title: 'Prologue',
    text: 'You stand on the border of the Immanis Protectorate\'s territory, a realm shrouded in mystery and mistrust. You are a seasoned Monster Hunter, and your mission begins here.',
    type: 'event',
    image: 'stone-arch',
    id: Events.Prologue1,
    step: {
      text: 'You stand on the border of the Immanis Protectorate\'s territory, a realm shrouded in mystery and mistrust. You are a seasoned Monster Hunter, and your mission begins here.',
      options: [
        {
          text: 'Continue',
          activate: () => {},
          next: {
            text: `With your heart full of determination, you cross into the hushed borderlands, ready to face the unknown.`,
            options: [leave]
          }
        }
      ]
    }
  };

  const scout = createNode(0);
  nodes.push(scout);
  scout.type = 'event';
  scout.icon = 'scout';
  scout.image = getIcon(scout.icon);
  scout.visit = () => {
    screens.push('event', { event: scout.event });
  }
  scout.event = {
    title: 'Tall Cliff',
    text: 'A vantage point offering a glimpse into the lush expanse of the forest.',
    type: 'scout',
    image: 'cliff',
    id: Events.Prologue2,
    step: {
      text: `Exiting the forest's cover, you find yourself on the precipice's edge, the towering cliff granting an unobstructed view of the sprawling landscape below.`,
      options: [
        {
          text: 'Look around',
          activate: (player, map, node) => {
            battle.scouted = true;
            campfire.scouted = true;
            encounter.scouted = true;
            finale.scouted = true;
          },
          next: {
            text: `Choosing to peer over the edge, the landscape unfolds—Verdant Glades extend as far as the eye can see, with Omphalos appearing like a miniature settlement amidst the expansive forest. The Elemental Peaks' distant presence looms, shrouded in an aura of mystery and power.`,
            options: [leave]
          }
        }
      ]
    }
  };

  const battle = createNode(1);
  nodes.push(battle);
  battle.type = 'event';
  battle.icon = 'battle0';
  battle.image = getIcon(battle.icon);
  battle.visit = () => {
    screens.push('event', { event: battle.event });
    // screens.push('battlestart', { battle: battle.battle });
  };
  battle.battle = {
    enemy: getEnemyDefinition('bandit-brawler'),
    environment: 'forest',
    level: 0,
    type: 'normal',
    outcome: 'active'
  };
  battle.event = {
    title: 'Frontier Fighters.',
    text: 'A motley group of frontier fighters block the path ahead.',
    type: 'battle',
    image: 'bandit-mob',
    id: Events.Prologue3,
    step: {
      text: `The path narrows as you near a motley group of frontier fighters wary of your approach.They grip their weapons but don't immediately attack, a silent standoff hanging in the air.`,
      options: [{
        text: 'Approach',
        activate: (player, map, node) => {
        },
        next: {
          text: `Leader - "Halt! What's your business here, stranger? We're not fond of Immanis Protectorate types poking around."`,
          options: [{
            text: 'State your intentions',
            activate: () => {},
            next: {
              text: `You - "I'm here on peaceful terms. I aim to set up a branch in Omphalos, to protect and aid the town against threats."`,
              options: [{
                text: 'Continue',
                activate: () => {},
                next: {
                  text: `Leader - "Protectors? More like meddlers. We'll give you a chance to prove yourselves. Attack, lads!"`,
                  options: [{
                    text: 'Prepare for battle',
                    activate: async () => {
                      screens.push('battlestart', { battle: battle.battle });
                      return new Promise((resolve) => {
                        battle.battle.resolve = resolve;
                      })
                      .then(outcome => {
                        if (outcome == 'win') {
                          return {
                            next: {
                              text: `With skill and resolve, you fend off the marauders' aggression, compelling them to flee in defeat.Yet, your victory came at a cost—the mistrust etched on their faces deepens.They may fear your power but will not easily trust the Immanis Protectorate.`,
                              options: [leave]
                            }
                          };
                        }
                        else {
                          return {
                            next: {
                              text: `Despite your efforts, the marauders prove too formidable. Their disbelief in your abilities only solidifies as they overpower you, showcasing the weakness in your approach. As consciousness fades, you witness their disappointed gazes, losing any hope of earning their trust or cooperation.`,
                              options: [leave]
                            }
                          };
                        }
                      });
                    }
                  }]
                }
              }]
            }
          }]
        }
      }]
    }
  };

  const campfire = createNode(2);
  nodes.push(campfire);
  campfire.type = 'event';
  campfire.icon = 'campfire';
  campfire.image = getIcon(campfire.icon);
  campfire.event = {
    title: 'Campfire',
    type: 'campfire',
    image: 'campfire1',
    text: 'In a forest clearing, a crackling campfire emits a warm glow, offering solace amidst the tranquil surroundings.',
    id: Events.Prologue4,
    step: {
      text: 'In a forest clearing, a crackling campfire emits a warm glow, offering solace amidst the tranquil surroundings.',
      options: [{
        text: 'Rest by the comforting campfire and allow its gentle warmth to soothe your tired body.',
        activate: (player, map, node) => {
          player.healthCurrent = player.healthMax;
          addModifier(player, StatusEffect.Rested, 2, { duration: 2 });
        },
        next: {
          text: 'As you bask in the campfire\'s healing radiance, your wounds begin to mend, and a sense of rejuvenation washes over you, restoring your health and revitalizing your spirit.',
          options: [{
            text: 'Leave',
            activate: (player, map, node) => {
              screens.pop();
            }
          }]
        }
      }]
    }
  };
  campfire.visit = () => {
    screens.push('event', {event: campfire.event});
  };

  const encounter = createNode(3);
  nodes.push(encounter);
  encounter.type = 'event';
  encounter.icon = 'event';
  encounter.image = getIcon(encounter.icon);
  encounter.event = {
    title: 'Concerned Civilians',
    type: 'event',
    image: 'civilians',
    text: 'A group of anxious civilians gathered on the outskirts of Omphalos.',
    id: Events.Prologue5,
    step: {
      text: 'As you venture closer to Omphalos, you come across a group of anxious civilians gathered on the outskirts. They appear distressed, murmuring amongst themselves with worried expressions.',
      options: [{
        text: 'Approach',
        next: {
          text: `Villager - "Monsters lurk closer these days, stranger. Our town's safety's fading like shadows at dusk."`,
          options: [{
            text: 'Offer Assistance',
            next: {
              text: `You volunteer your aid to address the monster threat, promising to protect Omphalos from the escalating danger.`,
              options: [{
                text: 'Continue',
                next: {
                  text: `The civilians are hesitant but allow you a chance to prove your intentions. Your successful handling of the monster threat earns you some goodwill, albeit cautiously.`,
                  options: [leave]
                }
              }]
            }
          }, {
            text: 'Seek Information',
            next: {
              text: `You ask the civilians for details about the recent attacks and the types of monsters plaguing the town, aiming to gather valuable information.`,
              options: [{
                text: 'Continue',
                next: {
                  text: `The civilians, while wary, provide some details about the monsters' behaviors and locations. This knowledge may aid you in strategizing your approach but doesn't significantly impact their perception of you.`,
                  options: [leave]
                }
              }]
            }
          }, {
            text: 'Request Cooperation',
            next: {
              text: `You suggest forming a collaboration between the Immanis Protectorate and the town's civilians to defend against the monsters, proposing a unified front for protection.`,
              options: [{
                text: 'Continue',
                next: {
                  text: `The idea resonates with a few civilians, but most remain skeptical. They appreciate the suggestion but are hesitant to trust outsiders, including the Immanis Protectorate, considering their previous suspicions.`,
                  options: [leave]
                }
              }]
            }
          }]
        }
      }, {
        text: 'Ignore',
        activate: () => {},
        next: {
          text: `You decide to ignore the civilians' plight, continuing on your way to Omphalos.`,
          options: [leave]
        }
      }]
    }
  };
  encounter.visit = () => {
    screens.push('event', { event: encounter.event });
  };

  let finale = createNode(4);
  nodes.push(finale);
  finale.type = 'event';
  finale.icon = 'quest';
  finale.image = getIcon(finale.icon);
  finale.x = centerX;
  finale.y = centerY + centerY / 2;
  finale.event = {
    title: 'Omphalos',
    text: ``,
    type: 'quest',
    image: 'omphalos-gate',
    id: Events.Prologue6,
    step: {
      text: `With the sun setting on the horizon, you finally step onto the cobbled streets of Omphalos. The town, nestled amidst the Verdant Glades, pulses with life yet carries an air of unease. Your arrival marks the beginning of a new chapter in your journey.`,
      options: [{
        text: 'Continue',
        next: {
          text: `Your path to Omphalos was fraught with challenges—the treacherous borderlands, encounters with wary civilians, and a growing understanding of the imminent monster threat. Yet, your determination and resolve remain unwavering as you seek to establish an Immanis Protectorate branch and earn the town's trust.`,
          options: [{
            text: 'Continue',
            next: {
              text: `As darkness descends upon Omphalos, your mission is far from over. The shadows cast by the Elemental Peaks loom large, hinting at mysteries yet unsolved and dangers yet to be faced. The townsfolk's skepticism and the lurking threat of monsters paint a foreboding picture, signaling the beginning of an arduous yet crucial chapter in your quest. The story unfolds, awaiting your choices and actions to shape the fate of Omphalos and the land beyond.`,
              options: [{
                text: 'Finish Prologue',
                activate: (player, map, node) => {
                  screens.pop();
                  screens.pop();
                }
              }]
            }
          }]
        }
      }]
    }
  };
  finale.visit = () => {
    screens.push('event', { event: finale.event });
  };

  edges.push(createEdge(-1, 0));
  edges.push(createEdge(0, 1));
  edges.push(createEdge(1, 2));
  edges.push(createEdge(2, 3));
  edges.push(createEdge(3, 4));

  let map = {
    nodes: nodes,
    edges: edges,
  };

  return map;
}



export function createPrologue(){
  let map = createMap();
  return map;
}