<script lang='ts'>
  import { createDie } from "../../models/die.type";
  import BlankDie from '../../dice-tray/die-blank.svelte';
  import Text from '../../components/text.svelte';
  import Icon from '../../icon/icon.svelte';
  import Hover from '../../components/hover.svelte';
  import { code } from '../../modals/modals';
  import type { Die as DieObject } from "../../models/die.type";
  import type { DieFace } from "src/lib/models/face.type";

  export let data;
  export let searchDie: Array<DieObject> = [];
  let display = true;

  function viewCode(){
    code({
      title: 'Code',
      text: '    '+data.activate
    });
  }

  $: if (searchDie.length !== -1) {
    display = true;

    const faces = [];
    searchDie.forEach((die: DieObject) => {
      die.faces.forEach((face: Array<DieFace>) => {
        face.forEach((face: DieFace) => {
          if(!faces.includes(face)){
            faces.push(face);
          }
        })
      });
    });

    if(faces && faces.length){
      display = false;

      // set display to true if all the values in data.ingredients also exist in faces
      if(data.ingredients.every((ingredient: string) => faces.includes(ingredient))){
        display = true;
      }
    }
  }
</script>

{#if display}
<div class='container'>
  <h2>{data.title}</h2>

  <div class='information'>
    <div class='die-list'>
      {#each data.ingredients as ingredient}
        <div
          class='die-container'
        >
          <BlankDie dieType={ingredient}/>
        </div>
      {/each}
      {#if data.requirements}
        {#each data.requirements as requirement, index}
          {#if requirement.icon}
            <div class='requirement'>
              <Icon name={requirement.icon} width='40px' height='40px'/>
              {#if requirement.iconBonus}
                <Icon name={requirement.iconBonus} width='60px' height='60px'/>
              {/if}

              <Hover>
                <div class='hover-icon'>
                  <Icon name={requirement.icon} width='40px' height='40px'/>
                  {#if requirement.iconBonus}
                    <Icon name={requirement.iconBonus} width='60px' height='60px'/>
                  {/if}
                </div>
                <br/>

                <p>{requirement.text}</p>
              </Hover>
            </div>
          {/if}
        {/each}
      {/if}
    </div>

    <Text data={data.text}/>
    <!-- <h3>{data.text}</h3> -->

    <button on:click={viewCode}>View Code</button>
  </div>
</div>
{/if}

<style lang='less'>
  @import "../../../defines.less";

  .container{
    border: 2px solid var(--background-colour-light);
    padding: 5px;

    .information{
      display: grid;
      grid-template-columns: calc((var(--dice-size) + var(--dice-gap)*2) * 4) 1fr;
      grid-gap: 10px;

      @media (max-width: 550px){
        grid-template-columns: 1fr;
      }

      padding: 5px;
      text-align: left;

      .die-list{
        text-align: center;
        display: flex;
        grid-gap: var(--dice-gap);

        .die-container{
          position: relative;
        }

        .requirement{
          position: relative;
          // border: 2px solid red;
          width: var(--dice-size);
          height: var(--dice-size);
          border-radius: var(--dice-size);
          display: flex;
          align-items: center;
          justify-content: center;
          opacity: 0.5;
          background-color: #888;
          box-shadow: inset 3px 3px 3px 0 #aaa, inset -3px -3px 3px #666;
          opacity: 1;

          svg{
            position: absolute;
          }

          .hover-icon{
            width: 60px;
            height: 60px;
            position: relative;
            display: flex;
            align-items: center;
            justify-content: center;
            margin: auto;
          }
        }
      }
    }

    button{
      display: none;
    }
  }

  h2{
    text-transform: capitalize;
  }
</style>