import { Modifier } from "../modifier";
import { actionHistory } from '../../../store';

export class Rebirth extends Modifier {
  damageIn(damage) {
    // if this.owner.health is less than damage.value, set damage value to 0 and health to stacks
    if (this.owner.healthCurrent < damage.value) {
      damage.value = 0;
      this.owner.healthCurrent = this.stacks;
      actionHistory.push(`${this.owner.title} was reborn!`);
      this.removeStacks(this.stacks);
    }
  }
}